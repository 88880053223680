import { Injectable } from '@angular/core'
import { StorageMap } from '@ngx-pwa/local-storage'
import { Subject, Observable } from 'rxjs'
import { Router } from '@angular/router'

import { environment } from 'src/environments/environment'
import { FileResult } from '@doclab/validation'
import { WorkerService } from 'src/app/worker.abstract'
import { ConfigService } from '../config/config.service'
import { DetectService } from '../detect/detect.service'
import { ImageService } from '../image/image.service'
import { RadialService } from '../radial/radial.service'
import { ModelType } from 'src/app/models/basic'

export interface Point {
  x: number
  y: number
}

export type BoxType = 'doc' | 'txt' | 'img' | 'bbx' | 'kpt' | 'obf'
export interface Roi {
  id: number
  name: string
  ocr: boolean
  type: BoxType
  obfuscated: number[]
  points: Point[]
  geo: Geo
  rawText?: string
  imgUrl?: string
}

export interface Geo {
  t: number
  l: number
  b: number
  r: number
  w: number
  h: number
}

export interface SearchRoiEvent {
  cmd: 'searchRoi'
  status: 'ok' | 'ko'
  duration: number
  isFound: boolean
  validation: FileResult
  roiImage: string
  extra: any
}

@Injectable({
  providedIn: 'root'
})
export class RoiService extends WorkerService {
  public workerName = 'roi'
  public isOnline = true
  protected maxLoadingDuration = 15000
  protected maxProcessDuration = 1500

  private remoteModel: any

  private offscreenCanvas: HTMLCanvasElement
  private offscreenCtx: CanvasRenderingContext2D

  constructor(
    protected detectService: DetectService,
    public imageService: ImageService,
    protected storageMap: StorageMap,
    protected radialService: RadialService,
    protected router: Router,
    protected configService: ConfigService,
  ) {
    super(detectService, storageMap, radialService, router, configService)
    this.offscreenCanvas = document.createElement('canvas')
    this.offscreenCtx = this.offscreenCanvas.getContext('2d')
  }

  public initModels() {
    this.isDesirable()
    if (this.withWorker) {
      this.fetchModel('roi').then(_ => {
        if (!this.worker) { // check in case where the worker is already launch
          this.init()
        } else {
          console.warn('         roiServ.ts Worker already started')
        }
      }).catch(_ => {
        console.log('         roiServ.ts Worker, can\'t load Model,  Wasm desactivated')
        this.withWorker = false
        this.init()
      })
    } else {
      this.init()
    }
  }

  public searchRoi(
    lowRes: ArrayBuffer,
    lowWidth: number,
    lowHeight: number,
    hiRes: Blob,
    dbId: string,
    radId: string,
    extra?: any) {
    if (this.withWorker) {
      if (false) { // >>> DEBUG only
        // const formData: FormData = new FormData()
        // formData.append('file', hiRes)
        // this.httpClient.post(`${hosts.save}/crucial/save`, formData).subscribe(event => {
        //   console.log(` recognitionComp.ts /crucial/save result`, event)
        // })
      } // <<< DEBUG end
      console.log(`         roiServ.ts searchRoi begin`)
      if (hiRes) { // with hires
        // get image Data from Blob
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0)
          const imageData = context.getImageData(0, 0, image.width, image.height)
          console.log(`         roiServ.ts searchRoi image ` +
            `size(${imageData.data.byteLength}) width(${imageData.width}) height(${imageData.height})`)

          // --- ask the worker to find roi
          const msg = {
            cmd: 'searchRoi',
            buffer: imageData.data.buffer,
            dbId: dbId,
            width: imageData.width,
            height: imageData.height,
            extra,
          }
          this.worker.postMessage(msg, [msg.buffer])
          URL.revokeObjectURL(image.src)
        }
        console.log(`         roiServ.ts searchRoi before CreateObjectUrl`)
        image.src = window.URL.createObjectURL(hiRes) // TODO : hiRes can be available after this call !!! see TakePhoto
        console.log(`         roiServ.ts searchRoi after CreateObjectUrl`)

      } else { // with lowres
        // --- ask the worker to find roi
        const msg = {
          cmd: 'searchRoi',
          buffer: lowRes,
          dbId: dbId,
          width: lowWidth,
          height: lowHeight,
          extra,
        }
        this.worker.postMessage(msg, [msg.buffer])
      }
    } else {
      console.log('         roiServ.ts recognizeFromBlob without worker')
      this.radialService.analyze(hiRes, radId, dbId).subscribe(analyzeResult => {
        this.isOnline = analyzeResult.status === 'ok'

        let isFound: boolean
        let duration: number
        let roiImage: string
        let validation: FileResult | null

        if (analyzeResult.status === 'ko') {
          isFound = false
          duration = -1
          roiImage = ''
          validation = null
        } else {
          isFound = analyzeResult.isFound
          duration = analyzeResult.duration.overall
          validation = analyzeResult.validation
          roiImage = analyzeResult.roiImage
        }

        const roiResult: SearchRoiEvent = {
          cmd: 'searchRoi',
          status: analyzeResult.status,
          validation,
          isFound,
          duration,
          roiImage,
          extra,
        }
        this.subject.next(roiResult)
      })

    }
  }

  public extractDoc(imgUrl: string, geo: Geo, rois: Roi[]): Observable<Blob> {
    console.log(`         roiServ.ts ocr extractDoc begin`)
    const subject = new Subject<Blob>()
    const canvas = document.createElement('canvas')
    const canvasCtx = canvas.getContext('2d')

    const image = new Image()
    image.onload = () => {
      // crop the image
      canvas.width = image.width
      canvas.height = image.height
      canvasCtx.drawImage(image, 0, 0)

      // display roi area
      canvasCtx.lineWidth = 4
      rois.filter(roi => roi.ocr || roi.type === 'img').map(roi => {
        canvasCtx.strokeStyle = 'red'
        canvasCtx.setLineDash([])
        canvasCtx.beginPath()
        canvasCtx.moveTo(roi.points[0].x, roi.points[0].y)
        canvasCtx.lineTo(roi.points[1].x, roi.points[1].y)
        canvasCtx.lineTo(roi.points[3].x, roi.points[3].y)
        canvasCtx.lineTo(roi.points[2].x, roi.points[2].y)
        canvasCtx.lineTo(roi.points[0].x, roi.points[0].y)
        canvasCtx.stroke()
        canvasCtx.strokeStyle = 'green'
        canvasCtx.setLineDash([16, 16])
        canvasCtx.beginPath()
        canvasCtx.moveTo(roi.points[0].x, roi.points[0].y)
        canvasCtx.lineTo(roi.points[1].x, roi.points[1].y)
        canvasCtx.lineTo(roi.points[3].x, roi.points[3].y)
        canvasCtx.lineTo(roi.points[2].x, roi.points[2].y)
        canvasCtx.lineTo(roi.points[0].x, roi.points[0].y)
        canvasCtx.stroke()
      })

      const cropImage = canvasCtx.getImageData(geo.l, geo.t, geo.w, geo.h)

      // convert it into Blob
      canvas.width = geo.w
      canvas.height = geo.h
      canvasCtx.putImageData(cropImage, 0, 0)
      canvas.toBlob((blob) => {
        // house keeping and return
        subject.next(blob)
        subject.complete()
      })
    }
    image.src = imgUrl

    return subject.asObservable()
  }

  public extractImg(fullImage: Blob, rois: Roi[]): Observable<Roi> {
    console.log(`         roiServ.ts ocr extractImg begin`)
    const subject = new Subject<Roi>()

    const image = new Image()
    image.onload = () => {
      // crop the image
      this.offscreenCanvas.width = image.width
      this.offscreenCanvas.height = image.height
      this.offscreenCtx.drawImage(image, 0, 0)

      rois.filter(roi => roi.type === 'img').map(roi => {
        const cropImage = this.offscreenCtx.getImageData(roi.geo.l, roi.geo.t, roi.geo.w, roi.geo.h)

        // convert it into Blob
        this.offscreenCanvas.width = roi.geo.w
        this.offscreenCanvas.height = roi.geo.h
        this.offscreenCtx.putImageData(cropImage, 0, 0)
        roi.imgUrl = this.offscreenCanvas.toDataURL()
        subject.next(roi)
      })
      URL.revokeObjectURL(image.src)
      subject.complete()
    }
    image.src = URL.createObjectURL(fullImage)

    return subject.asObservable()
  }

  protected isDesirable() {
    const androidVersion = this.detectService.androidVersion
    const iosVersion = this.detectService.iosVersion
    const result = environment.with.wasm[this.workerName] && // stability allowed and
      (
        (androidVersion && parseInt(androidVersion, 10) >= 9) || // very recent of Android or
        (iosVersion && parseInt(iosVersion, 10) >= 12) || // recent of iOS or
        (!androidVersion && !iosVersion) // desktop
      )
    console.log(`         roiServ.ts worker isDesirable(${result}) ` +
      `wasm(${environment.with.wasm[this.workerName]}), android(${androidVersion}), ios(${iosVersion})`)
    this.withWorker = result
  }

  protected onMessage(event: MessageEvent) {
    if (event.data.cmd === 'loadModelroi') {
      console.log(`         roiServ.ts ${event.data.name} onRespons(${event.data.cmd})`)
      this.isReady = true
      this.subject.next(event.data)
    } else if (event.data.cmd === 'searchRoi') {
      console.log('roiServ.ts searchRoi event', event) // DEBUG
      console.log(`         roiServ.ts ${event.data.name} onRespons(${event.data.cmd}) ` +
        `in ${event.data.duration} ms, ${event.data.isFound ? 'found' : 'notFound'}`)
      if (event.data.status === 'ko') {
        // worker has crashed, disable it
        console.warn(`         roiServ.ts Worker crash, deactivate it`)
        this.withWorker = false // worker failed to start. mark it as unusable
        this.worker.terminate()
        this.worker = null
        event.data.isFound = false
      }
      event.data.rawCrops = JSON.stringify(event.data.crops)
      this.subject.next(event.data)
    } else {
      console.warn(`         roiServ.ts ${event.data.name} unknow cmd`, event.data)
    }
  }

  protected onConnect() {
    this.worker.postMessage({
      cmd: 'loadModelroi',
      models: this.remoteModel
    })
  }

  private fetchModel(modelType: ModelType): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const modelKey = `models${modelType}`
      const descriptorType = modelType === 'rad' ? this.configService.radial.radDescriptor : this.configService.radial.roiDescriptor
      this.storageMap.get<any>(modelKey).subscribe((localModels: any) => {
        if (localModels === undefined) {
          // not yet in the local DB
          this.radialService.getModels(modelType, descriptorType, undefined).subscribe((remoteAllModels: any) => {
            if (remoteAllModels.hasOwnProperty('status')) {
              console.log(`         roiServ.ts fetchModel request failed, exit`)
              reject()
            } else {
              console.log(`         roiServ.ts fetchModel request succeed, initialize new models`)
              this.remoteModel = remoteAllModels.body
              // save it for the next time
              this.storageMap.set(modelKey, remoteAllModels).subscribe(() => { })
              resolve()
            }
          })
        } else {
          // check if models changed
          this.radialService.getModels(
            modelType, environment[descriptorType], localModels.source, localModels.deployDate
          ).subscribe((remoteAllModels: any) => {
            if (remoteAllModels.hasOwnProperty('status')) {
              console.log(`         roiServ.ts fetchModel request failed, keep locals`)
              this.remoteModel = localModels.body
              resolve()
            } else {
              if (remoteAllModels.body && remoteAllModels.body.length !== 0) {
                console.log(`         roiServ.ts fetchModel request succeed, new models`)
                this.remoteModel = remoteAllModels.body
                // save it for the next time
                this.storageMap.set(modelKey, remoteAllModels).subscribe(() => { })
                resolve()
              } else {
                console.log(`         roiServ.ts fetchModel request succeed, nothing new`)
                this.remoteModel = localModels.body
                resolve()
              }
            }
          })
        }
      })
    })
  }
}
