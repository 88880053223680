<mat-card class="doc-card">
  <mat-card-header>
      <!-- <mat-card-subtitle>{{ document.label }}</mat-card-subtitle> -->
      <mat-card-title>{{ document.label }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngFor="let page of document.pages">
          <div class="page">
						<img *ngIf="!!page.image" 
              (click)="openZoomDialog(doc, page)"
              class="photo" [src]="page.image | safe" 
            	aria-label="Fullscreen">
          </div>
        </ng-container>
      <p>This card has divider and indeterminate progress as footer</p>
      <mat-divider></mat-divider>
    </mat-card-content>
    <!-- <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions> -->
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
</mat-card>
