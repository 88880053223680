import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute } from '@angular/router'
import { DocumentService } from '../services/document/document.service'
import { Observable } from 'rxjs'
import { ConfigService } from '../services/config/config.service'
import { NavigateService } from '../services/navigate/navigate.service'
import { RadialService } from '../services/radial/radial.service'
import { AuthService } from '../auth/auth.service'
import { DisplayableDocument } from '../models/document.model'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  state$: Observable<object>
  rippleInterval: NodeJS.Timeout
  sending = false
  sent = false
  htmlText: string
  id: string
  
  public get done(): boolean {
    return this.documentService.isDone
  }

  public get documents(): DisplayableDocument[] {
    const done = this.done
    return this.documentService.displayableDocuments.filter(
      (doc) => done || !doc.done
    )
  }

  public get sendable(): boolean {
    return (!!this.configService.userCallbackUrl)
  }

  constructor(
    public configService: ConfigService,
    public documentService: DocumentService,
    public navigateService: NavigateService,
    private radialService: RadialService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {
    this.id = this.activatedRoute.snapshot.queryParamMap?.get('k')
    console.log(`      resumeComp.ts constructor  id(${this.id})`)
  }

  ngOnInit() {

    // Don't allow the navigation to the previous page to limit security issues...
    window.history.pushState(null, null, window.location.href)
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href)
    }

    console.log('      resumeComp.ts onInit')
    if (!this.sent || !this.done) {
      // If all is done or if it's a partial send (requireConsolidate...)
      if (this.documentService.isDone || !this.configService.requireConsolidate) {
        if (this.documentService.isEmpty && localStorage.getItem('connected') === 'yes') {
          this.htmlText = 'La connexion a été interrompue, merci de cliquer sur le lien que vous avez reçu pour recommencer le processus.'
  
        } else if (this.documentService.isEmpty) {
          this.htmlText = "Pas de numéro d'identification."

        } else {
          this.onSend()
        }
      } else {
        this.htmlText = 'Dossier incomplet ! Ces documents sont manquant.'
      }
    }
  }

  ngOnDestroy() {
    console.log('      resumeComp.ts onDestroy')
    clearInterval(this.rippleInterval)
  }

  onExit() {
    if (this.configService?.endLink !== '') {
      window.location.href = this.configService?.endLink
    } else {
      window.close
    }
  }

  public onSend() {
    this.sending = true
    const sendObservable = this.configService.userCallbackUrl ?
      this.documentService.sendValidationResult(this.configService.userCallbackUrl) :
      this.documentService.sendFileToConvivial(this.radialService.hosts.partial)
      sendObservable.subscribe((response) => {
        this.sending = false
        if (response['status'] === 'ko') {
          this.snackBar.open(
            `Le dossier n\'a pas pu être envoyé. Erreur: ${response['msg']}`,
            'OK'
          )
        } 
        else {
          this.sent = true

          this.htmlText = 'Vous pouvez fermer la page.'
          this.cd.detectChanges()

          // request a delete of the kycq information
          const id = this.configService.requestInfo.requestId
          this.radialService.delKycq(id).subscribe(() => {
            console.log(`      resumeComp.ts delete kycq ${id} ok`)
          }, () => {
            console.log(`      resumeComp.ts delete kycq ${id} ko`)
          })
          localStorage.removeItem('user')
          localStorage.removeItem('connected')
          // consume a piece quota
          const count = this.documentService.displayableDocuments.length
          this.radialService.consumeQuota('piece', count).subscribe((result) => {
            // TODO : don't validate doc if consume failed
            console.log(`      resumeComp.ts consumeQuota OK result(${result})`)
          }, (error) => {
            console.log(`      resumeComp.ts consumeQuota KO error(${error})`)
          })
        }
      },
      (err: HttpErrorResponse) => {
        const statusMsg = err.status !== undefined ? `code ${err.status}` : ''
        const errMsg = err instanceof Error ? `${err}` : err.error
        const msg = `${statusMsg}${statusMsg && errMsg ? ', ' : ''}${errMsg || ''}`
        this.snackBar.open(
          `Le dossier n\'a pas pu être envoyé. Erreur: ${msg}`,
          'OK'
        )
        this.sending = false
      })
      this.cd.detectChanges()
  }
}
