import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Subject, Observable, of } from 'rxjs'
import { catchError, tap, switchMap, map } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'

import { environment } from 'src/environments/environment'
import { FileResult } from '@doclab/validation'
import { ConfigService } from '../config/config.service'
import { DetectService } from '../detect/detect.service'
import { Config } from 'src/app/models/config.model'

const uuidV4 = require('uuid/v4')

export interface IRoiRow {
  id: string
  name: string
  version: string
  creationDate: string
  validityName: string
}

export interface IDeployRoiRow {
  id: string
  deployDate: string
}

export interface RegisterResult {
  blob: Blob
  transform: number[]
}

export interface SetImageResult {
  mainOid: number
  thumbOid: number
}

export interface StatusResult {
  status: 'ok' | 'ko'
  msg?: string
}

export interface TestFeaturesRadResult {
  status: 'ok' | 'ko'
  msg?: string
  id: string
  category: string
  isModelOk?: boolean
}

export interface TestFeaturesRoiResult {
  status: 'ok' | 'ko'
  msg?: string
  crops: any[]
}

export interface CompareRadResult {
  blob: Blob
  matchType: number
}

export interface List {
  id: string
  content: any
}

// Generic typings
export type Status = 'ok' | 'ko'

export class FailureResult {
  status: 'ko'
  msg: string
}

export interface Duration {
  overall: number
  rad?: number
  roi?: number
  pdf?: number
  recognition?: number
}
// Authenticate typings
export class AuthenticateSuccessResult {
  access_token: string
  expires_in: number
  id_token: string
  'not-before-policy': number
  refresh_expires_in: number
  refresh_token: string
  scope: string
  session_state: string
  token_type: string
  consumed: number
  quota: number
}
export type AuthenticateResult = AuthenticateSuccessResult | FailureResult

// Recognize typings
export class RecognizeResult {
  status: Status
  msg?: string
  duration: Duration
  radId: string
  dbId: string
}

// Analyze typings
export class AnalyseSuccessResult {
  status: 'ok'
  duration: Duration
  isFound: boolean
  validation: FileResult
  roiImage: string
}

export type AnalyzeResult = AnalyseSuccessResult | FailureResult

@Injectable({
  providedIn: 'root'
})
export class RadialService {
  online = new Subject<boolean>()
  online$ = this.online.asObservable()
  imagesToDeleteOnCancel: SetImageResult[] = []
  altToDeleteOnSave: SetImageResult[] = []
  testToDeleteOnSave: SetImageResult[] = []
  getImageUrl: string
  hosts = { radialRemote: '', collegial: '', partial: '', auth: '', addressChecker: '', tokens: '' }

  constructor(
    private httpClient: HttpClient,
    private detectService: DetectService,
    private configService: ConfigService,
  ) {

    const parts = location.origin.split('.') // ex : https://linker.prod.wizidee.com or https://linker.wizidee.com 
    const subDomain = parts.length === 4 ? '' : '.prod'
    this.hosts.auth = environment.authHost === '' ?
      location.origin.replace('linker', `auth${subDomain}`) : environment.authHost
    this.hosts.partial = environment.partialHost === '' ?
      location.origin.replace('linker', `bff-linker`) : environment.partialHost
    this.hosts.addressChecker = environment.addressCheckerHost === '' ?
      location.origin.replace('linker', `address-checker${subDomain}`) : environment.addressCheckerHost
    this.hosts.collegial = environment.collegialHost === '' ?
      location.origin.replace('linker', `collegial${subDomain}`) : environment.collegialHost
    this.hosts.tokens = environment.tokensHost === '' ?
      location.origin.replace('linker', `tokens${subDomain}`) : environment.tokensHost

    this.configService.onReady$.subscribe(() => {
      this.hosts.radialRemote = this.configService.radial.host
    })
  }

  public async authenticate(realm: string, clientId: string, login: string, password: string): Promise<AuthenticateSuccessResult> {
    // prepare the request for an application/x-www-form-urlencoded;charset=UTF-8
    const payLoad = new HttpParams()
      .set('grant_type', 'password')
      .set('client_id', clientId)
      .set('scope', 'openid')
      .set('username', login)
      .set('password', password)
    // this angular prototype will auto setup content-type in x-www-form-urlencoded
    const result = await this.httpClient.post<AuthenticateSuccessResult>(
      `${this.hosts.auth}/auth/realms/${realm}/protocol/openid-connect/token`, payLoad
    ).toPromise()
    console.log(`      radialServ.ts authenticate consumed(${result.consumed}) / quota(${result.quota})`)
    return result
  }

  public consumeQuota(subType: string, count: number = 1): Observable<boolean> {
    const subject = new Subject<boolean>()
    const uuid = uuidV4()
    const extra = JSON.stringify({
      deviceOs: this.detectService.osName,
      app: `(C)rucial v${environment.version}`,
      userAgent: navigator.userAgent,
      origin: location.origin,
    })

    // prepare the request for an application/x-www-form-urlencoded;charset=UTF-8
    const payLoad = new HttpParams()
      .set('n', count.toString())
      .set('subtype', subType)
      .set('extra', extra)
      .set('nonce', uuid)
    this.httpClient.post(
      `${this.hosts.tokens}/consume-tokens`, payLoad, { responseType: 'text' }
    ).subscribe(body => {
      subject.next(true)
      // if (typeof body === 'string') {
      //   jscu.pkc.verify(
      //     `n=${count}&subtype=${subType}&nonce=${uuid}`,
      //     body,
      //     environment.pubKey,
      //     'SHA-256'
      //   ).then(isValid => subject.next(isValid))
      //   subject.next(true)
      // } else {
      //   // unable to reach tokens server
      //   subject.next(false)
      // }

    })

    return subject.asObservable()
  }

  private makeSource(modelType: string, descriptorType: string): string {
    const customer = this.configService.radial.customer
    const stage = this.configService.radial.stage
    return this.hosts.partial + `/api/features/${customer}/${stage}/${modelType}/${descriptorType}`
  }

  public getModels(modelType: string, descriptorType: string, cachedSource: string, timestamp: string = '') {
    // If the models are cached and their source is different from the
    // requested one then ignore the contents of the cache
    const customer = this.configService.radial.customer
    const stage = this.configService.radial.stage
    const source = this.makeSource(modelType, descriptorType)
    if (source !== cachedSource) {
      timestamp = ''
    }

    return this.httpClient.get<any>(
      this.hosts.partial + `/api/features/${customer}/${stage}/${modelType}/${descriptorType}`,
      { params: { timestamp } }
    ).pipe(
      tap(result => {
        result.source = source

        // Log for debug and learning purpose
        console.log('      radialServ.ts getModels')
      })
    )
  }

  public getValidationConfig(configName: string) {
    return this.httpClient.get<any>(
      this.hosts.partial + '/config/convivial/' + configName,
      {
        headers: {
          'X-Skip-Error-Interceptor': '',
        }
      }
    ).pipe(
      tap(result => {
        // Log for debug and learning purpose
        console.log('      radialServ.ts getValidationConfig')
      }),
      switchMap(result => {
        return of(result.body)
      }),
      catchError(
        // implement custom error handling
        this.handleError<any>('getValidationConfig', { global: {}, files: {} })
      )

    )
  }

  public recognize(blob: Blob, withoutOcrRad: boolean): Observable<RecognizeResult> {
    const partialUrl = Location.joinWithSlash(this.hosts.partial, '/tool/recognize')
    const formData: FormData = new FormData()
    formData.append('file', blob)
    formData.append('withoutOcrRad', withoutOcrRad.toString())
    // this angular prototype will auto setup content-type in multipart/form-data
    return this.httpClient.post<RecognizeResult>(partialUrl, formData).pipe(
      tap(result => {
        // Log for debug
        console.log('      radialServ.ts recognize', result)
      })
    )
  }

  public analyze(blob: Blob, radId: string, dbId: string): Observable<AnalyzeResult> {
    const radialUrl = Location.joinWithSlash(this.hosts.partial, '/tool/analyze')
    const formData: FormData = new FormData()
    formData.append('file', blob)
    formData.append('radId', radId)
    formData.append('dbId', dbId)
    formData.append('withCrops', 'true')
    formData.append('withThumbnail', 'false')
    formData.append('withImage', 'true')
    formData.append('validationSchemaContent', this.configService.schema)

    // this angular prototype will auto setup content-type in multipart/form-data
    return this.httpClient.post<AnalyzeResult>(radialUrl, formData).pipe(
      tap(result => {
        // Log for debug and learning purpose
        console.log('      radialServ.ts analyze', result)
      })
    )
  }

  public async fetch(url: string): Promise<Config> {
    const response = await this.httpClient.get<{ body: Config }>(
      url, {
      headers: {
        'X-Skip-Error-Interceptor': '',
      }
    }
    ).toPromise()
    return response.body
  }

  public pdf2png(file: File): Observable<Blob> {
    // call Radial internal helper
    const radialUrl = Location.joinWithSlash(this.hosts.radialRemote, '/pdf/pdf2png')
    const formData: FormData = new FormData()
    formData.append('file', file)
    // this angular prototype will auto setup content-type in multipart/form-data and response content to blob
    return this.httpClient.post(radialUrl, formData, { responseType: 'blob', observe: 'response' }).pipe(
      tap(result => {
        // Log for debug and learning purpose
        console.log('      radialServ.ts pdf2png', result)
      }),
      switchMap(result => {
        return of(result.body)
      })
    )
  }

  public saveImg(blob: Blob, suffix: string): Observable<any> {
    console.log(`      radialServ.ts saveImg`)
    // call Radial internal helper
    const radialUrl = Location.joinWithSlash(this.hosts.radialRemote, '/crucial/save-img')
    const formData = new FormData()
    formData.append('file', blob)
    formData.append('suffix', suffix)
    // this angular prototype will auto setup content-type in multipart/form-data
    return this.httpClient.post(radialUrl, formData)
  }

  public saveRaw(buffer: ArrayBuffer, width: number, height: number, channels: 1 | 2 | 3 | 4, suffix: string): Observable<any> {
    // call Radial internal helper
    const radialUrl = Location.joinWithSlash(this.hosts.radialRemote, '/crucial/save-raw')
    const formData = new FormData()
    formData.append('file', new Blob([buffer]))
    formData.append('width', width.toString())
    formData.append('height', height.toString())
    formData.append('channels', channels.toString())
    formData.append('suffix', suffix)
    // this angular prototype will auto setup content-type in multipart/form-data
    return this.httpClient.post(radialUrl, formData)
  }

  public delKycq(id: string): Observable<any> {
    return this.httpClient.delete(`${this.hosts.partial}/config/kycq`, {
      headers: {
        'X-Skip-Error-Interceptor': '',
      }
    })
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error('      radialServ.ts handleError', error) // log to console instead

      // transforming error for user consumption
      this.online.next(false)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }

}

