import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { StorageMap } from '@ngx-pwa/local-storage'
import { Router } from '@angular/router'
import { Point } from '../roi/roi.service'
import { WorkerService } from 'src/app/worker.abstract'
import { DetectService } from '../detect/detect.service'
import { RadialService } from '../radial/radial.service'
import { ConfigService } from '../config/config.service'

export interface StabilityEvent {
  status: string
  cmd: string
  msg?: string
  srcBuffer?: ArrayBuffer
  dstBuffer?: ArrayBuffer
  isFound?: boolean
  isStable?: boolean
  rectPoints?: any[]
  duration?: number
  previousInlierPoints?: Point[]
  currentInlierPoints?: Point[]
}

@Injectable({
  providedIn: 'root'
})
export class StabilityService extends WorkerService {
  public workerName = 'stability'
  protected maxLoadingDuration = 15000
  protected maxProcessDuration = 500
  protected maxNbrOverDuration = 3

  isNew = true
  nbrOverDuration = 0

  constructor(
    protected detectService: DetectService,
    protected storageMap: StorageMap,
    protected radialService: RadialService,
    protected router: Router,
    protected configService: ConfigService,
  ) {
    super(detectService, storageMap, radialService, router, configService);
    this.isDesirable()
    this.init()
  }

  public processFrame(imageData: ImageData, width: number, height: number, withImage: boolean) {
    // --- ask the worker to find stability
    if (this.withWorker) {
      // if (false) {
      const msg = {
        cmd: 'detect',
        buffer: imageData.data.buffer,
        width: width,
        height: height,
        isNew: this.isNew,
        timestamp: performance.now(),
        withImage,
      }
      this.worker.postMessage(msg, [msg.buffer])
    }
  }

  protected isDesirable() {
    const androidVersion = this.detectService.androidVersion
    const iosVersion = this.detectService.iosVersion
    const result = environment.with.wasm[this.workerName] && // stability allowed and
      (
        (androidVersion && parseInt(androidVersion, 10) >= 8) || // very recent of Android or
        (iosVersion && parseInt(iosVersion, 10) >= 12) || // recent of iOS or
        (!androidVersion && !iosVersion) // desktop
      )
    console.log(`   stabilityServ.ts worker isDesirable(${result}) ` +
      `wasm(${environment.with.wasm[this.workerName]}), android(${androidVersion}), ios(${iosVersion})`)
    this.withWorker = result
  }

  protected onMessage(event: MessageEvent) {
    if (event.data.cmd === 'detect') {
      // convert event data
      if (!event.data.isFound) {
        event.data.isStable = false
      }
      const result: StabilityEvent = {
        status: 'ok',
        cmd: 'detect',
        isFound: event.data.isFound,
        isStable: event.data.isStable,
        dstBuffer: new ArrayBuffer(1),
        srcBuffer: event.data.buffer,
        // duration: event.data.duration,
        duration: Math.round(performance.now() - event.data.timestamp),
        previousInlierPoints: event.data.previousInlierPoints || [],
        currentInlierPoints: event.data.currentInlierPoints || [],
      }
      if (event.data.isStable) {
        this.isNew = true
      }
      if (event.data.status === 'ko') {
        // worker has crashed, disable it
        console.warn(`   stabilityServ.ts worker crash, deactivate it`)
        this.terminateWorker()
      }
      if (event.data.duration > this.maxProcessDuration) {
        this.nbrOverDuration++
        if (this.nbrOverDuration >= this.maxNbrOverDuration) {
          // mobile is not enought power to process stability
          console.log(`   stabilityServ.ts worker not speed enough ${event.data.duration} > ${this.maxProcessDuration}`)
          this.terminateWorker()
        }
      }
      this.subject.next(result)
    } else {
      console.warn('   stabilityServ.ts worker unknow cmd', event.data)
    }
  }
}
