import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest, zip } from 'rxjs'
import { first, timeout } from 'rxjs/operators'
import { AuthService } from '../auth/auth.service'
import { ConfigService } from '../services/config/config.service'
import { DocumentService } from '../services/document/document.service'
import { RadialService } from '../services/radial/radial.service'
import { ThemeService } from '../services/theme/theme.service'

@Component({
  selector: 'app-initializer',
  templateUrl: './initializer.component.html',
  styleUrls: ['./initializer.component.scss']
})
export class InitializerComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private radialService: RadialService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const theme = params.get('theme')
      if (theme) {
        this.themeService.setTheme(theme)
      }
    })

    // Wait until successful authentication (with a timeout)
    combineLatest([this.authService.onLogged$, this.route.queryParamMap]).pipe(
      first(([loggedIn]) => loggedIn),
      timeout(15000) // use for the timeout of authentification
    )
      .subscribe(
        async ([loggedIn, params]) => {
          if (!loggedIn) {
            const errMsg = 'Erreur d\'authentification'
            console.log(`  initializeComp.ts onInit`, errMsg)
            this.onFailure(errMsg)
            return
          }

          // Load the request config indicated by the route parameter if it is specified
          const requestId = params.get('k')
          if (requestId) {

            // get partial jwt
            const url = `${this.radialService.hosts.partial}/config/kycq/${requestId}`

            if (this.configService.schema !== '') {
              try {
                const config = await this.radialService.fetch(url)
                this.configService.load(requestId, config)
                console.log(`  initializeComp.ts onInit Config loaded`)
                const dateExpiration = this.configService.requestInfo.dateExpiration
                if (dateExpiration) {
                  const deadLine = new Date(dateExpiration)
                  const now = new Date()
                  if (now > deadLine) {
                    const errMsg = 'Délai expiré\nVeuillez contacter\nvotre responsable d\'agence'
                    console.log(`  initializeComp.ts onInit`, errMsg)
                    this.onFailure(errMsg)
                  } else {
                    await this.onSuccess()
                  }
                } else {
                  await this.onSuccess()
                }
              } catch (error) {
                // request ID not found
                const errMsg = 'Le délai pour transmettre votre document est dépassé.\nVous pouvez nous transmettre vos documents à tout moment\ndepuis votre espace personnel\nou nous recontacter.'
                console.log(`  initializeComp.ts onInit`, errMsg)
                this.onFailure(errMsg)
              }
            }
          } else {
            const errMsg = 'Pas de numéro d\'identification.\nVeuillez contacter\nvotre responsable d\'agence'
            console.log(`  initializeComp.ts onInit`, errMsg)
            this.onFailure(errMsg)
          }
        },
        (err) => {
          console.log(`Initialization error: ${err}`)
          const errMsg = 'Erreur d\'initialisation'
          this.onFailure(errMsg)
        }
      )
  }

  private async onSuccess(): Promise<void> {
    await this.documentService.initModels(this.configService.schema)
    this.router.navigate(['/documents'])
  }

  private onFailure(errMsg: string): void {
    this.router.navigate(['/home'], { state: { errMsg } })
  }
}
