import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Subject, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  Field, File, Children, Document, Page, parseSchema, Schema, setLogger, DocumentResult, FieldResult, FileResult,
  PageResult, Result, setRequester, Requester, logger
} from '@doclab/validation'

import { DisplayableDocument, DocumentEvent, getPageSortingOrder, Typing, ViewDocument } from '../../models/document.model'
import { RadialService } from '../radial/radial.service'
import { ConfigService } from '../config/config.service'
import { ImageService } from '../image/image.service'

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private isReady = false
  private isNewInserted = false
  private requester: Requester
  private radIdRegex = /^(.+?)(Recto|Verso|#\d+)?$/i
  private viewDocuments: ViewDocument[] = []
  private cabinet: File
  public cabinetResult: FileResult
  private lastDocIdx = -1
  private lastPageIdx = -1
  private schema: Schema
  private schemaSource: string
  private validationConfigCustom: string
  private documentDescriptions: Map<string, string> = new Map()
  private subject = new Subject<DocumentEvent>()
  public onEvent$ = this.subject.asObservable()
  private documentPages: Map<string, Set<string>> = new Map()
  private documentRequiredPages: Map<string, Set<string>> = new Map()
  public possiblePageNames = new Map<string, string>()

  public displayableDocuments: DisplayableDocument[] = []

  constructor(
    private radialService: RadialService,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private imageService: ImageService
  ) {
    // initialize requester to auto process JWT
    this.requester = {
      request: (url: string, timeoutInMs: number) => this.httpClient.get(url, { responseType: 'text' }).toPromise()
    }
  }

  public getDocumentLabel(docName: string): string {
    return this.documentDescriptions.get(docName) || docName
  }

  public getDocumentPageLabel(docName: string, pageName: string): string {
    const docLabel = this.getDocumentLabel(docName)
    const docPages = this.documentPages.get(docName)
    if (
      !pageName ||
      !docPages ||
      docPages.size === 0 ||
      (docPages.size === 1 &&
        ['recto', '#1'].includes(Array.from(docPages)[0].toLowerCase()))
    ) {
      return docLabel
    } else {
      const pageSubLabel = pageName.charAt(0) === '#' ?
        `page ${pageName.slice(1)}` :
        pageName.toLowerCase()
      return `${docLabel} (${pageSubLabel})`
    }
  }

  async initModels(schemaSource: string) {
    // get validation configuration
    const convivialConfig = this.configService.requestInfo.baseSchemaName
    this.validationConfigCustom = await this.radialService.getValidationConfig(convivialConfig).toPromise()

    // parse custom schema
    const schemaType = this.init(schemaSource)
    this.initDocs(schemaType, JSON.parse(schemaSource))
    this.isReady = true

    // notify ready
    this.subject.next({
      cmd: 'ready',
      docIdx: this.lastDocIdx,
      pageIdx: this.lastPageIdx,
      name: 'ready',
      value: 'ready',
    })
  }

  private initDocs(schema: any, rawSchema: any) {
    const requiredDocuments: string[] = []
    const file = schema.files['subscriber']
    let hasSelfie = false;
    for (const constraint of file.constraints) {
      if (constraint.type === 'RequireDocuments') {
        for (const name of constraint.required) {
          if (name === "Selfie") {
            hasSelfie = true;
          } else {
            requiredDocuments.push(name)
          }
        }
      }
    }
    if (hasSelfie) {
      requiredDocuments.push("Selfie")
    }

    const documentNames: Map<string, Set<string>> = new Map()
    function addNameToDocument(key: string, value: string): void {
      const names = documentNames.get(key) || new Set()
      names.add(value)
      documentNames.set(key, names)
    }

    this.documentDescriptions = new Map()
    const rawDocs = rawSchema.files['subscriber']['documents']
    for (const [docName, doc] of Object.entries(file.documents)) {
      const desc = doc['description'] || rawDocs[docName]?.description
      if (desc) {
        this.documentDescriptions.set(docName, desc)
      }
      addNameToDocument(docName, docName)
      const classNames = doc['classes'] || []
      for (const className of classNames) {
        addNameToDocument(className, docName)
      }

      // Retrieve the possible pages of the document
      this.documentPages.set(docName, new Set(Object.keys(doc["pages"] || {})))

      // Retrieve the required pages on the document for its constraints,
      // defaults to just 'recto'
      let requiredPages = new Set(['recto'])
      for (const constraint of (doc['constraints'] || [])) {
        if (constraint.type === 'RequirePages') {
          requiredPages = new Set(constraint.required)
        }
      }
      this.documentRequiredPages.set(docName, requiredPages)
    }

    // initialize view model
    for (const name of requiredDocuments) {
      const label = this.getDocumentLabel(name)
      this.viewDocuments.push(new ViewDocument(label, documentNames.get(name)))
    }

    // initialize validation model
    this.updateValidationFile()

    // Initialize possible page names
    const possiblePages = new Array<[string, string, string, string]>()
    for (const name of requiredDocuments) {
      const docNames = Array.from(documentNames.get(name).values() || [])
      for (const docName of docNames) {
        const docLabel = this.getDocumentLabel(docName)
        const docPages = this.documentPages.get(docName)
        if (!docPages || docPages.size === 0) {
          possiblePages.push([docName, docLabel, docName, ''])
        } else {
          docPages.forEach((pageName) => {
            const pageNameIdPostfix =
              pageName.charAt(0).toUpperCase() + pageName.slice(1).toLowerCase()
            possiblePages.push([
              `${docName}${pageNameIdPostfix}`,
              this.getDocumentPageLabel(docName, pageName),
              docName,
              pageName,
            ])
          })
        }
      }
    }
    // Sort possible page names according to natural display order
    possiblePages.sort((a, b) => {
      let order = a[2].toLowerCase().localeCompare(b[2].toLowerCase())
      if (order === 0) {
        order = a[2].localeCompare(b[2])
      }
      if (order === 0) {
        order = getPageSortingOrder(a[3]) - getPageSortingOrder(b[3])
      }
      return order
    })
    this.possiblePageNames = new Map(possiblePages.map(x => [x[0], x[1]]))
  }

  private updateValidationFile() {
    this.cabinet = new File('subscriber', new Children<Document>())
    this.viewDocuments.forEach((document) => {
      const pages = document.pages.map((page) => {
        return new Page(page.name, new Children<Field>(...(page.fields || [])))
      })
      this.cabinet.documents.push(
        new Document(document.name, new Children<Page>(...pages))
      )
    })

    this.updateDisplayableDocuments()
  }

  private updateDisplayableDocuments() {
    this.displayableDocuments = this.viewDocuments.map((viewDoc, docIndex) => {
      return new DisplayableDocument(viewDoc, this.cabinetResult?.documents[docIndex])
    })
  }

  /**
   * Insert a page if it is expected.
   *
   * @returns was the page expected (and inserted)?
   */
  insert(
    radId: string, typing: Typing, roiThumbnail: string, roiImage: Blob, analyze: any
  ): boolean {
    const radIdMatch = this.radIdRegex.exec(radId)
    const documentName = radIdMatch[1]
    const pageName = (radIdMatch[2] || 'recto').toLowerCase()

    // Find the first document/class that matches the type
    let docIdx = 0
    for (const viewDoc of this.viewDocuments) {
      if (viewDoc.matches(documentName)) {
        break
      } else {
        docIdx++
      }
    }

    // Ignore the page if it is not allowed
    if (docIdx === this.viewDocuments.length) {
      console.warn(`    documentServ.ts insert model(${radId}) not allowed`)
      return false
    }

    const doc = this.viewDocuments[docIdx]

    // Ignore the page if the page is not possible on the document type
    const lowerCaseDocumentName = documentName.toLowerCase()
    // Retrieve the document possible pages and required pages by ignoring
    // the case of its type since the comparison with radId is already
    // case-insensitive
    let docPages: Set<string>
    for (const [key, val] of Array.from(this.documentPages.entries())) {
      if (key.toLowerCase() === lowerCaseDocumentName) {
        docPages = val
        break
      }
    }
    if (docPages === undefined || !docPages.has(pageName)) {
      console.warn(`    documentServ.ts insert page ${pageName} on model(${radId}) not allowed`)
      return false
    }
    let docRequiredPages: Set<string>
    for (const [key, val] of Array.from(this.documentRequiredPages.entries())) {
      if (key.toLowerCase() === lowerCaseDocumentName) {
        docRequiredPages = val
        break
      }
    }

    // Insert new page
    const fields = []
    const document = analyze.validation.documents.find(document => document.name === documentName)
    if (document) {
      const page = document.pages.find(page => page.name === pageName)
      if (page) {
        for (const field of page.fields) {
          fields.push(new Field(field.name, field.input))
        }
      }
    }
    doc.setPage(documentName, pageName, typing, roiImage, roiThumbnail, fields, analyze)

    // Insert empty missing pages as required by constraint on document type
    // (work on a copy of the constraint not to modify the original)
    const missingPages = new Set(docRequiredPages.values())
    doc.pages.forEach((p) => {
      missingPages.delete(p.name)
    })
    missingPages.forEach((missingPageName) => {
      doc.setPage(documentName, missingPageName)
    })

    this.updateValidationFile()

    this.lastDocIdx = docIdx
    this.lastPageIdx = doc.pages.findIndex((page) => page.name === pageName)
    this.isNewInserted = true
    return true
  }

  public get isEmpty(): boolean {
    return this.viewDocuments.every((doc) => doc.missing)
  }

  public get isDone(): boolean {
    let result = false

    if (this.displayableDocuments.every((doc) => doc.done)
      || this.displayableDocuments.every((doc) => doc.typing === 'Manual')) {
        result = true
    }
    return result
  }

  // For partial send => requireConsolidate to false
  public get isPartiallyValid() {
    let result = false
    // If not all documents are valid and we are in partial send, some doc or page is good
    if (!this.configService.requireConsolidate) {
      this.displayableDocuments.forEach(document => {
        if ((document.done && document.valid) || document.pages.some(page => (page.done && page.valid))) {
          result = true
        }
      })
    }
    return result
  }

  public get isReadyToSend() {
    // If not all documents are valid and we are in partial send, some doc or page is good
    return this.isPartiallyValid || this.isValid
  }

  public get isValid(): boolean {
    let result = false
    if (this.displayableDocuments.every((doc) => doc.done && doc.valid)
      || this.displayableDocuments.every((doc) => doc.typing === 'Manual')) {
        result = true
    }
    return result
  }

  public get hasSelfie(): boolean {
    for (const doc of this.viewDocuments) {
      if (doc.label === 'Selfie') {
        return true;
      }
    };

    return false;
  }

  public get crossDocumentErrors(): string[] {
    let result = [];
    let errors = this.cabinetResult?.result.errors || [];
    for (let error of errors) {
      if (error.severity >= 0 && error.shortDescription.indexOf('document obligatoire') == -1) {
        let errorMsg = error.longDescription || error.shortDescription
        result.push(errorMsg)
      }
    }

    return result;
  }

  public get hasCrossDocumentErrors(): boolean {
    return this.crossDocumentErrors.length > 0
  }

  public getUndoneDocuments(): DisplayableDocument[] {
    let result = [];
    for (const doc of this.displayableDocuments) {
      if (!doc.done) {
        result.push(doc)
      }
    }
    return result;
  }

  public get nextDoc(): DisplayableDocument | undefined {
    const undoneDocuments = this.getUndoneDocuments();
    if (undoneDocuments.length > 0) {
      return undoneDocuments[0];
    }
    
    return undefined;
  }

  public get nextIsSelfie(): boolean {
    const undoneDocuments = this.getUndoneDocuments();
    if (undoneDocuments.length == 1) {
      if (undoneDocuments[0].label === 'Selfie') {
        return true;
      }
    }
    
    return false;
  }

  public extractPhotos(): { photo: string, radId: string }[] {
    let result = [];

    for (const doc of this.displayableDocuments) {
      if (doc.done) {
        for (const page of doc.pages) {
          const photo = page.getFieldOutput('photo');
          const radId = doc.name + page.name.charAt(0).toUpperCase() + page.name.slice(1).toLowerCase()
                            
          if (photo) {
            result.push({
              photo: photo,
              radId: radId
            });
          }
        }
      }
    }

    return result;
  }

  // For following the progression / For a next version maybe ? Dynamic loader here.
  public getProgressPercent() {
    let donePages = 0
    let pages = 0
    this.displayableDocuments.forEach(doc => {
      pages += doc.pages.length
      donePages += doc.pages.filter(page => page.done).length
    })

    return donePages / pages * 100;
  }

  public compute() {
    if (!this.isReady || this.lastDocIdx === -1 || !this.isNewInserted) { return }

    // generate event from insert
    this.isNewInserted = false
    for (const field of this.cabinet.documents[this.lastDocIdx].pages[this.lastPageIdx].fields) {
      this.subject.next({
        cmd: 'addField',
        docIdx: this.lastDocIdx,
        pageIdx: this.lastPageIdx,
        name: field.name,
        value: '',
      })
    }
    this.subject.next(
      { cmd: 'opener', docIdx: this.lastDocIdx, pageIdx: this.lastPageIdx, name: 'lastDocIdx', value: this.lastDocIdx.toString() }
    )

    const start = performance.now()
    this.schema.validateFile(
      this.cabinet,
      {
        addressCheckerUrl: `${this.radialService.hosts.partial}/tool/check_address`,
        disconnectedMode: !navigator.onLine,
      },
      ['file', 'document', 'page', 'field']
    ).then((fileResult: FileResult) => {
      console.log(`    documentServ.ts validateFile result`, fileResult)
      this.cabinetResult = fileResult
      this.parseResult()
      this.updateDisplayableDocuments()
      this.subject.next({ cmd: 'parseAll', docIdx: this.lastDocIdx, pageIdx: this.lastPageIdx, name: 'validation', value: 'parse' })
    }).catch(err => {
      console.warn(`    documentServ.ts validateFile error`, err)
    })
  }

  private parseResult() {
    this.cabinet['result'] = this.cabinetResult.result
    console.log('>>> cabinetResult', this.cabinetResult)

    // --- for each documents
    this.cabinet.documents.forEach((doc, docIdx) => {
      if (doc.name !== '') { // don't parse empty document
        const docsFound = this.cabinetResult.documents.filterByName(doc.name)
        if (docsFound.length === 1) {
          doc['result'] = docsFound[0].result

          // --- for each pages
          doc.pages.forEach((page, pageIdx) => {
            const pagesFound = docsFound[0].pages.filterByName(page.name)
            if (pagesFound.length === 1) {
              page['result'] = pagesFound[0].result

              // --- for each fields
              page.fields.forEach((field) => {
                const fieldsFound = pagesFound[0].fields.filterByName(field.name)
                // assign the raw value
                field['output'] = field.input
                if (fieldsFound.length === 1) {
                  field['result'] = fieldsFound[0].result
                  if (field['result']['errors'].length === 0) {
                    // assign the parsed value
                    if (field.name === 'adresse') {
                      const value: any = fieldsFound[0].result.value
                      field['output'] = `${value.locality || value.streetNumber + ' ' + value.streetLabel}\n` +
                        `${value.postalCode} ${value.city}`
                      field['result'].isCorrectedValue = true
                      field['result'].showInput = false
                    } else if (field.name === 'mrz') {
                      if (field['result'].errors.length === 0) {
                        const values = field['result'].value
                        if (values.hasOwnProperty('documentNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'documentNumber',
                            value: values.documentNumber,
                          })
                        }
                        if (values.hasOwnProperty('vehicleIdentificationNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'vehicleIdentificationNumber',
                            value: values.vehicleIdentificationNumber,
                          })
                        }
                        if (values.hasOwnProperty('registrationNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'registrationNumber',
                            value: values.registrationNumber,
                          })
                        }
                        if (values.hasOwnProperty('firstRegistrationYear')) {
                          const registrationYear = this.getRegistrationYear(values.firstRegistrationYear)
                          const firstRegistration = new Date(
                            registrationYear, values.firstRegistrationMonth - 1, values.firstRegistrationDay,
                          )
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'firstRegistration',
                            value: firstRegistration.toLocaleDateString(
                              navigator.language, { day: '2-digit', month: 'long', year: 'numeric' },
                            ),
                          })
                        }
                        if (values.hasOwnProperty('commercialDescription')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'make',
                            value: `${values.make} ${values.commercialDescription}`,
                          })
                        }
                        if (values.hasOwnProperty('nationalGenre')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'nationalGenre',
                            value: values.nationalGenre,
                          })
                        }
                        if (values.hasOwnProperty('nationalVehicleBody')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'nationalVehicleBody',
                            value: values.nationalVehicleBody,
                          })
                        }
                        if (values.hasOwnProperty('surname')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'surname',
                            value: values.surname,
                          })
                        }
                        if (values.hasOwnProperty('givenNames')) {
                          // don't add givenNames from MRZ because it's troncated
                        }
                        if (values.hasOwnProperty('birthDay')) {
                          const birth = new Date(values.birthYear, values.birthMonth - 1, values.birthDay)
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'birthDay',
                            value: birth.toLocaleDateString(
                              navigator.language, { day: '2-digit', month: 'long', year: 'numeric' },
                            ),
                          })
                        }
                        if (values.hasOwnProperty('expirationDay')) {
                          const expiration = new Date(values.expirationYear, values.expirationMonth - 1, values.expirationDay)
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'expirationDay',
                            value: expiration.toLocaleDateString(
                              navigator.language, { day: '2-digit', month: 'long', year: 'numeric' },
                            ),
                          })
                        }
                        if (values.hasOwnProperty('cardNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'cardNumber',
                            value: values.cardNumber,
                          })
                        }
                        if (values.hasOwnProperty('passportNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'passportNumber',
                            value: values.passportNumber,
                          })
                        }
                        if (values.hasOwnProperty('licenseNumber')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'licenseNumber',
                            value: values.licenseNumber,
                          })
                        }
                        if (values.hasOwnProperty('sex')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'sex',
                            value: values.sex,
                          })
                        }
                        if (values.hasOwnProperty('nationality')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'nationality',
                            value: values.nationality,
                          })
                        }
                        if (values.hasOwnProperty('issuingCountry')) {
                          this.subject.next({
                            cmd: 'addField',
                            docIdx: docIdx,
                            pageIdx: pageIdx,
                            name: 'issuingCountry',
                            value: values.issuingCountry,
                          })
                        }
                      }
                    }
                  }
                }
                if (this.lastDocIdx === docIdx) {
                  this.subject.next({
                    cmd: 'parseField',
                    docIdx: docIdx,
                    pageIdx: pageIdx,
                    name: field.name,
                    value: { output: field['output'], withErrors: field['result'].isKo() }
                  })
                } else {
                  this.subject.next({
                    cmd: 'addField',
                    docIdx: docIdx,
                    pageIdx: pageIdx,
                    name: field.name,
                    value: { output: field['output'], withErrors: field['result'].isKo() }
                  })
                }
              })
            }
          })
        }
      }
    })
  }

  private init(schemaData: any) {
    // initialize the validator schema
    setRequester(this.requester)
    setLogger(logger) // logger is a null console
    const schemaType = parseSchema(schemaData)
    this.schema = new Schema(schemaType)
    this.schemaSource = schemaData
    console.log(`    documentServ.ts schema initialized`)
    return schemaType
  }

  private getRegistrationYear(yearShort: number): number {
    const yearNow = new Date().getFullYear()
    const year20 = parseInt(`20${yearShort}`, 10)
    const year19 = parseInt(`19${yearShort}`, 10)

    // a date can't be in the future and more than a century
    return year20 > yearNow ? year19 : year20
  }

  public sendValidationResult(url: string) {
    return this.httpClient.post(
      url,
      this.cabinetResult,
      {
        headers: {
          'X-Skip-Jwt-Interceptor': '',
          'X-Skip-Error-Interceptor': '',
        },
        responseType: 'text'
      }
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        const msg = err instanceof Error ? err : JSON.stringify(err)
        console.log(`Failed to send validation result with error: ${msg}`)
        return throwError(err)
      })
    )
  }

  public sendFileToConvivial(partialHost: string) {
    console.log(`    documentServ.ts sendFileToConvivial collegialHost(${partialHost})`)
    const formData = new FormData()

    let imageIndex = 0
    const recognizes = []
    const analyzes = []
    this.displayableDocuments.forEach((doc) => {
      doc.pages.forEach((page) => {
        // To prevent the partial send for only valid pages.
        if (page.valid) {
          formData.append('files', page.hiResImage, `file-${imageIndex}.jpg`)
          const pageNameId =
            page.name.charAt(0).toUpperCase() + page.name.slice(1).toLowerCase()
          recognizes.push({
            radId: `${doc.name}${pageNameId}`,
            status: 'ok'
          })
          analyzes.push(
            page.analyze ?
              page.analyze :
              {
                status: 'ok',
                isFound: true,
                validation: page.result ?
                  this.makeConvivialPageValidation(
                    this.cabinetResult.name,
                    doc.name,
                    page.result
                  ) :
                  {}
              }
          )
          formData.append('pageIdxs', '-1')
          imageIndex += 1
        }
      })
    })
    formData.append('recognizes', JSON.stringify(recognizes))
    formData.append('analyzes', JSON.stringify(analyzes))

    formData.append('customer', this.configService.customer)
    formData.append('mugMod', this.configService.mug.mod)
    formData.append('mugUser', this.configService.mug.user)
    formData.append('mugGroup', this.configService.mug.group)

    const jobCallbackUrl = this.configService.requestInfo.jobCallbackUrl
    if (jobCallbackUrl !== undefined) {
      formData.append('callbackUrl', jobCallbackUrl)
    }
    formData.append('autoDelete', 'true')
    formData.append('validationSchemaCustom', this.schemaSource)
    formData.append('validationConfigCustom', JSON.stringify(this.validationConfigCustom))
    formData.append('convivialTool', this.configService.enforcedControls ? 'task-typindex' : 'none')
    
    formData.append('fsmName', 'sad')
    formData.append('paginationMode', 'batch')

    const { schema, ...requestInfo } = this.configService.requestInfo
    formData.append('custom', JSON.stringify({ requestInfo }))

    // radialHost will be used to read pdf
    formData.append('radialHost', this.radialService.hosts.radialRemote)

    // For tests
    // formData.forEach((entryValue: FormDataEntryValue) => {
    //   console.warn(entryValue)
    // })

    console.warn(`${partialHost}/file/create`, formData)
    
    return this.httpClient.post(`${partialHost}/file/create`, formData)
  }

  private makeConvivialPageValidation(
    fileName: string,
    documentName: string,
    pageResult: PageResult
  ): FileResult {
    return new FileResult(
      fileName,
      new Result('', { kind: 'nothing' }),
      new Children(
        new DocumentResult(
          documentName,
          new Result('', { kind: 'nothing' }),
          new Children(pageResult)
        )
      )
    )
  }
}
