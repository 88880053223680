import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { NgModule, Provider, LOCALE_ID, Injectable } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http'
import { environment } from '../environments/environment'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { LayoutModule } from '@angular/cdk/layout'

import { MatButtonModule } from '@angular/material/button'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCardModule } from '@angular/material/card'
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';


import { PinchZoomModule } from 'ngx-pinch-zoom'
import { RecognitionComponent } from './recognition/recognition.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { DocumentComponent } from './documents/documents.component'
import { HomeComponent } from './home/home.component'
import { ErrorInterceptor } from './error.interceptor'
import { JwtInterceptor } from './auth/jwt.interceptor'
import { SafePipe } from './safe.pipe'
import { SafeStylePipe } from './safe-style.pipe'
import { InitializerComponent } from './initializer/initializer.component'
import { ManualTypingDialogComponent } from './dialogs/manual-typing-dialog/manual-typing-dialog.component';

import {
  AuthGuardService as AuthGuard
} from '../app/services/guards/guards.service';
import { CirclePanelComponent } from './circle-panel/circle-panel.component'
import { DetectService } from './services/detect/detect.service';
import { ConfirmationSendDialogComponent } from './dialogs/confirmation-send-dialog/confirmation-send-dialog.component';
import { SendDialogComponent } from './dialogs/send-dialog/send-dialog.component';
import { GdprDialogComponent } from './dialogs/gdpr-dialog/gdpr-dialog.component';
import { DocumentCardComponent } from './documents/document-card/document-card.component';
import { ZoomDialogComponent } from './dialogs/zoom-dialog/zoom-dialog.component';
import { ConfigDetailsDialogComponent } from './config-details-bottom-sheet/config-details-bottom-sheet.component';
import { BiometryComponent } from './biometry/biometry.component'

import { BiometryDetectionComponent } from './biometry/biometry-detection/biometry-detection.component'
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component'
import { Observable } from 'rxjs'

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
    })
    return mc
  }
}

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({
      withCredentials: true // Ajouter les credentials
    });
    return next.handle(modifiedReq);
  }
}

export let appProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
]
if (environment.production) { // provider LOCALE_ID only in JIT mode
} else {
  appProviders.push({ provide: LOCALE_ID, useValue: environment.locale })
  appProviders.push(AuthGuard)
}

@NgModule({
  declarations: [
    AppComponent,
    DocumentComponent,
    BiometryDetectionComponent,
    BiometryComponent,
    RecognitionComponent,
    HomeComponent,
    SafePipe,
    SafeStylePipe,
    InitializerComponent,
    ManualTypingDialogComponent,
    CirclePanelComponent,
    ConfirmationSendDialogComponent,
    SendDialogComponent,
    GdprDialogComponent,
    DocumentCardComponent,
    ZoomDialogComponent,
    ConfigDetailsDialogComponent,
    AlertDialogComponent
  ],
  entryComponents: [
    ManualTypingDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    LayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatTabsModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    PinchZoomModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTooltipModule,
    MatBottomSheetModule
  ],
  providers: appProviders,
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private detectService: DetectService) {

    // override upload and capture environment
    if (this.detectService.isAndroid || this.detectService.isIos) {
      environment.with.feature.camera = true
      // override upload and scan environment
    } else {
      environment.with.feature.camera = false
    }

    console.log(`         appModl.ts ` +
      `ios(${this.detectService.iosVersion}) ` +
      `android(${this.detectService.androidVersion}) ` +
      `uploadOnly(${environment.with.feature.uploadOnly})`
    )
  }
}
