import { Subject } from 'rxjs'
import { StorageMap } from '@ngx-pwa/local-storage'
import { Router } from '@angular/router'
import { DetectService } from './services/detect/detect.service'
import { RadialService } from './services/radial/radial.service'
import { ConfigService } from './services/config/config.service'

export abstract class WorkerService {
  public abstract workerName
  public withWorker = false
  public worker: Worker
  public isReady = false
  public subject = new Subject<any>()
  public onEvent$ = this.subject.asObservable()

  protected maxLoadingDuration = 15000
  protected maxProcessDuration = 500

  private timeoutId: NodeJS.Timeout

  constructor(
    protected detectService: DetectService,
    protected storageMap: StorageMap,
    protected radialService: RadialService,
    protected router: Router,
    protected configService: ConfigService,
  ) { }

  public terminateWorker() {
    if (this.worker) { // not yet terminated
      this.withWorker = false // worker not good enougth. mark it as unusable
      this.worker.terminate()
      this.worker = null
      console.log(`      workerAbst.ts worker(${this.workerName}) stopped `)
    }
  }

  protected init() {
    if (!this.withWorker) {
      console.log(`      workerAbst.ts worker(${this.workerName}) Wasm desactivated`)
      this.isReady = true
      this.subject.next({ status: 'ok', cmd: 'ready' })
    } else {
      if (!this.worker) { // check in case where the worker is already launch
        // --- start a timer in case where the worker wasn't able to reload (i.e: chrome on android)
        this.timeoutId = setTimeout(() => {
          console.log(`      workerAbst.ts worker(${this.workerName}) too slow to start`)
          this.terminateWorker()
          // and send a notification that the app can continue
          this.isReady = true
          this.subject.next({ status: 'ok', cmd: 'ready' })
        }, this.maxLoadingDuration)
        const workerUrl = `./assets/${this.workerName}.worker.js`
        console.log(`      workerAbst.ts worker(${this.workerName}) load with import (${workerUrl})`)
        this.worker = new Worker(workerUrl, { name: this.workerName })
        this.worker.onmessage = (event: MessageEvent) => {
          if (event.data.cmd === 'ready') {
            console.log(`      workerAbst.ts worker(${this.workerName}) onMessage(${event.data.cmd})`)
            if (this.isReady) { // isReady have been set before by the timer
              console.log(`      workerAbst.ts worker(${this.workerName}) too late , timeout before`)
            } else {
              clearTimeout(this.timeoutId)
              this.isReady = true
              this.subject.next(event.data)
              // try to communicate
              this.worker.postMessage({ cmd: 'ping' })
            }
          } else if (event.data.cmd === 'ping') {
            console.log(`      workerAbst.ts worker(${this.workerName}) onMessage(${event.data.cmd})`)
            // communication is on
            this.onConnect()
          } else {
            // try specific processing
            this.onMessage(event)
          }

          if (event.data.error) {
            // an exception occurs -> stop it
            console.warn(`      workerAbst.ts worker(${this.workerName}) got an exception`)
            this.terminateWorker()
          }
        }
        this.worker.onerror = (event: ErrorEvent) => {
          const data: any = event['data']
          console.error(`      workerAbst.ts worker(${this.workerName}) onerror should never append`, event)
          if (data !== undefined) {
            console.warn(`      workerAbst.ts worker(${this.workerName}) onerror received`, data)
          }
        }
      } else {
        console.warn(`      workerAbst.ts worker(${this.workerName}) already started`)
      }
    }
  }
  protected abstract isDesirable()

  protected abstract onMessage(event: MessageEvent)

  protected onConnect() { }

}
