import { KeyValue } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface ManualTypingDialogData {
  readonly image: string
  readonly possiblePageNames: Map<string, string>
}

@Component({
  selector: 'app-manual-typing-dialog',
  templateUrl: './manual-typing-dialog.component.html',
  styleUrls: ['./manual-typing-dialog.component.scss']
})
export class ManualTypingDialogComponent {
  public selectedType: string = ''

  constructor(
    public dialogRef: MatDialogRef<ManualTypingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManualTypingDialogData) {}

  onSelectedType(type: string): void {
    this.selectedType = type
    // this.dialogRef.close(type)
  }

  public comparePageNames(
    _a: KeyValue<string, string>,
    _b: KeyValue<string, string>
  ): number {
    return 0
  }
}
