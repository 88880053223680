import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GdprDialogData {
  messages?: string[];
  okLabel?: string;
}

@Component({
  selector: 'app-gdpr-dialog',
  templateUrl: './gdpr-dialog.component.html',
  styleUrls: ['./gdpr-dialog.component.scss'],
})
export class GdprDialogComponent implements OnInit {
  hasValidated: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: GdprDialogData) { }

  ngOnInit(): void {}

}
