import { NgModule } from '@angular/core'
import { Routes, RouterModule, CanActivate } from '@angular/router'
import { RecognitionComponent } from './recognition/recognition.component'
import { DocumentComponent } from './documents/documents.component'
import { InitializerComponent } from './initializer/initializer.component'
import { environment } from 'src/environments/environment'
import { AuthGuard } from './auth.guard'
import { HomeComponent } from './home/home.component'
import { DocGuard } from './doc.guard'
import { BiometryComponent } from './biometry/biometry.component'

const routes: Routes = [
  {
    path: 'documents', component: DocumentComponent, data: {
      state: 'documents', page: environment.with.feature.uploadOnly ? undefined : 1,
    }, canActivate: [AuthGuard], canDeactivate: [DocGuard]
  },
  {
    path: 'biometry', component: BiometryComponent, data: {
      state: 'biometry'
    }, canActivate: [AuthGuard], canDeactivate: [DocGuard]
  },
  {
    path: 'recognition', component: RecognitionComponent, data: {
      state: 'recognition', page: environment.with.feature.uploadOnly ? undefined : 2,
    }, canActivate: [AuthGuard]
  },
  {
    path: 'home', component: HomeComponent, data: {
      state: 'home', page: environment.with.feature.uploadOnly ? 2 : 3,
    }
  },
  // ignore path, only useful for appclip
  { path: '**', component: InitializerComponent, pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
