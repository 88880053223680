import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'

export interface ButtonFromCircle {
  name: string;
  icon: string;
  action: () => void;
  isVisible?: Boolean;
}
@Component({
  selector: 'app-circle-panel',
  templateUrl: './circle-panel.component.html',
  styleUrls: ['./circle-panel.component.scss']
})
export class CirclePanelComponent implements OnInit, AfterViewInit {

  @Input() text: string | SafeHtml = ''
  securedText: SafeHtml = ''

  @Input() isError?: boolean
  // Init a progression
  @Input() progressValue?: number
  @Input() buttonList?: ButtonFromCircle[] = []

  // CallBack the function
  @Output() onButtonAction: EventEmitter<any> = new EventEmitter()

  version: string = environment.version

  percentage: number = 50

  @ViewChild('circle', { static: true }) circle: ElementRef
  spinnerDiameter: number = 10

  constructor(private domSanitizer: DomSanitizer) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const parentSize = this.circle?.nativeElement?.offsetWidth;
      this.spinnerDiameter = parentSize;
    });
  }

  ngOnInit(): void {
    if (this.text && this.text !== '') {

      // If string, make it safe
      if (typeof this.text === 'string') {
        this.securedText = this.domSanitizer.bypassSecurityTrustHtml(this.text)
      } else {
        this.securedText = this.text
      }
    }
  }

  actionIsDone(button: ButtonFromCircle) {
    this.onButtonAction.emit(button.action);
  }

}
