import { Injectable } from '@angular/core'
import { StorageMap } from '@ngx-pwa/local-storage'
import { Router } from '@angular/router'
import { ModelType, Point } from 'src/app/models/basic'
import { WorkerService } from 'src/app/worker.abstract'
import { environment } from 'src/environments/environment'
import { DetectService } from '../detect/detect.service'
import { RadialService } from '../radial/radial.service'
import { ConfigService } from '../config/config.service'
import { ImageService } from '../image/image.service'

export interface StabilityEvent {
  status: string
  cmd: string
  msg?: string
  srcBuffer?: ArrayBuffer
  dstBuffer?: ArrayBuffer
  isFound?: boolean
  isStable?: boolean
  rectPoints?: any[]
  duration?: number
  previousInlierPoints?: Point[]
  currentInlierPoints?: Point[]
}

@Injectable({
  providedIn: 'root'
})
export class RadService extends WorkerService {
  public workerName = 'rad'

  private disableLocalRad = false
  public isOnline = true
  public isAvailable = false
  protected maxLoadingDuration = 60000
  protected maxProcessDuration = 3000
  private remoteModel: any

  private detectStability = true
  private disableLocalDetection = false
  protected maxDetectProcessDuration = 500
  protected maxNbrOverDuration = 3
  private numInFlightDetections = 0
  // The minimum period during which the detections must be stable to consider
  // the camera stable
  private readonly minStabilityPeriodInMs = 50
  private lastFrameTimeStamps: number[] = []

  isNew = true
  nbrOverDuration = 0

  constructor(
    protected detectService: DetectService,
    protected storageMap: StorageMap,
    protected radialService: RadialService,
    protected router: Router,
    protected configService: ConfigService,
    private imageService: ImageService,
  ) {
    super(detectService, storageMap, radialService, router, configService)
    this.detectStability = environment.with.feature.detectStability
  }

  private get useLocalRad(): boolean {
    return this.withWorker && !this.disableLocalRad
  }

  private get useLocalDetection(): boolean {
    return this.withWorker && !this.disableLocalDetection
  }

  public initModels() {
    this.isDesirable()
    if (this.withWorker) {
      this.fetchModel('rad').then(_ => {
        if (!this.worker) { // check in case where the worker is already launch
          this.init()
        } else {
          console.warn('         radServ.ts worker already started')
        }
      }).catch(err => {
        console.log(`this.fetchModel('rad') failed with error: ${err}`)
        console.log('         radServ.ts worker, can\'t load Model,  Wasm desactivated')
        this.withWorker = false
        this.init()
        // unable to fetch model, stop the app
        this.router.navigate(['/home'], {
          state: {
            errMsg: 'Unable to reach the server!\nCheck your internet connection\nor try later.'
          }
        })
      })
    } else {
      console.log('         radServ.ts worker, can\'t load Model,  Wasm desactivated')
      this.init()
      this.isAvailable = true
      this.subject.next({ status: 'ok', cmd: 'available' })
    }
  }

  public recognizeFromImageData(image: ImageData, width: number, height: number, forceRemote: boolean = false, extra?: any) {
    console.log('         radServ.ts worker recognizeFromImageData')
    if (this.useLocalRad && !forceRemote) {
      const buffer = <ArrayBuffer>image.data.buffer
      this.recognizeFromBuffer(buffer, width, height, extra)
    } else {
      this.imageService.convertRawToImageFile(image).then(file => {
        this.recognizeFromFile(file, extra)
      })
    }
  }

  public recognizeFromBlob(blob: Blob, width: number, height: number, forceRemote: boolean = false, extra?: any) {
    if (!this.isReady) {
      this.subject.next({
        status: 'ok',
        cmd: 'recognize',
        isReady: 'ko',
        buffer: new ArrayBuffer(1),
        type: '',
        extra,
      })
    }

    if (this.useLocalRad && !forceRemote) {
      console.log('         radServ.ts worker recognizeFromBlob with worker')
      const fileReader = new FileReader()
      fileReader.onerror = (err) => {
        console.error('         radServ.ts worker FileReader onError', err)
      }
      fileReader.onloadend = () => {
        console.log('         radServ.ts worker FileReader onLoadEnd')
        const imgBuffer = <ArrayBuffer>fileReader.result
        this.recognizeFromBuffer(imgBuffer, width, height, extra)
      }
      fileReader.readAsArrayBuffer(blob)

    } else {
      console.log('         radServ.ts worker recognizeFromBlob without worker')
      this.radialService.recognize(blob, false).subscribe(event => {
        console.log(`         radServ.ts worker recognizeFromBlob http result`, event)
        this.isOnline = event.status === 'ok' || event.msg === 'notFound'
        this.subject.next({
          status: 'ok',
          cmd: 'recognize',
          msg: event.msg,
          duration: event.duration,
          isReady: 'ok',
          buffer: new ArrayBuffer(1),
          radId: event.radId,
          dbId: event.dbId,
          isFound: !(event.status === 'ko' && event.msg === 'notFound'),
          extra,
        })
      })
    }
  }

  public recognizeFromFile(file: File, extra?: any) {
    if (!this.isReady) {
      this.subject.next({
        status: 'ok',
        cmd: 'recognize',
        isReady: 'ko',
        buffer: new ArrayBuffer(1),
        type: '',
        extra,
      })
    }

    if (this.useLocalRad) {
      console.log('         radServ.ts worker recognizeFromFile with worker')

      const url = URL.createObjectURL(file)
      const image = new Image()

      image.onload = () => {
        console.log('         radServ.ts worker Image onLoad')
        URL.revokeObjectURL(url)
        const w = image.width
        const h = image.height
        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const imageData = ctx.getImageData(0, 0, w, h)
        this.recognizeFromImageData(imageData, w, h, false, extra)
      }

      image.onerror = (err) => {
        console.error('         radServ.ts worker Image onError', err)
      }

      image.src = url
    } else {
      console.log('         radServ.ts worker recognizeFromFile without worker')
      this.radialService.recognize(file, false).subscribe(event => {
        console.log('         radServ.ts recognize event', event) // DEBUG
        this.isOnline = event.status === 'ok' || event.msg === 'notFound'
        this.subject.next({
          status: 'ok',
          cmd: 'recognize',
          msg: event.msg,
          duration: event.duration,
          isReady: 'ok',
          buffer: new ArrayBuffer(1),
          radId: event.radId,
          dbId: event.dbId,
          isFound: !(event.status === 'ko' && event.msg === 'notFound'),
          extra,
        })

      })
    }
  }

  public recognizeFromBuffer(buffer: ArrayBuffer, width: number, height: number, extra?: any) {
    if (this.isReady && this.useLocalRad) {
      console.log('         radServ.ts worker recognizeFromBuffer with worker')
      // --- ask the workers to recognize
      const msg = {
        cmd: 'recognize',
        buffer,
        size: buffer.byteLength,
        width: width,
        height: height,
        extra,
      }
      this.worker.postMessage(msg) // can't pass buffer by reference because it can't used multiple time
    } else {
      this.subject.next({
        status: 'ok',
        cmd: 'recognize',
        isReady: this.isReady ? 'ok' : 'ko',
        buffer: new ArrayBuffer(1),
        type: '',
        extra,
      })
    }
  }

  public get isReadyToProcessFrame(): boolean {
    return this.isReady && this.numInFlightDetections === 0 && this.withWorker
  }


  public processFrame(imageData: ImageData, width: number, height: number, withImage: boolean) {
  }

  protected isDesirable() {
    const androidVersion = this.detectService.androidVersion
    const iosVersion = this.detectService.iosVersion
    const result = environment.with.wasm[this.workerName] && // rad allowed and
      (
        (androidVersion && parseInt(androidVersion, 10) >= 8) || // very recent of Android or
        (iosVersion && parseInt(iosVersion, 10) >= 12) || // recent of iOS or
        (!androidVersion && !iosVersion) // desktop
      )
    console.log(`         radServ.ts worker isDesirable(${result}) ` +
      `wasm(${environment.with.wasm[this.workerName]}), android(${androidVersion}), ios(${iosVersion})`)
    this.withWorker = result
  }

  protected onMessage(event: MessageEvent) {
    if (event.data.cmd === 'loadModelrad') {
      console.log(`         radServ.ts worker ${event.data.name} onRespons(${event.data.cmd}) in ${event.data.duration} ms`)
      this.isAvailable = true
      this.subject.next({ status: 'ok', cmd: 'available' })
      this.subject.next(event.data)
    } else if (event.data.cmd === 'recognize') {
      console.log(`         radServ.ts worker ${event.data.name} onRespons(${event.data.cmd})`)
      const found = event.data.hasOwnProperty('category') &&
        event.data.category !== 'NoMatchFound' &&
        event.data.category !== 'NoModelFound' &&
        event.data.category !== 'undefined' &&
        event.data.category !== ''
      event.data.isFound = found
      event.data.duration = { overall: event.data.duration }

      if (found) {
        // adapt structure to be compatible with radial
        event.data.status = 'ok'
        event.data.msg = found ? '' : event.data.category
        event.data.radId = event.data.category
        event.data.dbId = event.data.id
        delete event.data.id
        delete event.data.category
        console.log(`         radServ.ts worker ${event.data.name} Perf RAD duration ${event.data.duration.overall} ms`)
      }
      if (event.data.status === 'ko') {
        // worker has crashed, disable it
        console.warn(`         radServ.ts worker crash, disable local RAD`)
        this.disableLocalRad = true
      }
      this.subject.next(event.data)
    } else {
      console.warn(`         radServ.ts worker ${event.data.name} unknow cmd`, event.data)
    }
  }

  protected onConnect() {
    this.worker.postMessage({
      cmd: 'loadModelrad',
      models: this.remoteModel
    })
    // Delete serialized models since they are not needed anymore and could
    // end up consuming a lot of RAM
    this.remoteModel = undefined
  }

  private fetchModel(modelType: ModelType): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const modelKey = `models${modelType}`
      const descriptorType = modelType === 'rad' ?
        this.configService.radial.radDescriptor :
        this.configService.radial.roiDescriptor
      this.storageMap.get<any>(modelKey).subscribe((localModels: any) => {
        if (localModels === undefined) {
          // not yet in the local DB
          this.radialService.getModels(modelType, descriptorType, undefined).subscribe((remoteAllModels: any) => {
            if (remoteAllModels.hasOwnProperty('status')) {
              console.log(`         radServ.ts worker fetchModel request failed, exit`)
              reject()
            } else {
              console.log(`         radServ.ts worker fetchModel request succeed, initialize new models`)
              this.remoteModel = remoteAllModels.body
              // save it for the next time
              this.storageMap.set(modelKey, remoteAllModels).subscribe(() => { })
              resolve()
            }
          })
        } else {
          // check if models changed
          this.radialService.getModels(
            modelType, environment[descriptorType], localModels.source, localModels.deployDate
          ).subscribe((remoteAllModels: any) => {
            if (remoteAllModels.hasOwnProperty('status')) {
              console.log(`         radServ.ts worker fetchModel request failed, keep locals`)
              this.remoteModel = localModels.body
              resolve()
            } else {
              if (remoteAllModels.body && remoteAllModels.body.length !== 0) {
                console.log(`         radServ.ts worker fetchModel request succeed, new models`)
                this.remoteModel = remoteAllModels.body
                // save it for the next time
                this.storageMap.set(modelKey, remoteAllModels).subscribe(() => { })
                resolve()
              } else {
                console.log(`         radServ.ts worker fetchModel request succeed, nothing new`)
                this.remoteModel = localModels.body
                resolve()
              }
            }
          })
        }
      })
    })
  }
}
