
import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { Observable } from 'rxjs'

import { BiometryDetectionResult } from './biometry-detection/biometry-detection.component';
import { DocumentService } from '../services/document/document.service';
import { ImageService } from '../services/image/image.service';
import { NavigateService } from '../services/navigate/navigate.service';
import { BiometryService } from '../services/biometry/biometry.service';

@Component({
    selector: 'app-biometry',
    templateUrl: './biometry.component.html',
    styleUrls: ['./biometry.component.scss']
})
export class BiometryComponent implements OnInit, OnDestroy, AfterViewInit {
    photos: { photo: string, radId: string }[] = []

    state: string = 'initializing';

    @ViewChild('detector') detector;

    constructor(
        public documentService: DocumentService,
        public imageService: ImageService,
        public navigateService: NavigateService,
        public biometryService: BiometryService
    ) {
    }

    ngOnInit() {
    }

    async ngAfterViewInit() {
        this.extractPhotos();
    }

    ngOnDestroy() {
    }

    async onSubmitted(detectionResult: BiometryDetectionResult) {
        const selfie = detectionResult.imageData[0];
        
        let hasSelfieSpoofError = false;

        if (this.photos.length <= 0) {
            this.navigateService.move(['documents'], { fragment: "noExtractPhotosError" })
            return;
        }

        for (const p of this.photos) {
            const photo = this.imageService.dataURLtoBlob(p.photo);

            const selfieResult = await this.biometryService.selfie(photo, selfie).toPromise();
            if (!selfieResult.match) {
                hasSelfieSpoofError = true;
                break;
            }

            const spoofResult = await this.biometryService.spoof(selfie).toPromise();
            if (!spoofResult.valid) {
                hasSelfieSpoofError = true;
                break;
            }
        }

        if (hasSelfieSpoofError) {
            this.navigateService.move(['documents'], { fragment: "hasSelfieSpoofError" })
        } else {
            const selfieBase64 = await this.imageService.blobToDataURL(selfie);
            this.addSelfieDocument(selfie, selfieBase64);
        }        
    }

    private async extractPhotos() {
        this.photos = this.documentService.extractPhotos();
        if (this.photos.length <= 0) {
            this.navigateService.move(['documents'], { fragment: "noExtractPhotosError" })
        }

        // validate photos
        for (const p of this.photos) {
            const photo = this.imageService.dataURLtoBlob(p.photo);
            const radId = p.radId;

            const sentinelResult = await this.biometryService.sentinel(photo, radId).toPromise();
            if (sentinelResult < 0) {
                this.navigateService.move(['documents'], { fragment: "noExtractPhotosError" })
                return;
            }
        }

        const imageRef = this.imageService.dataURLtoBlob(this.photos[0].photo);
        this.detector.setImageRef(imageRef);

        this.state = 'initialized';
    }

    private addSelfieDocument(selfie, selfieBase64) {
        this.imageService.processImageBlobForRoi(selfie)
            .then((thumbnailImageFile) => {
                const reader = new FileReader()
                reader.onerror = (err) => {
                    throw err
                }
                reader.onloadend = () => {
                    const validation: any = {
                        name: 'subscriber',
                        result: {
                            errors: []
                        },
                        documents: [{
                            name: 'Selfie',
                            label: 'Selfie',
                            result: { errors: [] },
                            pages: [{
                                name: 'recto',
                                label: 'recto',
                                result: { errors: [] },
                                fields: [{
                                    name: 'photo',
                                    input: selfieBase64,
                                    result: {
                                        value: {
                                            kind: 'image'
                                        },
                                        'errors': []
                                    }
                                }]
                            }]
                        }]
                    }

                    this.documentService.insert(
                        "SelfieRecto",
                        'Auto',
                        reader.result as string,
                        thumbnailImageFile,
                        {
                            status: 'ok',
                            isFound: true,
                            validation,
                        }
                    );

                    this.documentService.compute()

                    this.navigateService.move(['documents']);
                }
                reader.readAsDataURL(thumbnailImageFile)
            })
    }
}
