import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ConfigService } from 'src/app/services/config/config.service';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-config-details-bottom-sheet',
  templateUrl: './config-details-bottom-sheet.component.html',
  styleUrls: ['./config-details-bottom-sheet.component.scss']
})
export class ConfigDetailsDialogComponent implements OnInit {

  version: string = environment?.version

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public config: ConfigService) { }

  ngOnInit(): void {}

}
