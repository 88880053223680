import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2
  public theme = 'default'

  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  public setTheme(theme: string) {
    console.log(`       themeServ.ts setTheme (${theme})`)

    this.theme = theme
    const classToAdd = `${theme}-mode`
    const classesToRemove = []
    document.body.classList.forEach((className) => {
      if (className.endsWith('-mode') && className !== classToAdd) {
        classesToRemove.push(className)
      }
    })
    this.renderer.addClass(document.body, classToAdd)
    classesToRemove.forEach((className) => {
      this.renderer.removeClass(document.body, className)
    })
  }

}
