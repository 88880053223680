<div class="content">
    <div *ngIf="state=='initializing'"
        class="message">
        <p>Vérification en cours ...</p>
    </div>

    <div *ngIf="state=='initialized' && detector.state=='initializing'"
        class="message">
        <p>Initialisation de la caméra ...</p>
    </div>

    <div *ngIf="state=='initialized' && detector.state=='initialized'"
        class="message">
        <p>Nous allons vérifier votre identité.</p>
        <button
            mat-raised-button
            color="primary"
            (click)="detector.pending()">
            Commencer
        </button>
    </div>

    <div *ngIf="state=='initialized' && (detector.state=='pending' || detector.state=='decounting' || detector.state=='recording')"
        class="message">
        <p>Placez vous au centre du cercle</p>
    </div>

    <div *ngIf="state=='initialized' && detector.state=='validating'"
        class="message">
        <p>Vérification de votre identité ...</p>
    </div>

    <div *ngIf="state=='initialized' && detector.state=='error'"
        class="message">
        <p>Une erreur est survenue ({{detector.error}})</p>
    </div>

    <app-biometry-detection
        #detector
        [class.disable]="state=='initialized' && detector.state=='initializing'"
        class="biometry-detection"
        (submitted)="onSubmitted($event)">
    </app-biometry-detection>
</div>