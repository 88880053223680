import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DetectService {
  androidVersion: string
  iosVersion: string
  osName: string
  isIos: boolean
  isAndroid: boolean

  constructor() {
    this.getAndroidVersion()
    this.getIosVersion()
    this.getOs()
    // androidVersion; //'4.2.1'
    // parseInt(androidVersion, 10); //4
    // parseFloat(androidVersion); //4.2
    this.isAndroid = this.androidVersion !== null
    this.isIos = this.iosVersion !== null

  }

  fileApiDrop(): boolean {
    // the only way I found
    // https://stackoverflow.com/questions/6518033/how-to-detect-browser-support-file-api-drag-n-drop
    return !(typeof window.orientation !== 'undefined')
  }

  private getAndroidVersion() {
    const ua = navigator.userAgent.toLowerCase()
    const match = ua.match(/android\s([0-9\.]*)/)
    this.androidVersion = match ? match[1] : null
  }

  private getIosVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
      this.iosVersion = `${v[1]}.${v[2]}.${v[3] || '0'}`
    } else {
      this.iosVersion = null
    }
  }

  private getOs() {
    this.osName = 'Unknown OS'
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.osName = 'Windows'
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.osName = 'MacOS'
    }
    if (navigator.appVersion.indexOf('X11') !== -1) {
      this.osName = 'UNIX'
    }
    if (navigator.appVersion.indexOf('Linux') !== -1) {
      this.osName = 'Linux'
    }
  }
}
