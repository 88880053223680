import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse
} from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { FailureResult } from './services/radial/radial.service'
import { AuthService } from './auth/auth.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not catch errors if the request has the skip header
    // (which will be removed from the final request)
    if (request.headers.has('X-Skip-Error-Interceptor')) {
      const cleanHeaders = request.headers.delete('X-Skip-Error-Interceptor')
      return next.handle(request.clone({ headers: cleanHeaders }))
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(`       errorItct.ts err=`, err)
        let msg: string

        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          msg = err.error.message
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          msg = `code=(${err.status}), msg=(${err.statusText} ${err.message})`
        }

        // return a default fallback value so app can continue
        return of(new HttpResponse<FailureResult>({ body: { status: 'ko', msg } }))
      }),
    )
  }
}
