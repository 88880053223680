import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {

  constructor() { }

  openFullscreen(htmlElement?: HTMLElement) {
    if (environment.with.feature.fullscreen) {
      console.log(`  fullscreenServ.ts openFullscreen`)
      htmlElement = htmlElement || document.documentElement

      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement['mozRequestFullScreen']) {
        /* Firefox */
        document.documentElement['mozRequestFullScreen']()
      } else if (document.documentElement['webkitRequestFullscreen']) {
        /* Chrome, Safari and Opera */
        document.documentElement['webkitRequestFullscreen']()
      } else if (document.documentElement['msRequestFullscreen']) {
        /* IE/Edge */
        document.documentElement['msRequestFullscreen']()
      }
    } else {
      console.log(`  fullscreenServ.ts openFullscreen canceled by config`)
    }
  }

}
