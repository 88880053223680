import {
  Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef, HostListener, NgZone
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { ImageCapture } from 'image-capture'
import { Rect, Point } from '../models/basic'
import { TextBox } from '../models/textDetector'
import { Subscription } from 'rxjs'
import { AuthService } from '../auth/auth.service'
import { environment } from 'src/environments/environment'
import { ConfigService } from '../services/config/config.service'
import { StabilityService } from '../services/stability/stability.service'
import { NavigateService } from '../services/navigate/navigate.service'
import { ImageService } from '../services/image/image.service'
import { RadialService } from '../services/radial/radial.service'
import { DetectService } from '../services/detect/detect.service'
import { VideoDevicesService } from '../services/video-devices/video-devices.service'
import { RadService } from '../services/rad/rad.service'
import { RoiService, SearchRoiEvent } from '../services/roi/roi.service'
import { MotionService } from '../services/motion/motion.service'
import { DocumentService } from '../services/document/document.service'

type IViewMode = 'live' | 'file'

declare var TextDetector: any

export interface ProcessFrameEvent {
  srcBuffer: ArrayBuffer
  dstBuffer: ArrayBuffer
  isStable: boolean
  rectPoints?: any[]
}

@Component({
  selector: 'app-recognition',
  templateUrl: './recognition.component.html',
  styleUrls: ['./recognition.component.scss'],
  animations: [
    trigger('obfuscationState', [
      state('hide', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('hide => show', animate('200ms cubic-bezier(0.55, 0.055, 0.675, 0.19)')), // easeInCubic
      transition('show => hide', animate('200ms cubic-bezier(0.215, 0.61, 0.355, 1)')) // easeOutCubic
    ])
  ]
})
export class RecognitionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('defaultButton') defaultButton: ElementRef<HTMLButtonElement>
  @ViewChild('video', { static: true }) videoElm: ElementRef<HTMLVideoElement>
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef<HTMLCanvasElement>
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>

  onAuth: Subscription
  onRad: Subscription
  onRoi: Subscription
  onReady: Subscription
  getDelta: Subscription
  onMotion: Subscription
  onStability: Subscription

  shutterClick: HTMLAudioElement
  focusBeep: HTMLAudioElement
  viewMode: IViewMode = 'file'
  withCompositeImage = false
  isNewDetection = false
  isRadInProgress = false
  isAutoRecognize = false
  isInProgress = false
  isDisplayedStillImage = false
  isFileUploadInProgress = false
  isReadyToProcessUpload = false
  isBadOrientation = false
  roiFound = ''
  progressMsg = ''
  progressTimeout: NodeJS.Timeout
  manualRecognition = false
  id: string

  debug = false
  isVideoReady = false
  obfuscationState = 'hide'
  obfuscationColor = 'transparent'
  showDocumentUnknown = false
  radIdFound = ''
  dbIdFound = ''
  durationObj = 0
  durationPic = 0
  durationRad = 0
  durationRoi = 0
  nbrBadRecognize = 0
  recognizeForMotionStability = false
  recognizeForManual = false
  isMotionFixed = false
  isStabilityFixed = false
  stabilityLines: Point[][] = [[], []]

  drawRectPoints: Rect = null
  prevRectPoints: Rect
  nextRectPoints: Rect
  maxRectDebounce = 15
  nbrRectDebounce = this.maxRectDebounce
  boundingBoxes: TextBox[]
  hiResSnapshot: Blob
  loResSnapshot: ImageData
  frameSnapshot: ArrayBuffer

  fileChosen: File = null

  video: HTMLVideoElement
  canvas: HTMLCanvasElement
  image: HTMLImageElement
  canvasCtx: CanvasRenderingContext2D
  imageCapture: ImageCapture
  photoCapabilities: any
  photoMaxWidth: number
  photoMaxHeight: number

  offscreenCanvas: HTMLCanvasElement
  offscreenCtx: CanvasRenderingContext2D
  detectLastFrameImageData: ImageData

  videoWidth: number
  videoHeight: number

  ticksFps = { 'detect': [], 'render': [] }
  countFps = { 'detect': 0, 'render': 0 }
  animationDetectFrameId = 0
  animationRenderFrameId = 0

  isLogged = false
  userName: string
  rippleInterval: NodeJS.Timeout
  showStatusBar = false

  constructor(
    public configService: ConfigService,
    public videoDevicesService: VideoDevicesService,
    public detectService: DetectService,
    public stabilityService: StabilityService,
    public radService: RadService,
    public roiService: RoiService,
    public motionService: MotionService,
    public navigateService: NavigateService,
    private radialService: RadialService,
    private documentService: DocumentService,
    private authService: AuthService,
    private imageService: ImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.id = this.activatedRoute.snapshot.queryParamMap?.get('k')
    console.log(` recognitionComp.ts constructor id(${this.id})`)

    this.userName = this.authService.anonymousName
    this.shutterClick = new Audio('/assets/sounds/camera-shutter-click.wav')
    this.focusBeep = new Audio('/assets/sounds/camera-focus-beep.wav')
  }

  ngOnInit() {
    console.log(` recognitionComp.ts onInit`)

    // prepare user wakeup
    // setTimeout(() => {
    // this.rippleInterval = setInterval(() => {
    //   if (this.defaultButton) { // could be in progress
    //     this.defaultButton['ripple'].launch({ centered: true })
    //   }
    // }, 1500)
    // }, 15000)

    // load config in case of direct page welcome access
    this.authService.onLogged$.subscribe(async (onLogin) => {
      if (onLogin && this.id) {
        const url = `${this.radialService.hosts.partial}/api/kycq_config/${this.id}`
        const config = await this.radialService.fetch(url)
        this.configService.load(this.id, config)
        this.documentService.initModels(this.configService.schema)
      }
    })

    this.video = this.videoElm.nativeElement
    this.canvas = this.canvasElm.nativeElement
    this.canvasCtx = this.canvas.getContext('2d')

    this.offscreenCanvas = document.createElement('canvas')
    this.offscreenCtx = this.offscreenCanvas.getContext('2d')

    this.onAuth = this.authService.onLogged$.subscribe(isLogged => {
      this.isLogged = isLogged
      this.userName = this.authService.user.id
    })

    this.onRad = this.radService.onEvent$.subscribe(async (msg) => {
      if (msg.cmd === 'ready') {
        console.log(` recognitionComp.ts radService ready`)
      } else if (msg.cmd === 'recognize') {
        this.isRadInProgress = false
        // if an upload occurs between rad process, ignore his result
        if (this.isAutoRecognize && this.viewMode === 'file') {
          console.log(` recognitionComp.ts skip rad result, because this result is outdated`)
        } else {
          if (msg.status === 'ok' && msg.isFound && msg.hasOwnProperty('radId') && msg.hasOwnProperty('dbId') && this.roiService.isReady) {
            console.log(` recognitionComp.ts onRecognize radId=(${msg.radId}) dbId=(${msg.dbId})`)
            this.radIdFound = msg.radId
            this.dbIdFound = msg.dbId
            this.durationRad = msg.duration ? Math.round(msg.duration.overall) : -1
            // if (!this.manualRecognition) { // don't trigger animation twice. already done with manual click
            //   this.apertureAnimation()
            // }

            // start the roi processing and prepare fake message
            this.isInProgress = true // in case where come from processFrame
            this.progressMsg = 'Analyse ...'
            this.progressTimeout = setTimeout(() => {
              this.progressMsg = 'Lecture ...'
              this.progressTimeout = setTimeout(() => {
                this.progressMsg = 'Compilation ...'
                this.progressTimeout = setTimeout(() => {
                  this.progressMsg = 'Post-traitement ...'
                  this.progressTimeout = setTimeout(() => {
                    this.progressMsg = 'Classement ...'
                    this.progressTimeout = setTimeout(() => {
                      // searchRoi is stuck, cancel it
                      console.log(` recognitionComp.ts force cancel searchRoi`)
                      this.isInProgress = false
                      this.radIdFound = ''
                      this.dbIdFound = ''
                      this.showDocumentUnknown = true
                      this.manualRecognition = false
                      this.showLiveStream()
                      setTimeout(() => {
                        this.showDocumentUnknown = false
                      }, 5000)
                    }, 5000)
                  }, 2000)
                }, 2000)
              }, 2000)
            }, 2000)

            // if recognize is automatic, get hiResSnapshot
            if (!this.manualRecognition) {
              if (environment.with.feature.hiResPhoto) { // HiRes
                this.hiResSnapshot = await this.takePhotoFromCamera()
              } else { // LowRes
                this.hiResSnapshot = await this.takePhotoFromVideo()
              }
              this.showCapturedPhoto()
            }
            this.roiService.searchRoi(msg.srcBuffer, msg.width, msg.height, this.hiResSnapshot, msg.dbId, msg.radId)
          } else {
            this.isInProgress = false
            this.radIdFound = ''
            this.dbIdFound = ''
            this.durationRad = msg.duration ? Math.round(msg.duration.overall) : -1
            if (this.manualRecognition) {
              this.showDocumentUnknown = true
              this.manualRecognition = false
              setTimeout(() => {
                this.showDocumentUnknown = false
              }, 5000)
              console.log(` recognitionComp.ts request onSwitch video after unknown manual reco`)
              this.showLiveStream()
            }

            if (this.viewMode === 'file') {
              this.showDocumentUnknown = true
              this.manualRecognition = false
              setTimeout(() => {
                this.showDocumentUnknown = false
              }, 5000)
              console.log(` recognitionComp.ts request onSwitch video after unknown file reco`)
              this.showLiveStream()
            }
            this.nbrBadRecognize++
            console.log(` recognitionComp.ts onRecognize=(${msg.msg}) nbrBadRecognize(${this.nbrBadRecognize})`)

            // be sure to have a video frame
            if (this.isDisplayedStillImage) {
              console.log(` recognitionComp.ts request onSwitch video after displayed still image`)
              this.manualRecognition = false
              this.showLiveStream()
            }
          }

          // >>> DEBUG only
          // const formData: FormData = new FormData()
          // formData.append('file', new Blob([msg.buffer]))
          // formData.append('width', this.videoWidth.toString())
          // formData.append('height', this.videoHeight.toString())
          // this.httpClient.post(`${hosts.save}/sift/save`, formData).subscribe(event => {
          //   console.log(` recognitionComp.ts recognition save`, event)
          // })
          // <<< DEBUG end
        }
        this.isAutoRecognize = false
      } else if (msg.cmd === 'loadModelrad') {
        // model rad are ready, now load roi
        // already made in app.component.ts : this.roiService.initModels()
      } else {
        console.log(` recognitionComp.ts unknown cmd`, msg)
      }
    })

    this.onRoi = this.roiService.onEvent$.subscribe(event => {
      if (event.cmd === 'searchRoi') {
        const msg: SearchRoiEvent = event
        console.log(` recognitionComp.ts onSearchRoi`, msg)
        if (this.progressTimeout) {
          clearTimeout(this.progressTimeout)
        }
        this.progressMsg = ''
        this.isInProgress = false
        this.durationRoi = Math.round(msg.duration)
        this.roiFound = msg.isFound ? 'OK' : 'KO'
        let tryAgain = !msg.isFound
        if (msg.isFound) {
          const result = this.documentService.insert(
            this.radIdFound, 'Auto', msg.roiImage, this.hiResSnapshot, {
            status: msg.status,
            isFound: msg.isFound,
            validation: msg.validation,
          })

          if (result) {
            this.radIdFound = ''
            this.dbIdFound = ''
            this.navigateService.to('/document')
          } else {
            tryAgain = true
          }
        }

        if (tryAgain) {
          this.radIdFound = ''
          this.dbIdFound = ''
          if (this.manualRecognition) {
            this.showDocumentUnknown = true
            this.manualRecognition = false
            setTimeout(() => {
              this.showDocumentUnknown = false
            }, 5000)
          }
          console.log(` recognitionComp.ts request onSwitch video after unknown worker reco`)
          this.showLiveStream()
        }
      } else {
        console.log(` recognitionComp.ts unknown cmd`, event)
      }
    })
  }

  ngAfterViewInit() {
    console.log(` recognitionComp.ts ngAfterViewInit`)
    this.onReady = this.videoDevicesService.onReady().subscribe(null, null, () => {
      console.log(` recognitionComp.ts videoDevicesService onReady`)
      this.viewMode = this.videoDevicesService.haveDevice ? 'live' : 'file'
      // avoid ExpressionChangedAfterItHasBeenCheckedError http://tiny.cc/6xee2y
      this.cd.detectChanges()
      this.onSwitch(0)
    })

    this.onMotion = this.motionService.onStable.subscribe((event) => {
      this.isMotionFixed = event.state === 'stable'
      if (event.changement && event.state === 'unstable') {
        // reset the lock of recognizing
        console.log(` recognitionComp.ts onStable changement reset badReco`)
        this.nbrBadRecognize = 0
      }
      if (this.isVideoReady && (event.state === 'stable' && this.nbrBadRecognize < 2)) {
        // mark to recognize next frame
        this.recognizeForMotionStability = true
      }
      if (event.changement) {
        console.log(` recognitionComp.ts onStable changement badReco(${this.nbrBadRecognize})`, event)
      }
    })

    this.onStability = this.stabilityService.onEvent$.subscribe(event => {
      if (event.cmd === 'detect') {
        this.durationObj = event.duration
        this.stabilityLines[0] = event.previousInlierPoints
        this.stabilityLines[1] = event.currentInlierPoints
        if (event.isStable) {
          this.recognizeForMotionStability = true
          this.isStabilityFixed = true
          setTimeout(_ => this.isStabilityFixed = false, 3000)
        }
      }
    })
  }

  ngOnDestroy() {
    console.log(` recognitionComp.ts onDestroy`)
    clearInterval(this.rippleInterval)

    this.stabilityLines = [[], []]
    this.stopVideoProcessing()
    if (this.onAuth) { this.onAuth.unsubscribe() }
    if (this.onRad) { this.onRad.unsubscribe() }
    if (this.onRoi) { this.onRoi.unsubscribe() }
    if (this.onReady) { this.onReady.unsubscribe() }
    if (this.getDelta) { this.getDelta.unsubscribe() }
    if (this.onMotion) { this.onMotion.unsubscribe() }
    if (this.onStability) { this.onStability.unsubscribe() }
  }

  @HostListener('window:resize', [])
  onResize() {
    console.log(` recognitionComp.ts window resize video
    top=(${this.video.offsetTop}) left=(${this.video.offsetLeft})
    videoW=(${this.video.videoWidth}) videoH=(${this.video.videoHeight})
    clientW=(${this.video.clientWidth}) clientH=(${this.video.clientHeight})
    activeW(${this.videoWidth}) activeH(${this.videoHeight})`
    )

    // change canvas orientation
    const isLandscape = this.video.clientWidth > this.video.clientHeight

    if (isLandscape) { // landscape
      this.canvas.width = this.offscreenCanvas.width = this.videoWidth
      this.canvas.height = this.offscreenCanvas.height = this.videoHeight
      console.log(` recognitionComp.ts resize set canvas in landscape mode ` +
        `video(${this.video.clientWidth}x${this.video.clientHeight}) ` +
        `canvas(${this.canvas.width}x${this.canvas.height})` +
        `active(${this.videoWidth}x${this.videoHeight})`)
    } else { // Portrait : keep the same the landscape, but not for iOS
      if (this.detectService.isIos) {
        this.canvas.width = this.offscreenCanvas.width = this.videoHeight
        this.canvas.height = this.offscreenCanvas.height = this.videoWidth
      } else {
        this.canvas.width = this.offscreenCanvas.width = this.videoWidth
        this.canvas.height = this.offscreenCanvas.height = this.videoHeight
      }
      console.log(` recognitionComp.ts resize set canvas in portrait mode ` +
        `video(${this.video.clientWidth}x${this.video.clientHeight}) ` +
        `canvas(${this.canvas.width}x${this.canvas.height})` +
        `active(${this.videoWidth}x${this.videoHeight})`)
    }
    this.offscreenCtx.save()
    this.canvasCtx.save()
    this.drawRectPoints = null // force clip rectangle to be recompute
  }

  @HostListener(environment.with.feature.detectMotion ? 'window:devicemotion' : 'none', ['$event'])
  onDeviceMotion(event: DeviceMotionEvent) {
    // don't use z because some phone add Gravity
    this.motionService.tickMotion(event.acceleration.x, event.acceleration.y)
  }

  onCanPlay(event: Event) {
    console.log(` recognitionComp.ts onCanPlay video w(${this.video.videoWidth}), h(${this.video.videoHeight})`)
    this.isVideoReady = true
    this.startVideoProcessing()
  }

  onSwitch(delta: number) {
    console.log(` recognitionComp.ts onSwitch video delta(${delta})`)


    if (this.viewMode === 'file') {
      this.viewMode = 'live'
      this.fileChosen = null
      delta = 0 // keep the same cam when come from file
    }

    // --- stop the previous one if any
    this.stopVideoProcessing()

    // --- play the new one
    this.getDelta = this.videoDevicesService.get(delta).subscribe((stream: MediaStream) => {

      this.video.srcObject = stream
      this.video.play()

      const track = this.video.srcObject.getVideoTracks()[0]
      const activeVideoSettings = track.getSettings()
      this.videoWidth = activeVideoSettings.width
      this.videoHeight = activeVideoSettings.height

      this.imageCapture = new ImageCapture(track)
      // console.log('>>> activeVideoSettings', activeVideoSettings)
      // console.log('>>> imageCapture', this.imageCapture)

      this.imageCapture.getPhotoCapabilities().then(photoCapabilities => {
        this.photoCapabilities = photoCapabilities
        this.photoMaxWidth = photoCapabilities.imageWidth.max || Number.MAX_SAFE_INTEGER // secure because iOS return 0
        this.photoMaxHeight = photoCapabilities.imageHeight.max || Number.MAX_SAFE_INTEGER

        console.log(` recognitionComp.ts onSwitch video end. getPhotoCapabilities` +
          `maxWidth(${this.photoMaxWidth}), maxHeight(${this.photoMaxHeight})`, photoCapabilities)
      })

      // update the canvas drawing size. The viewport size is already setup by CSS
      this.onResize()
    })
  }
  onUpload() {
    console.log(` recognitionComp.ts onUpload`)

    if (this.manualRecognition) {
      // don't allow reco if already in process
      console.log(' recognitionComp.ts onCanvasRemoteClick canceled, already inProgress')
      return
    }

    this.isInProgress = true
    this.progressMsg = 'Reconnaissance ...'
    this.manualRecognition = true
    this.recognizeForManual = true

    if (this.viewMode === 'file') {
      this.isReadyToProcessUpload = false
      this.hiResSnapshot = this.fileChosen
      // file is already displayed inside image tag
      this.isRadInProgress = true
      this.radService.recognizeFromFile(this.fileChosen)
    } else {
      this.focusBeep.play()
    }
  }

  onDragOver(event) {
    event.preventDefault()
  }

  onDrop(event) {
    if (this.viewMode === 'live') {
      this.viewMode = 'file'
      this.stopVideoProcessing()
    }
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files && files[0]) {
      this.processUploadFile(files[0]).then(() => this.isFileUploadInProgress = false)
    }
  }

  private apertureAnimation() {
    // start aperture animation
    console.log(' recognitionComp.ts start aperture animation')
    this.shutterClick.play()
    this.obfuscationColor = 'white'
    this.obfuscationState = 'show'
    setTimeout(() => {
      this.obfuscationState = 'hide'
      this.obfuscationColor = 'transparent' // must force transparent for iOS opacity bug
    }, 200)
  }

  startVideoProcessing() {
    this.onResize() // initialize corner
    this.animationDetectFrameId = requestAnimationFrame((time) => { this.detectRectangle(time) })
    if (environment.with.feature.render) {
      this.animationRenderFrameId = requestAnimationFrame((time) => { this.renderRectangle(time) })
    }
    console.log(` recognitionComp.ts startVideoProcessing frameId(${this.animationDetectFrameId})`)
  }

  stopVideoProcessing() {
    console.log(` recognitionComp.ts stopVideoProcessing frameId(${this.animationDetectFrameId})`)

    cancelAnimationFrame(this.animationDetectFrameId)
    this.animationDetectFrameId = 0
    cancelAnimationFrame(this.animationRenderFrameId)
    this.animationRenderFrameId = 0

    if (this.video.srcObject) {
      const stream: MediaStream = <MediaStream>this.video.srcObject
      stream.getVideoTracks().forEach(track => track.stop())
      console.log(` recognitionComp.ts stopVideoProcessing stop tracks`)
    }
  }

  async detectRectangle(timeFrame: number): Promise<void> {
    this.tick('detect')

    // detect stability if ready and not yet found
    if (this.stabilityService.isReady && this.dbIdFound === '' && this.radService.isReady) {

      // catch the video frame
      this.offscreenCtx.drawImage(this.video, 0, 0)
      const videoFrameImageData = this.offscreenCtx.getImageData(
        0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height
      )
      this.stabilityService.processFrame(
        videoFrameImageData, this.offscreenCanvas.width, this.offscreenCanvas.height, this.withCompositeImage
      )
    }

    // new code bellow

    // launch a RAD if the rectangle is stable and not yet recognizing and rad worker available
    if (this.recognizeForMotionStability && this.dbIdFound === '' && !this.isRadInProgress &&
      this.radService.isReady) {

      // reset flags
      this.recognizeForMotionStability = false
      this.isRadInProgress = true
      this.isAutoRecognize = true

      // catch the video frame
      this.offscreenCtx.drawImage(this.video, 0, 0)
      this.loResSnapshot = this.offscreenCtx.getImageData(
        0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height
      )
      this.recognize(false)
      this.focusBeep.play()
    }

    // launch a RAD on user action and if a reco isn't already in progress, wait his result
    if (this.recognizeForManual && !this.isRadInProgress && this.radService.isReady) {
      this.recognizeForManual = false
      this.isRadInProgress = true

      // catch the video frame
      this.offscreenCtx.drawImage(this.video, 0, 0)
      this.loResSnapshot = this.offscreenCtx.getImageData(
        0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height
      )

      if (environment.with.feature.hiResPhoto) { // HiRes
        this.hiResSnapshot = await this.takePhotoFromCamera()
        // await this.showCapturedPhotoFromBlob(this.hiResSnapshot)

      } else { // LowRes
        this.hiResSnapshot = await this.takePhotoFromVideo()
        this.showCapturedPhoto(this.loResSnapshot)
      }

      this.recognize(false) // old code say 'true'
      this.apertureAnimation()
    }

    // request for a next video frame
    this.animationDetectFrameId = requestAnimationFrame((time) => { this.detectRectangle(time) })
    return

    if (false) { // >>> DEBUG only
      // this.offscreenCanvas.toBlob(blob => {
      //   const formData: FormData = new FormData()
      //   formData.append('file', blob)
      //   this.httpClient.post(`${hosts.save}/crucial/save`, formData).subscribe(event => {
      //     console.log(` recognitionComp.ts /crucial/save result`, event)
      //   })
      // })
    } // <<< DEBUG end

    //
    // if (window['TextDetector'] !== undefined) {
    //   this.imageCapture.grabFrame().then(imageBitmap => {
    //     const textDetector = new TextDetector()
    //     textDetector.detect(imageBitmap).then(boundingBoxes => {
    //       this.boundingBoxes = boundingBoxes
    //       // this.TestOcrRemote()
    //     }).catch(err => {
    //       console.log(' recognitionComp.ts detect error', err)
    //     })
    //   })
    // }
  }

  renderRectangle(timeFrame: number) {
    this.tick('render')

    if (this.manualRecognition) {
      // show image only the firstTime
      if (!this.isDisplayedStillImage) {
        const videFrameImagaData = this.offscreenCtx.getImageData(
          0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height
        )

        this.canvasCtx.putImageData(videFrameImagaData, 0, 0)
        this.isDisplayedStillImage = true
      }

    } else {
      // don't call custom rectangle if given by the back end or if image is freezed
      if (!this.withCompositeImage && !this.isDisplayedStillImage) {
        this.applyCustomRectFilter()
      }
    }
    this.animationRenderFrameId = requestAnimationFrame((time) => { this.renderRectangle(time) })
  }

  private applyCustomRectFilter() {
    if (!this.drawRectPoints) {
      this.drawRectPoints = this.buildDefaultRect()
    }

    if (this.nextRectPoints) {
      this.prevRectPoints = this.drawRectPoints
      this.drawRectPoints = this.nextRectPoints
      this.nbrRectDebounce = this.maxRectDebounce // reset the debounce
    } else {
      this.nbrRectDebounce--
      if (this.nbrRectDebounce === 0) {
        // restaure the default rectangle
        this.prevRectPoints = this.drawRectPoints
        this.drawRectPoints = this.buildDefaultRect()
      } else {
        // TODO interpolate the rectangle
      }
    }

    // flag to draw the cliping region or not. Only for powerfull
    const withClip = this.countFps.render > 20 && !this.detectService.isIos
    if (withClip) {
      // draw clipping region
      this.canvasCtx.save()
      this.canvasCtx.filter = 'grayscale(100%) blur(3px)'
      this.canvasCtx.drawImage(this.video, 0, 0)
      this.canvasCtx.restore()
      this.canvasCtx.save()
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(this.drawRectPoints[0].x, this.drawRectPoints[0].y)
      this.canvasCtx.lineTo(this.drawRectPoints[1].x, this.drawRectPoints[1].y)
      this.canvasCtx.lineTo(this.drawRectPoints[2].x, this.drawRectPoints[2].y)
      this.canvasCtx.lineTo(this.drawRectPoints[3].x, this.drawRectPoints[3].y)
      this.canvasCtx.clip()
      this.canvasCtx.drawImage(this.video, 0, 0)
      this.canvasCtx.restore()
    } else {
      this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    // Draw rectangle
    if (this.drawRectPoints !== null) {
      const pts = this.drawRectPoints
      const everialLightBlue50 = '#E0F4FC'
      this.canvasCtx.strokeStyle = everialLightBlue50
      this.canvasCtx.lineWidth = this.detectService.iosVersion ? 4 : 2
      this.canvasCtx.fillStyle = everialLightBlue50

      // --- draw polygon
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(pts[0].x, pts[0].y)
      this.canvasCtx.lineTo(pts[1].x, pts[1].y)
      this.canvasCtx.lineTo(pts[2].x, pts[2].y)
      this.canvasCtx.lineTo(pts[3].x, pts[3].y)
      this.canvasCtx.closePath()
      this.canvasCtx.stroke()

      // --- draw corner
      const segLen = 40
      this.canvasCtx.lineWidth = this.detectService.iosVersion ? 20 : 10
      this.canvasCtx.lineCap = 'round'
      this.canvasCtx.lineJoin = 'bevel'
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(pts[0].x, pts[0].y + segLen)
      this.canvasCtx.lineTo(pts[0].x, pts[0].y)
      this.canvasCtx.lineTo(pts[0].x + segLen, pts[0].y)
      this.canvasCtx.moveTo(pts[1].x - segLen, pts[1].y)
      this.canvasCtx.lineTo(pts[1].x, pts[1].y)
      this.canvasCtx.lineTo(pts[1].x, pts[1].y + segLen)
      this.canvasCtx.moveTo(pts[2].x, pts[2].y - segLen)
      this.canvasCtx.lineTo(pts[2].x, pts[2].y)
      this.canvasCtx.lineTo(pts[2].x - segLen, pts[2].y)
      this.canvasCtx.moveTo(pts[3].x + segLen, pts[3].y)
      this.canvasCtx.lineTo(pts[3].x, pts[3].y)
      this.canvasCtx.lineTo(pts[3].x, pts[3].y - segLen)
      this.canvasCtx.stroke()

      // --- draw text boxes
      this.drawTextBoxes()
      this.drawStabilityLines()
    }
  }


  formatDuration(duration: number = 0, len: number): string {
    return duration.toString().padStart(len, '0')
  }

  onStopRad() {
    this.radService.terminateWorker()
  }

  onStopRoi() {
    this.roiService.terminateWorker()
  }

  private async processUploadFile(file: File): Promise<void> {
    if (file.type === 'application/pdf') {
      const blob = await this.radialService.pdf2png(file).toPromise()
      this.fileChosen = new File([blob], 'tmp.jpg')
    } else {
      this.fileChosen = file
    }

    await new Promise<void>((resolve, reject) => {
      const url = URL.createObjectURL(this.fileChosen)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)

        // If the source image is bigger than the given max size then
        // scale it down and keep its original ratio
        const maxSize = 6209280 // 6 MPix 2880x2156
        const resolution = image.width * image.height
        if (resolution > maxSize) {
          const ratio = Math.sqrt(maxSize / resolution)
          const outHeight = Math.floor(image.height * ratio)
          const outWidth = Math.floor(image.width * ratio)
          console.log(` recognitionServ.ts resize big image ` +
            `from ${image.width}x${image.height} to ${outWidth}x${outHeight}`
          )

          // resize the image
          const canvas = document.createElement('canvas')
          canvas.width = outWidth
          canvas.height = outHeight
          const canvasCtx = canvas.getContext('2d')
          this.scaleToFit(canvas, canvasCtx, image)
          this.imageService.canvasToBlob(canvas, (blob) => {
            this.fileChosen = new File([blob], 'tmp.jpg')
            resolve()
          }, 'image/jpeg', 1)
        } else {
          resolve()
        }
      }
      image.onerror = () => {
        console.warn(' recognitionComp.ts bad file chosen')
        URL.revokeObjectURL(url)
        reject()
      }
      image.src = url
    })
    this.isReadyToProcessUpload = true
  }

  private buildDefaultRect(): Rect {
    if (this.detectService.isIos) {
      return null
    }

    const gutterRatio = this.detectService.isIos ? 1 : 1
    let clipMargin
    if (this.video.clientWidth > this.video.clientHeight) { // landscape
      clipMargin = { x: 120 * gutterRatio, y: 50 * gutterRatio }
    } else { // Portrait
      clipMargin = { x: 50 * gutterRatio, y: 120 * gutterRatio }
    }

    const clipRect = {
      x: clipMargin.x,
      y: clipMargin.y,
      w: this.canvas.width - (clipMargin.x * 2),
      h: this.canvas.height - (clipMargin.y * 2)
    }

    const result: Rect = [
      { x: clipRect.x, y: clipRect.y },
      { x: clipRect.x + clipRect.w, y: clipRect.y },
      { x: clipRect.x + clipRect.w, y: clipRect.y + clipRect.h },
      { x: clipRect.x, y: clipRect.y + clipRect.h },
    ]
    return result
  }

  private tick(key: string) {
    const decimalPlaces = 0
    const updateEachSecond = 4
    const decimalPlacesRatio = Math.pow(10, decimalPlaces)

    this.ticksFps[key].push(performance.now())

    const msPassed = this.ticksFps[key][this.ticksFps[key].length - 1] - this.ticksFps[key][0]

    if (msPassed >= updateEachSecond * 1000) {
      this.countFps[key] = Math.round(this.ticksFps[key].length / msPassed * 1000 * decimalPlacesRatio) / decimalPlacesRatio
      this.ticksFps[key] = []
    }
  }

  private drawTextBoxes() {
    if (this.boundingBoxes && this.boundingBoxes.length > 0) {
      const everialRed500 = '#F29091'
      this.canvasCtx.strokeStyle = everialRed500
      this.canvasCtx.fillStyle = everialRed500
      this.canvasCtx.lineWidth = 2

      for (const box of this.boundingBoxes) {
        // --- draw polygon
        this.canvasCtx.beginPath()
        this.canvasCtx.moveTo(box.cornerPoints[0].x, box.cornerPoints[0].y)
        this.canvasCtx.lineTo(box.cornerPoints[1].x, box.cornerPoints[1].y)
        this.canvasCtx.lineTo(box.cornerPoints[2].x, box.cornerPoints[2].y)
        this.canvasCtx.lineTo(box.cornerPoints[3].x, box.cornerPoints[3].y)
        this.canvasCtx.closePath()
        this.canvasCtx.stroke()

        if (box.rawValue !== '') {
          this.canvasCtx.font = `${box.boundingBox.height - 8}px sans-serif`
          this.canvasCtx.fillText(box.rawValue, box.cornerPoints[3].x, box.cornerPoints[3].y)
        }
      }
    }
  }

  private drawStabilityLines() {
    if (!this.stabilityLines[0] || !this.stabilityLines[1]) { return }
    // --- draw stability lines
    this.canvasCtx.save()
    this.canvasCtx.strokeStyle = '#ffe0e0'
    this.canvasCtx.lineWidth = this.detectService.isIos ? 8 : 4
    this.canvasCtx.shadowBlur = this.detectService.isIos ? 32 : 16
    this.canvasCtx.shadowColor = '#ef5350'

    const radius = this.detectService.isIos ? 24 : 8
    for (let i = 0; i < this.stabilityLines[0].length; i++) {
      this.canvasCtx.beginPath()
      this.canvasCtx.moveTo(this.stabilityLines[0][i].x, this.stabilityLines[0][i].y)
      this.canvasCtx.lineTo(this.stabilityLines[1][i].x, this.stabilityLines[1][i].y)
      this.canvasCtx.arc(this.stabilityLines[1][i].x, this.stabilityLines[1][i].y, radius, 0, 2 * Math.PI)
      this.canvasCtx.stroke()
    }
    this.canvasCtx.restore()
  }

  private async recognize(forceRemote: boolean): Promise<void> {
    if (this.radService.withWorker && !forceRemote) { // with worker
      // copy loResSnapshot because it will be neutraled by worker
      const imageData = this.offscreenCtx.createImageData(this.loResSnapshot.width, this.loResSnapshot.height)
      imageData.data.set(this.loResSnapshot.data)
      this.radService.recognizeFromBuffer(imageData.data.buffer, imageData.width, imageData.height)
    } else { // remote
      this.radService.recognizeFromImageData(this.loResSnapshot, this.loResSnapshot.width, this.loResSnapshot.height, true)
    }
  }

  private async takePhotoFromCamera(): Promise<Blob> { // highest resolution
    const picStart = performance.now()
    // fillLightMode: 'auto' | 'off' | 'flash'

    const imageWidth = Math.min(this.photoMaxWidth, 3000)
    const imageHeight = Math.min(this.photoMaxHeight, 2000)
    const photo = await this.imageCapture.takePhoto({ imageWidth, imageHeight })
    this.durationPic = Math.round(performance.now() - picStart)
    console.log(` recognitionComp.ts HiRes photo taken in ${this.durationPic} ms`)
    return photo
  }

  private async takePhotoFromVideo(): Promise<Blob> { // resolution of the video frame
    const picStart = performance.now()
    const canvas = document.createElement('canvas')
    canvas.width = this.loResSnapshot.width
    canvas.height = this.loResSnapshot.height
    const canvasCtx = canvas.getContext('2d')
    canvasCtx.putImageData(this.loResSnapshot, 0, 0)
    return await new Promise(resolve => {
      canvas.toBlob(blob => {
        const durationPic = Math.round(performance.now() - picStart)
        console.log(` recognitionComp.ts LoRes photo taken in ${durationPic} ms`)
        resolve(blob)
      })
    })
  }

  private takePhotoFromFile(): Promise<ImageData> {
    const picStart = performance.now()
    const canvas = document.createElement('canvas')
    canvas.width = this.offscreenCanvas.width
    canvas.height = this.offscreenCanvas.height
    const canvasCtx = canvas.getContext('2d')
    canvasCtx.fillStyle = 'white'
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
    const url = URL.createObjectURL(this.fileChosen)
    const image = new Image
    return new Promise((resolve, reject) => {
      image.onload = () => {
        this.scaleToFit(canvas, canvasCtx, image)
        const imageData = canvasCtx.getImageData(0, 0, canvas.width, canvas.height)
        URL.revokeObjectURL(image.src)
        const durationPic = Math.round(performance.now() - picStart)
        console.log(` recognitionComp.ts Files photo taken in ${durationPic} ms`)
        resolve(imageData)
      }
      image.onerror = () => {
        reject()
      }
      image.src = url
    })
  }

  private scaleToFit(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, image: HTMLImageElement) {
    // get the scale
    const scale = Math.min(canvas.width / image.width, canvas.height / image.height)
    // get the top left position of the image
    const x = (canvas.width / 2) - (image.width / 2) * scale
    const y = (canvas.height / 2) - (image.height / 2) * scale
    ctx.drawImage(image, x, y, image.width * scale, image.height * scale)
  }

  private showCapturedPhoto(videoFrameImageData?: ImageData) {
    console.log(` recognitionComp.ts showCapturedPhoto video`)
    if (videoFrameImageData === undefined) {
      // catch the video frame
      this.offscreenCtx.drawImage(this.video, 0, 0)
      videoFrameImageData = this.offscreenCtx.getImageData(
        0, 0, this.offscreenCanvas.width, this.offscreenCanvas.height
      )
    }
    this.canvasCtx.putImageData(videoFrameImageData, 0, 0)
    this.isDisplayedStillImage = true
  }

  private showCapturedPhotoFromBlob(blob: Blob): Promise<void> {
    return new Promise<void>(resolve => {
      const url = URL.createObjectURL(blob)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)
        this.scaleToFit(this.canvas, this.canvasCtx, image)
        resolve()
      }
      image.src = url
    })
  }

  private showLiveStream() {
    if (this.viewMode === 'file' || this.detectService.iosVersion) { // force restart video
      this.onSwitch(0)
    }
    this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.isDisplayedStillImage = false
  }

}
