import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'
import { ConfigService } from '../services/config/config.service'


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private configService: ConfigService,
  ) {
    console.log(`         jwtServ.ts constructor user(${authService.user.id})`)
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Do not add Authorization header if the request has the skip header
    // (which will be removed from the final request)
    if (request.headers.has('X-Skip-Jwt-Interceptor')) {
      const cleanHeaders = request.headers.delete('X-Skip-Jwt-Interceptor')
      request = request.clone({ headers: cleanHeaders })
      return next.handle(request)
    }

    // add authorization header with jwt token if available
    if (this.authService.user && this.authService.user.jwt
      && request.url.indexOf('openid-connect') === -1) { // don't send JWT for url that request it
      let credentials = this.authService.user.jwt

      // Override quota user name if defined in Kycq configuration
      // The optional token must be Base64URL-encoded
      const opt_token = btoa(JSON.stringify({ user_name: this.configService.quotaUser }))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
      credentials += `~${opt_token}`

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${credentials}`,
        }
      })
    }

    return next.handle(request)
  }
}
