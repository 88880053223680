// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  locale: 'fr',
  version: require('../../package.json').version,
  partialHost: 'https://bff-linker.test.wizidee.com',
  //partialHost: 'https://localhost:8400',
  authHost: 'https://auth.test.wizidee.com',
  addressCheckerHost: 'https://address-checker.test.wizidee.com',
  tokensHost: 'https://tokens.test.wizidee.com',
  collegialHost: 'https://collegial.test.wizidee.com',
  // collegialHost: 'https://localhost:8200',
  sentinelHost: 'https://sentinel.test.wizidee.com',
  selfieHost: 'https://selfie.test.wizidee.com',
  spoofHost: 'https://spoof.test.wizidee.com',
  auth: {
    crucial: {
      realm: 'quota',
      clientId: 'api',
      log: 'crucial',
      pwd: 'eiViyaixa5Si',
    },
    partial: {
      realm: 'partial',
      clientId: 'tools',
      log: 'partial',
      pwd: '9BfShVze%9m33?%-',
    },
  },
  with: {
    wasm: { stability: false, rad: false, roi: false },
    feature: {
      fullscreen: false,
      camera: false,
      scanner: false,
      detectMotion: false,
      detectStability: false, // used only inside rad worker
      hiResPhoto: false,
      render: false,
      uploadOnly: true, // bypass recognition page, but overridable by app.module
      showPageFields: false,
    },
  },
  pubKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo8gFEV2js4HucrIO4qpI
zueIACC4CLqmHvlAK9hKa6YnnlqdGk0J9ZL3e4tS7KsJiL1wdBVifC4iW+Ov13gI
mh0zCHABXRe0Df24tw/Qmdhkzq2zBt295PhxVRBIfmtqOx2fUIQULyvERWsdLh5G
yud9Drsl1wQsq235KCOTWQRDQTLYPHJEnaDSZR40PnCaE5fXf0BaJb4KMK7GNMu6
yWREkF9GFDreavP4aiSg4gDdVCybN/Fp3SPt/Zkt7XOe8gFfVsML43bCaplUdfkZ
QUo8D5l1s/FU1h7IH4G/Pc1Kwl03Pd0SI7vTlQJGv+1EkEqZO75jMb/rw9n7rT/L
qQIDAQAB
-----END PUBLIC KEY-----`,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'  // Included with Angular CLI.
