<h1 mat-dialog-title>Document non reconnu automatiquement</h1>
<mat-dialog-content>
  <p>Le type du document n'a pas été reconnu automatiquement, merci de le sélectionner :</p>

    <mat-form-field appearance="outline">
      <mat-label>Type du document</mat-label>
      <mat-select (selectionChange)="onSelectedType($event.value)">
        <mat-option class="manual-typing-option" 
        *ngFor="let name of data.possiblePageNames | keyvalue: comparePageNames"
        [value]="name.key">{{ name.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <div class="photo">
    <img [src]="data.image | safe" />
  </div>

  <div class="container-warning">
    <mat-icon>warning</mat-icon>
    <span class="msg-warning">Nous ne pouvons garantir la véracité de ce document, ce typage relève de votre responsabilité.</span>
  </div>
  
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Annuler</button>
  <button mat-raised-button [disabled]="selectedType ===''" [mat-dialog-close]="selectedType">Confirmer</button>
</div>
