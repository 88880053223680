import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, CanActivate } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { NavigateService } from '../navigate/navigate.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router, private activatedRoute: ActivatedRoute, public navigateService: NavigateService) {
  }
  canActivate(): boolean {
    if (!this.authService.isLoggedIn) {
      this.navigateService.to('/home')
    }
    return true
  }
}
