import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface ZoomDialogData {
  docPageLabel: string;
  image: string
}

@Component({
  selector: 'app-zoom-dialog',
  templateUrl: './zoom-dialog.component.html',
  styleUrls: ['./zoom-dialog.component.scss']
})
export class ZoomDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZoomDialogData,
              private el: ElementRef) {}

  ngOnInit(): void {}

  // Zoom image or not
  switchZoomMode() {
    const image: HTMLElement = this.el.nativeElement.querySelector('img')
    if (image.classList.contains('zoomed')) {
      image.classList.remove('zoomed')
    } else {
      image.classList.add('zoomed')
    }
  }

}
