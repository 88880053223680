<video id="video" #video (canplay)="onCanPlay($event)" autoplay playsinline></video>
<canvas id="canvas" #canvas [hidden]="!isVideoReady" style=' border: 15px solid #808080;'></canvas>
<div id="obfuscation" [@obfuscationState]="obfuscationState" [style.background-color]="obfuscationColor"></div>
<div id="holder" (drop)="onDrop($event)" (dragover)="onDragOver($event)" style=' border: 15px solid #808080;'>
  <div id="work-area" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1">
    <div class="status-bar" fxLayout="column" fxLayoutAlign="center center"
         [ngStyle]="{'visibility': showStatusBar?'visible':'hidden'}">
      <div id="component" fxLayoutAlign="center center">
        <mat-icon *ngIf="isLogged">label</mat-icon>
        <mat-icon *ngIf="!isLogged">label_off</mat-icon>
        <span class="item">{{userName}}</span>
        <mat-icon *ngIf="!motionService.isAvailable">gps_off</mat-icon>
        <mat-icon *ngIf="motionService.isAvailable && !isMotionFixed">gps_not_fixed</mat-icon>
        <mat-icon *ngIf="motionService.isAvailable && isMotionFixed">gps_fixed</mat-icon>
        <span class="item">acc</span>
        <mat-icon *ngIf="!stabilityService.isReady">cached</mat-icon>
        <mat-icon *ngIf="stabilityService.isReady && stabilityService.worker && !isStabilityFixed">flight</mat-icon>
        <mat-icon *ngIf="stabilityService.isReady && !stabilityService.worker">airplanemode_inactive</mat-icon>
        <mat-icon *ngIf="stabilityService.isReady && stabilityService.worker && isStabilityFixed">gps_fixed</mat-icon>
        <span class="item">obj</span>
        <mat-icon *ngIf="!radService.isReady">cached</mat-icon>
        <mat-icon *ngIf="radService.isReady && radService.worker">flight</mat-icon>
        <mat-icon *ngIf="radService.isReady && !radService.worker && radService.isOnline">cloud</mat-icon>
        <mat-icon *ngIf="radService.isReady && !radService.worker && !radService.isOnline">cloud_off</mat-icon>
        <span class="item" (click)="onStopRad()">rad</span>
        <mat-icon *ngIf="!roiService.isReady">cached</mat-icon>
        <mat-icon *ngIf="roiService.isReady && roiService.worker">flight</mat-icon>
        <mat-icon *ngIf="roiService.isReady && !roiService.worker && roiService.isOnline">cloud</mat-icon>
        <mat-icon *ngIf="roiService.isReady && !roiService.worker && !roiService.isOnline">cloud_off</mat-icon>
        <span class="item" (click)="onStopRoi()">roi</span>
      </div>
      <div id="duration" fxLayoutAlign="center center">
        <mat-icon>schedule</mat-icon>
        <span class="item">obj(<span class="digit">{{formatDuration(durationObj, 3)}}</span>)</span>
        <span class="item">pic(<span class="digit">{{formatDuration(durationPic, 3)}}</span>)</span>
        <span class="item">{{radIdFound || 'rad'}}(<span class="digit">{{formatDuration(durationRad, 4)}}</span>)</span>
        <span class="item">roi{{roiFound}}(<span class="digit">{{formatDuration(durationRoi, 4)}}</span>)</span>
        <span>ms</span>
      </div>
    </div>

    <ng-container *ngIf="!isInProgress; else elseProcessing">
      <ng-container *ngIf="!captureIsPaused; else pausedCapture">
        <mat-icon *ngIf="!isVideoReady" [ngClass]="{'no-camera': !videoDevicesService.haveDevice}" id="camera-icon"
                  aria-label="Camera Icon">photo_camera</mat-icon>
        <h1 class="no-camera" [hidden]="!showDocumentUnknown">
          Document non reconnu. Recommencer!
        </h1>
      </ng-container>
    </ng-container>
    <ng-template #elseProcessing>
      <mat-spinner diameter="64" class="no-camera" color="warn"></mat-spinner>
      <h1 class="no-camera"> {{progressMsg}} </h1>
    </ng-template>
    <ng-template #pausedCapture>
      <ng-container *ngIf="viewMode === 'live'">
        <h1 *ngIf="!radService.isAvailable" class="no-camera"> Initialisation </h1>
        <h1 *ngIf="radService.isAvailable" class="no-camera"> Toucher ou cliquer l'écran pour
          démarrer la capture </h1>
      </ng-container>
    </ng-template>

    <div [ngStyle]="{'visibility': showStatusBar?'visible':'hidden'}">
      <div id="detect-fps" class="fps">Detect {{countFps.detect}} FPS</div>
      <div id="render-fps" class="fps">Render {{countFps.render}} FPS</div>
    </div>
  </div>
  <div id="footer" class="custom-toolbar mat-elevation-z2" fxLayoutAlign="space-around center"
       style='border-top: 15px solid #808080;'>

    <span fxLayoutAlign="center center" class="big">
      <ng-container *ngIf="!isVideoReady || !radService.isAvailable; else elseUpload">
        <mat-spinner class="big" color="primary" diameter="96"></mat-spinner>
      </ng-container>
      <ng-template #elseUpload>
        <button *ngIf="videoDevicesService.haveDevice" class="big" mat-fab (click)="onUpload()" #defaultButton
                color="primary" [disabled]="manualRecognition || isFileUploadInProgress">
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon>{{viewMode === 'file' ? 'cloud_upload' : 'add_a_photo'}}</mat-icon>
            {{viewMode === 'file' ? 'Envoyer' : 'Photo'}}
          </div>
        </button>
      </ng-template>
    </span>
  </div>
</div>
<div id="orientation" *ngIf="isBadOrientation" fxLayout="column" fxLayoutAlign="center center">
  <h1>Retourner votre appareil en mode portrait</h1>
  <h2>Le mode paysage n'est pas supporté sur iOS</h2>
</div>