import { Injectable } from '@angular/core'
import { Router, Event, ActivationEnd, NavigationExtras } from '@angular/router'
import { FullscreenService } from '../fullscreen/fullscreen.service'

interface IRouteParams {
  state: string
  page: number
}

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  pageIdx: number
  pages: Array<IRouteParams> = []

  constructor(
    private router: Router,
    private fullscreenService: FullscreenService,
  ) {
    console.log('    navigateServ.ts constructor')
    this.pages = this.router.config
      .filter(route => route.data && route.data.hasOwnProperty('page'))
      .map(route => <IRouteParams>route.data)

    this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        this.pageIdx = event.snapshot.data.page
      }
    })
  }

  to(page: string) {
    console.log(`    navigateServ.ts to(${page})`)
    this.fullscreenService.openFullscreen()
    this.router.navigate([page])
  }

  move(commands: any[], extra?: NavigationExtras) {
    this.fullscreenService.openFullscreen()
    this.router.navigate(commands, extra)
  }
}
