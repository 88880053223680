import { Injectable, HostListener } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

export type MotionState = 'stable' | 'unstable'
export interface StableEvent {
  changement: boolean
  state: MotionState
  value: number
}

@Injectable({
  providedIn: 'root'
})
export class MotionService {
  isAvailable = false
  start = 0
  lastState: MotionState = 'unstable'
  ticks: number[] = []
  stableSubject = new Subject<StableEvent>()
  onStable: Observable<StableEvent> = this.stableSubject.asObservable()

  constructor() { }

  public tickMotion(x: number, y: number) {
    // no call, means no motion captors
    if (!this.isAvailable) {
      this.isAvailable = environment.with.feature.detectMotion
    } else {
      const threshold = 0.20 // higher required less stability, lower more stable (ex: 0.10)
      const decimalPlaces = 2
      const updateEachSecond = 0.3
      const decimalPlacesRatio = Math.pow(10, decimalPlaces)

      if (this.start === 0) {
        this.start = performance.now()
      }
      this.ticks.push(
        Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
      )

      const msPassed = performance.now() - this.start

      if (msPassed >= updateEachSecond * 1000) {
        const vSum = this.ticks.reduce((acc, val) => acc + val, 0)
        const count = Math.round((vSum / this.ticks.length) * decimalPlacesRatio) / decimalPlacesRatio

        this.start = 0
        this.ticks = []
        const newState: MotionState = count <= threshold ? 'stable' : 'unstable'
        this.stableSubject.next({ changement: this.lastState !== newState, state: newState, value: count })
        this.lastState = newState
      }
    }
  }
}
