import * as escapeStringRegexp from 'escape-string-regexp'
import {
  Field, Children, DocumentResult, FieldResult,
  PageResult
} from '@doclab/validation'

export type Typing = 'Auto' | 'Manual'

export const getPageSortingOrder = (pageName: string): number => {
  const pageNameOrder = ['', 'recto', 'verso']
  let i = pageNameOrder.indexOf(pageName)
  if (i === -1 && pageName.startsWith('#')) {
    i = parseInt(pageName.slice(1), 10) + pageNameOrder.length
  }
  return i
}

/**
 * A view page is a page of an expected document.
 *
 * A page is considered missing if it was not assigned images and fields.
 */
export class ViewPage {
  /**
   * Fields of the page whose non-corrected input is shown.
   *
   * This is meaningful only for fields that have corrected values.
   */
  private shownInputFields = new Set<string>()

  constructor(
    public readonly name: string,
    public readonly typing: Typing,
    public readonly hiResImage?: Blob,
    public readonly image?: string,
    public readonly fields?: Field[],
    public readonly analyze?: any,
  ) {
  }

  public get missing(): boolean {
    return this.fields === undefined
  }

  public isFieldInputShown(fieldName: string): boolean {
    return this.shownInputFields.has(fieldName)
  }

  public showFieldInput(fieldName: string, show: boolean): void {
    if (show) {
      this.shownInputFields.add(fieldName)
    } else {
      this.shownInputFields.delete(fieldName)
    }
  }
}

/**
 * A view document is an expected document while it is missing.
 *
 * It is considered missing if it only contains missing pages.
 *
 * A document can be of different expected types given at construction time,
 * it can only be one of them or none at any given time.
 *
 * A new document starts with one missing page and it does not have a
 * type then.
 *
 * When a page is set on a document all previously existing pages of this
 * document will be removed if they have a different document type.
 *
 * When a page is set on a document other empty missing pages of the same
 * document type should be set as required by the document type's constraints.
 */
export class ViewDocument {
  public pages = [new ViewPage('', 'Auto')]
  private _name: string
  private allowedModelsRegexp: RegExp

  constructor(public readonly label: string, allowedModelNames: Set<string>) {
    const allowedModelsPattern = (
      Array.from(allowedModelNames.values())
        .map(s => `(?:^${escapeStringRegexp(s)}$)`)
        .join('|')
    )
    this.allowedModelsRegexp = new RegExp(allowedModelsPattern, 'i')
  }

  public get name(): string {
    return this._name
  }

  public get missing(): boolean {
    return this.pages.every((page) => page.missing)
  }

  /**
   * The typing of the document is the typing of all its pages if it has
   * at least one page and they are all typed the same else it is undefined.
   */
  public get typing(): Typing | undefined {
    if (this.pages.length !== 0) {
      for (const type of ['Auto', 'Manual'] as Typing[]) {
        if (this.pages.every((page) => page.typing === type)) {
          return type
        }
      }
    }
    return undefined
  }

  public matches(documentName: string): boolean {
    return this.allowedModelsRegexp.test(documentName)
  }

  public setPage(
    documentName: string,
    pageName: string,
    typing: Typing = 'Auto',
    hiResImage?: Blob,
    image?: string,
    fields?: Field[],
    analyze?: any,
  ) {
    if (this._name !== documentName) {
      this.pages = []
      this._name = documentName
    }

    this.pages = this.pages.filter((p) => p.name !== pageName)
    this.pages.push(new ViewPage(pageName, typing, hiResImage, image, fields, analyze))
    this.pages.sort((a, b) => {
      return getPageSortingOrder(a.name) - getPageSortingOrder(b.name)
    })
  }
}

/**
 * A displayable page brings together a view page and its validation
 * result.
 */
export class DisplayablePage {
  public result?: PageResult
  public fields?: Children<FieldResult>
  public analyze?: any
  public name: string

  constructor(private viewPage: ViewPage, result: PageResult | undefined) {
    this.result = result
    this.fields = result?.fields
    this.name = viewPage.name
    this.analyze = viewPage.analyze
  }

  public get image(): string {
    return this.viewPage.image
  }

  public get hiResImage(): Blob {
    return this.viewPage.hiResImage
  }

  public get missing(): boolean {
    return this.viewPage.missing
  }

  public get typing(): Typing {
    return this.viewPage.typing
  }

  public get inputFields(): Field[] | undefined {
    return this.viewPage.fields
  }

  public getFieldOutput(fieldName: string): string | undefined {
    const nameFields = this.fields?.filterByName(fieldName)
    if (!nameFields || nameFields.length === 0) {
      return undefined
    }
    const field = nameFields[0]

    let output = field.input

    const v = field.result.value
    switch (v.kind) {
      case 'address':
        output = `${v.locality || v.streetNumber + ' ' + v.streetLabel}\n` +
          `${v.postalCode} ${v.city}`
        break
    }

    return output
  }

  public hasFieldCorrectedOutput(fieldName: string): boolean {
    const field = this.fields?.filterByName(fieldName)[0]
    return field && this.getFieldOutput(fieldName) !== field.input
  }

  public isFieldInputShown(fieldName: string): boolean {
    return this.viewPage.isFieldInputShown(fieldName)
  }

  public showFieldInput(fieldName: string, show: boolean): void {
    this.viewPage.showFieldInput(fieldName, show)
  }

  public get done(): boolean {
    return this.viewPage.typing === 'Manual' || (
      !this.viewPage.missing &&
      this.result?.result.isOk()
    )
  }

  public get valid(): boolean {
    return this.viewPage.typing === 'Manual' || (
      !this.viewPage.missing &&
      this.result?.result.isOk() &&
      !this.fields.some(field => field.result.isKo())
    )
  }

}

/**
 * A displayable document brings together a view document and its validation
 * result.
 */
export class DisplayableDocument {
  public pages: DisplayablePage[]
  public result?: DocumentResult
  public name: string

  constructor(
    private viewDoc: ViewDocument,
    result: DocumentResult | undefined
  ) {
    this.result = result
    this.name = viewDoc.name

    this.pages = viewDoc.pages.map((viewPage, pageIndex) => {
      return new DisplayablePage(viewPage, result?.pages[pageIndex])
    })
  }

  public get label(): string {
    return this.viewDoc.label
  }

  public get missing(): boolean {
    return this.viewDoc.missing
  }

  public get typing(): Typing | undefined {
    return this.viewDoc.typing
  }

  /**
   * A document can be done even if it has errors.
   * Document errors are considered as warnings for the moment.
   */
  public get done(): boolean {
    return this.typing === 'Manual' || (
      !this.missing &&
      this.pages.every((page) => page.done)
    )
  }

  /**
   * A document can be valid even if all its pages are not done.
   */
  public get valid(): boolean {
    return this.typing === 'Manual' || (
      !this.missing &&
      this.result?.result.isOk()
    )
  }
}

export interface DocumentEvent {
  cmd: string
  docIdx: number
  pageIdx: number
  name: string
  value: any
}
