import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, NgZone } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ManualTypingDialogComponent } from '../dialogs/manual-typing-dialog/manual-typing-dialog.component'
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Location, HashLocationStrategy, KeyValue, LocationStrategy } from '@angular/common'
import { AuthService } from '../auth/auth.service'
import { DisplayableDocument, DisplayablePage } from '../models/document.model'
import { WebTwain } from 'dwt/dist/types/WebTwain'
import { ConfigService } from '../services/config/config.service'
import { DocumentService } from '../services/document/document.service'
import { ImageService } from '../services/image/image.service'
import { NavigateService } from '../services/navigate/navigate.service'
import { RoiService, SearchRoiEvent } from '../services/roi/roi.service'
import { RadService } from '../services/rad/rad.service'
import { RadialService } from '../services/radial/radial.service'
import { ConfirmationSendDialogComponent } from '../dialogs/confirmation-send-dialog/confirmation-send-dialog.component'
import { SendDialogComponent, SendDialogData } from '../dialogs/send-dialog/send-dialog.component'
import { GdprDialogComponent } from '../dialogs/gdpr-dialog/gdpr-dialog.component'
import { ZoomDialogComponent } from '../dialogs/zoom-dialog/zoom-dialog.component'
import { AlertDialogComponent } from '../dialogs/alert-dialog/alert-dialog.component'

export interface page {}
export interface document {}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentComponent implements OnInit, OnDestroy {
  state$: Observable<object>

  @ViewChild('defaultButton', { static: true }) defaultButton: ElementRef<HTMLButtonElement>
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>

  // Drag and drop
  @ViewChild('docs', { static: true }) docsElement: ElementRef<HTMLInputElement>

  DWObject: WebTwain
  selectSources: HTMLSelectElement
  containerId = 'container'
  bWASM = false
  deviceList = []
  version = environment.version
  lastDocIdx = -1
  lastPageIdx = -1
  docSubscription: Subscription
  onRad: Subscription
  onRoi: Subscription
  progressMsg = ''
  progressTimeout: NodeJS.Timeout
  manualRecognition = false
  canSelectFile = false
  isIntroduction = true
  id: string
  display = true
  fileChosen: File = null
  key: string = ''

  infoMessage: string = ''

  // Drag and drop files
  isDragging = false;

  constructor(
    public configService: ConfigService,
    public documentService: DocumentService,
    public imageService: ImageService,
    public navigateService: NavigateService,
    public radService: RadService,
    public roiService: RoiService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private location: Location) {
    console.log(`    documentComp.ts constructor`)
    this.id = this.activatedRoute.snapshot.queryParamMap?.get('k')
  }

  get uploadOnly(): boolean {
    return environment.with.feature.uploadOnly
  }

  get showPageFields(): boolean {
    return environment.with.feature.showPageFields
  }

  public get isInProgress(): boolean {
    return !!this.progressMsg
  }

  private updateProgressMessage(message?: string) {
    this.progressMsg = message
    this.cd.detectChanges()
  }

  public diemphased(doc: DisplayableDocument): boolean {
    if (!this.configService.stepByStep) {
      return false;
    }

    if (this.documentService.isReadyToSend) {
      return false;
    }

    if (this.documentService.nextDoc == doc) {
      return false;
    }

    return !doc.done;
  }

  ngOnInit() {
    let showGdpr = true;
    const fragment = this.activatedRoute.snapshot.fragment;
    if (fragment === "noExtractPhotosError") {
      showGdpr = false;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: { 
          title: "Erreur",
          message: "Nous n'arrivons pas à trouver un document avec une photo."
        },
        disableClose: true
      });
    } else if (fragment === "hasSelfieSpoofError") {
      showGdpr = false;
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: { 
          title: "Erreur",
          message: "Nous n'arrivons pas à vous reconnaître."
        },
        disableClose: true
      });
    }
    this.location.replaceState("documents");
    
    // Don't allow the navigation to the previous page to limit security issues...
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };

    // Gdpr
    if (this.configService?.gdprMessages?.length > 0 && showGdpr) {
      this.openGdprDialog()
    }

    if (this.configService?.messages.length > 0) {
      this.infoMessage = this.configService?.messages?.join('<br>')
    } else {
      this.infoMessage = 'Bonjour ! Afin de finaliser votre dossier, merci de bien vouloir transmettre les documents demandés.'
    }

    console.log(`    documentComp.ts onInit`)
    this.docSubscription = this.documentService.onEvent$.subscribe(event => {
      switch (event.cmd) {
        case 'opener':
          this.lastDocIdx = event.docIdx
          this.lastPageIdx = event.pageIdx
          break
      }

      this.cd.detectChanges()
    })

    this.documentService.compute()
    if (this.uploadOnly) {
      this.onUploadOnlyInit()
    }
  }

  ngOnDestroy() {
    console.log(`    documentComp.ts onDestroy`)
    if (this.docSubscription) { this.docSubscription.unsubscribe() }
    if (this.onRad) { this.onRad.unsubscribe() }
    if (this.onRoi) { this.onRoi.unsubscribe() }
  }

  /* Drag and drop files */
  onDrop(event: DragEvent, onDocZone?: boolean): void {
    if (!this.documentService.isReadyToSend) {
      event.preventDefault();
      this.docsElement.nativeElement.classList.remove('drag-over')
      this.isDragging = false;

      if (onDocZone) {
        const transferredFiles: FileList = event.dataTransfer?.files;
        this.onSelectedFile(transferredFiles)
      }
    }
  }

  onDragOver(event: DragEvent): void {
    if (!this.documentService.isReadyToSend) {
      event.preventDefault();
      this.docsElement.nativeElement.classList.add('drag-over')
      this.isDragging = true
    }
  }

  onDragLeave(event: DragEvent): void {
    if (!this.documentService.isReadyToSend) {
      event.preventDefault();
      this.docsElement.nativeElement.classList.remove('drag-over')
      this.isDragging = false;
    }
  }
  // ------------

  private onServiceReadinessUpdate(): void {
    const previousValue = this.canSelectFile
    this.canSelectFile = this.radService.isAvailable && this.roiService.isReady
    if (previousValue !== this.canSelectFile) {
      this.cd.detectChanges()
    }
  }

  onUploadOnlyInit() {
    this.onRad = this.radService.onEvent$.subscribe(async (msg) => {
      if (msg.cmd === 'recognize') {
        if (msg.status === 'ok' && msg.isFound && msg.hasOwnProperty('radId') && msg.hasOwnProperty('dbId') && this.roiService.isReady) {
          console.log(`    documentServ.ts on recognize radId=(${msg.radId}) dbId=(${msg.dbId})`)
          const extra = { cancelled: false, hiResSnapshot: msg.extra.hiResSnapshot, radId: msg.radId }
          this.followRoiProgress(extra)
          this.roiService.searchRoi(msg.srcBuffer, msg.width, msg.height, msg.extra.hiResSnapshot, msg.dbId, msg.radId, extra)
        } else {
          this.onRecognitionEnd('failure')
        }
      } else if (msg.cmd === 'ready' || msg.cmd === 'available') {
        this.onServiceReadinessUpdate()
      }
    })

    this.onRoi = this.roiService.onEvent$.subscribe(async (event) => {
      if (event.cmd === 'searchRoi') {
        const msg: SearchRoiEvent = event
        console.log('on searchRoi', msg)
        if (this.progressTimeout) {
          clearTimeout(this.progressTimeout)
        }
        // Accept also pages without found ROIs but then make them look as
        // if they had been typed manually
        if (msg.isFound) {
          this.onRecognitionEnd('success')
        } else {
          // Manual Type
          if (this.configService.allowManualTyping) {
            this.onRecognitionEnd('success')
            this.onFailedRecognitionManualFallback(msg.extra.hiResSnapshot)
          } else {
            this.onRecognitionEnd('failure')
          }
        }

        const roiImage = msg.roiImage ?
          this.imageService.dataURLtoBlob(msg.roiImage) :
          msg.extra.hiResSnapshot

        if (msg.isFound && !msg.extra.cancelled) {
          // Make a thumbnail image if search-roi did not return one
          if (msg.roiImage) {
            this.insertPage(
              msg.roiImage,
              roiImage,
              msg.isFound,
              msg.extra.radId,
              msg.status,
              msg.validation,
            )
          } else {
            const thumbnailImageFile = await this.imageService.makeThumbnailImage(
              roiImage,
              640
            )
            this.insertPage(
              await this.imageService.blobToDataURL(thumbnailImageFile),
              roiImage,
              msg.isFound,
              msg.extra.radId,
              msg.status,
              msg.validation,
            )
          }
        }
      } else if (event.cmd === 'ready') {
        this.onServiceReadinessUpdate()
      }
    })

    this.onServiceReadinessUpdate()
  }

  private async insertPage(thumbnail: string, roiImage: Blob, isFound: boolean, radId: string, status: string, validation: any): Promise<void> {

    let document = {
      thumbnail: thumbnail,
      roiImage: roiImage,
      isFound: isFound ? 'Auto' : 'Manual',
      radId: radId,
      details: {
        status: status,
        isFound: isFound,
        validation: validation
      }
    }

    if (!this.documentService.insert(
      radId,
      isFound ? 'Auto' : 'Manual',
      thumbnail,
      roiImage,
      {
        status,
        isFound,
        validation,
      }
    )) {
      this.snackBar.open('Document non demandé', 'OK')
    }
    this.documentService.compute()
  }

  private followRoiProgress(roiExtraArg: { cancelled: boolean }): void {
    this.updateProgressMessage('Analyse ...')
    this.progressTimeout = setTimeout(() => {
      this.updateProgressMessage('Lecture ...')
      this.progressTimeout = setTimeout(() => {
        this.updateProgressMessage('Compilation ...')
        this.progressTimeout = setTimeout(() => {
          this.updateProgressMessage('Post-traitement ...')
          this.progressTimeout = setTimeout(() => {
            this.updateProgressMessage('Classement ...')
            this.progressTimeout = setTimeout(() => {
              // searchRoi is stuck, cancel it
              console.log('force searchRoi cancellation')
              roiExtraArg.cancelled = true
              this.onRecognitionEnd('failure')
            }, 50000)
          }, 2000)
        }, 2000)
      }, 2000)
    }, 2000)
  }

  private onRecognitionEnd(status: 'success' | 'failure'): void {
    this.progressMsg = ''
    this.manualRecognition = false
    this.cd.detectChanges()
    if (status === 'failure') {
      this.snackBar.open('Document non reconnu', 'OK', { duration: 5000 })
    }
  }

  private onFailedRecognitionManualFallback(imageFile: File): void {
    this.imageService.makeThumbnailImage(imageFile, 320).then((thumbnailImageFile) => {
      const reader = new FileReader()
      reader.onerror = (err) => {
        throw err
      }
      reader.onloadend = () => {
        const dialogRef = this.dialog.open(ManualTypingDialogComponent, {
          data: {
            image: reader.result,
            possiblePageNames: this.possiblePageNames,
          }
        })
        dialogRef.afterClosed().subscribe((type: string) => {
          if (type) {
            this.addManuallyTypedDocument(imageFile, type)
          }
        })
      }
      reader.readAsDataURL(thumbnailImageFile)
    })
  }

  public get possiblePageNames(): Map<string, string> {
    const names = Array.from(this.documentService.possiblePageNames.entries())
    // The names are already sorted
    return new Map(names)
  }

  public comparePageNames(
    _a: KeyValue<string, string>,
    _b: KeyValue<string, string>
  ): number {
    return 0
  }

  getErrors(page: DisplayablePage, fieldName: string): any[] {
    const result = this.getValidationFieldResult(page, fieldName)
    return result?.errors
  }

  getNbrRows(text: string): number {
    return (text !== undefined && typeof text === 'string') ? Math.max(1, text.split('\n').length) : 2
  }

  private getValidationField(page: DisplayablePage, fieldName: string) {
    const selection = page.fields?.filterByName(fieldName)
    return selection?.length === 1 ? selection[0] : undefined
  }

  private getValidationFieldResult(page: DisplayablePage, fieldName: string) {
    const field = this.getValidationField(page, fieldName)
    return field?.result
  }

  onToggleCode(control: FormControl, page: DisplayablePage, fieldName: string) {
    const field = this.getValidationField(page, fieldName)
    if (field) {
      const show = !page.isFieldInputShown(fieldName)
      page.showFieldInput(fieldName, show)
      control.setValue(show ? field.input : page.getFieldOutput(fieldName))
    }
  }

  getCodeLabel(page: DisplayablePage, fieldName: string) {
    return page.isFieldInputShown(fieldName) ? 'label_off' : 'label'
  }

  hasCorrectedValue(page: DisplayablePage, fieldName: string): boolean {
    return page.hasFieldCorrectedOutput(fieldName)
  }

  onFile() {
    // reset the value property to allow select multiple times the same file
    this.fileInput.nativeElement.value = ''
    // trigger real input file
    this.fileInput.nativeElement.click()
    this.isIntroduction = false
  }

  // Insert files with upload or with drag and drop
  async onSelectedFile(event) {
    const files: FileList = event
    if (files && files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        const file: File = files.item(index)
        // Let a little time to proceed with rad, roi etc. otherwise the roi might get stuck
        await new Promise(resolve => setTimeout(resolve, 200))  
        try {
          this.updateProgressMessage('Reconnaissance ...')
          const radFile = await this.imageService.processImageForRad(file)
          const roiFile = await this.imageService.processImageForRoi(file)
          this.radService.recognizeFromFile(radFile, { hiResSnapshot: roiFile })
          this.progressTimeout = setTimeout(() => {
          }, 3500)
        } catch (error) {
          console.log('recognition error', error)
          this.snackBar.open(`Erreur de reconnaissance ${error}`, 'OK')
          this.updateProgressMessage()
        }
      }
    }
  }

  private addManuallyTypedDocument(imageFile: File, manualType: string): void {
    const radId = manualType
    this.imageService.processImageForRoi(imageFile)
      // this.imageService.makeThumbnailImage(imageFile, 640)
      .then((thumbnailImageFile) => {
        const reader = new FileReader()
        reader.onerror = (err) => {
          throw err
        }
        reader.onloadend = () => {
          const validation: any = {
            name: 'subscriber',
            result: { errors: [] },
            documents: [
              {
                name: '',
                result: { errors: [] },
                pages: [
                  {
                    name: '',
                    result: { errors: [] },
                    fields: [],
                  }
                ]
              }
            ]
          }
          if (!this.documentService.insert(
            radId, 'Manual',
            reader.result as string, thumbnailImageFile, {
            status: 'ok',
            isFound: true,
            validation,
          }
          )) {
            this.snackBar.open('Document non demandé', 'OK')
          }
          this.documentService.compute()
        }
        reader.readAsDataURL(thumbnailImageFile)
      })
  }

  onCamera() {
    this.navigateService.to('/recognition')
  }

  onNext() {
    // Ask confirmation before send
    this.openConfirmationSendDialog()
  }

  public getPageFieldControls(page: DisplayablePage): Map<string, FormControl> {
    const controls = new Map<string, FormControl>()
    page.inputFields?.forEach((inputField) => {
      let control: FormControl
      const field = this.getValidationField(page, inputField.name)
      const output = page.getFieldOutput(inputField.name)
      if (output !== undefined) {
        const result = field.result
        const errors = result.isKo() ? { schema: true } : null

        const validator = (_ctrl: AbstractControl): ValidationErrors | null => {
          return errors
        }

        control = new FormControl(
          page.isFieldInputShown(field.name) ? field.input : output,
          validator
        )
        control.markAsTouched()
      } else {
        control = new FormControl(inputField.input)
      }

      controls.set(inputField.name, control)
    })

    return controls
  }

  openConfirmationSendDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationSendDialogComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        // this.openSendDialog()
        this.navigateService.to('/home')
      }
    });
  }

  openSendDialog(): void {
    const dialogRef = this.dialog.open(SendDialogComponent, {
      panelClass: 'rounded-container'
    });
    // dialogRef.afterClosed().subscribe((result: SendDialogData) => {});
  }

  openGdprDialog() {
    const dialogRef = this.dialog.open(GdprDialogComponent, {
      data: { 
        messages : this.configService.gdprMessages,
        okLabel: this.configService.gdprOkLabel
      },
      disableClose: true
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     console.warn(result)
    //   }
    // });
  }

  openZoomDialog(doc: DisplayableDocument, page: DisplayablePage) {
    console.warn(doc)
    this.dialog.open(ZoomDialogComponent, { data: {
      docPageLabel: this.documentService.getDocumentPageLabel(doc.name, page.name),
      image: page.image
    }});
  }

  // If page was valid or not, or manually added (gree, red, orange)
  getPanelTitleColor(doc: DisplayableDocument, page: DisplayablePage) {
    let result = 'red'
    if ((doc.valid && page.valid)) {
      result = 'green'

      // If manually inserted, add other color
      if (page.typing === 'Manual') {
        result = 'orange'
      }
    }
    return result
  }

  // If page was valid or not, or manually added (cross, interrogation, check)
  getPanelTitleIcon(page: DisplayablePage) {
    // False by default
    let result = 'clear'

    if (page.done) {
      result = 'done'

      // If manually inserted, add another icon
      if (page.typing === 'Manual') {
        result = 'question_mark'
      }
    }
    return result
  }

  onBiometry() {
    this.navigateService.to('/biometry')
  }
}


