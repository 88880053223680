import { Injectable } from '@angular/core'
import { RadialService } from '../radial/radial.service'

const dataURIPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(
    private radialService: RadialService,
  ) { }

  /**
   * Convert a DataURL to a Blob.
   *
   * Heavily based on the version at
   * https://github.com/blueimp/JavaScript-Canvas-to-Blob/blob/master/js/canvas-to-blob.js
   */
  public dataURLtoBlob(datauri: string): Blob {
    // Parse the dataURI components as per RFC 2397
    const matches = datauri.match(dataURIPattern)
    if (!matches) {
      throw new Error('Invalid data URI')
    }
    // Default to text/plain;charset=US-ASCII
    const mediaType = matches[2]
      ? matches[1]
      : 'text/plain' + (matches[3] || ';charset=US-ASCII')
    const isBase64 = !!matches[4]
    const dataString = datauri.slice(matches[0].length)

    // Convert base64/URL-encoded data component to raw binary
    let byteString: string
    if (isBase64) {
      byteString = atob(dataString)
    } else {
      byteString = decodeURIComponent(dataString)
    }

    let n = byteString.length
    const u8arr = new Uint8Array(n)
    for (let i = 0; i < n; ++i) {
      u8arr[i] = byteString.charCodeAt(i)
    }
    return new Blob([u8arr], { type: mediaType })
  }

  public blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = (err) => {
        reject(err)
      }
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(blob)
    })
  }

  /**
   * Replacement for HTMLCanvasElement.toBlob().
   *
   * This is needed because browsers do not all support the quality parameter
   * of HTMLCanvasElement.toBlob().
   */
  public canvasToBlob(
    canvas: HTMLCanvasElement,
    callback: BlobCallback,
    type?: string,
    quality?: any
  ): void {
    const dataUrl = canvas.toDataURL(type, quality)
    const blob = this.dataURLtoBlob(dataUrl)
    callback(blob)
  }

  public async processImageForRad(inputFile: File): Promise<File> {
    let inputImageFile = inputFile
    if (inputFile.type === 'application/pdf') {
      const blob = await this.radialService.pdf2png(inputFile).toPromise()
      inputImageFile = new File([blob], 'tmp.jpg')
    }

    return await new Promise((resolve, reject) => {
      const url = URL.createObjectURL(inputImageFile)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)

        // If the source image is larger than the given max size then
        // scale it down and keep its original ratio
        const maxSize = 6209280 // 6 MPix: 2880 x 2156 at 4:3 ratio
        const size = image.width * image.height
        if (size > maxSize) {
          const ratio = Math.sqrt(maxSize / size)
          const outHeight = Math.floor(image.height * ratio) || 1
          const outWidth = Math.floor(image.width * ratio) || 1
          console.log(`       imageServ.ts processImageForRad Scale down image ` +
            `from ${image.width} x ${image.height} to ${outWidth} x ${outHeight}`
          )

          // Resize the image
          const canvas = document.createElement('canvas')
          canvas.width = outWidth
          canvas.height = outHeight
          const canvasCtx = canvas.getContext('2d')
          canvasCtx.drawImage(image, 0, 0, outWidth, outHeight)
          this.canvasToBlob(canvas, (blob) => {
            resolve(new File([blob], 'tmp.jpg'))
          }, 'image/jpeg', 0.85)
        } else {
          resolve(inputImageFile)
        }
      }
      image.onerror = () => {
        console.warn('Unreadable image file')
        URL.revokeObjectURL(url)
        reject()
      }
      image.src = url
    })
  }

  public async processImageBlobForRoi(input: Blob): Promise<File> {
    return await new Promise((resolve, reject) => {
      const url = URL.createObjectURL(input)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)

        // If the source image is larger than the given max size then
        // scale it down and keep its original ratio
        const maxSize = 6209280 // 6 MPix: 2880 x 2156 at 4:3 ratio
        const size = image.width * image.height
        if (size > maxSize) {
          const ratio = Math.sqrt(maxSize / size)
          const outHeight = Math.floor(image.height * ratio) || 1
          const outWidth = Math.floor(image.width * ratio) || 1
          console.log(`       imageServ.ts processImageForRoi Scale down image ` +
            `from ${image.width} x ${image.height} to ${outWidth} x ${outHeight}`
          )

          // Resize the image
          const canvas = document.createElement('canvas')
          canvas.width = outWidth
          canvas.height = outHeight
          const canvasCtx = canvas.getContext('2d')
          canvasCtx.drawImage(image, 0, 0, outWidth, outHeight)
          this.canvasToBlob(canvas, (blob) => {
            resolve(new File([blob], 'tmp.jpg'))
          }, 'image/jpeg', 0.85)
        } else {
          resolve(new File([input], 'tmp.jpg'))
        }
      }
      image.onerror = () => {
        console.warn('Unreadable image file')
        URL.revokeObjectURL(url)
        reject()
      }
      image.src = url
    })
  }

  public async processImageForRoi(inputFile: File): Promise<File> {
    let inputImageFile = inputFile
    if (inputFile.type === 'application/pdf') {
      const blob = await this.radialService.pdf2png(inputFile).toPromise()
      inputImageFile = new File([blob], 'tmp.jpg')
    }

    return await this.processImageBlobForRoi(inputImageFile);
  }


  public async processUploadedFile(inputFile: File): Promise<File> {
    let inputImageFile = inputFile
    if (inputFile.type === 'application/pdf') {
      const blob = await this.radialService.pdf2png(inputFile).toPromise()
      inputImageFile = new File([blob], 'tmp.jpg')
    }

    return await new Promise((resolve, reject) => {
      const url = URL.createObjectURL(inputImageFile)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)

        // If the source image is larger than the given max size then
        // scale it down and keep its original ratio
        const maxSize = 6209280 // 6 MPix: 2880 x 2156 at 4:3 ratio
        const size = image.width * image.height
        if (size > maxSize) {
          const ratio = Math.sqrt(maxSize / size)
          const outHeight = Math.floor(image.height * ratio) || 1
          const outWidth = Math.floor(image.width * ratio) || 1
          console.log(`       imageServ.ts processUploadFile Scale down image ` +
            `from ${image.width} x ${image.height} to ${outWidth} x ${outHeight}`
          )

          // Resize the image
          const canvas = document.createElement('canvas')
          canvas.width = outWidth
          canvas.height = outHeight
          const canvasCtx = canvas.getContext('2d')
          canvasCtx.drawImage(image, 0, 0, outWidth, outHeight)
          this.canvasToBlob(canvas, (blob) => {
            resolve(new File([blob], 'tmp.jpg'))
          }, 'image/jpeg', 0.85)
        } else {
          resolve(inputImageFile)
        }
      }
      image.onerror = () => {
        console.warn('Unreadable image file')
        URL.revokeObjectURL(url)
        reject()
      }
      image.src = url
    })
  }

  public convertRawToImageFile(imageData: ImageData): Promise<File> {
    const canvas = document.createElement('canvas')
    canvas.width = imageData.width
    canvas.height = imageData.height
    const ctx = canvas.getContext('2d')
    ctx.putImageData(imageData, 0, 0)

    return new Promise((resolve) => {
      this.canvasToBlob(canvas, (blob) => {
        resolve(new File([blob], 'tmp.jpg'))
      }, 'image/jpeg', 1)
    })
  }

  public makeThumbnailImage(inputImage: Blob, maxSide: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(inputImage)
      const image = new Image()
      image.onload = () => {
        URL.revokeObjectURL(url)

        // If a side of the image is too large then scale it down
        if (image.width > maxSide || image.height > maxSide) {
          const ratio =
            maxSide / (image.width > image.height ? image.width : image.height)
          const outHeight = Math.floor(image.height * ratio) || 1
          const outWidth = Math.floor(image.width * ratio) || 1
          console.log(`       imageServ.ts makeThumbnailImage Scale down image ` +
            `from ${image.width} x ${image.height} to ${outWidth} x ${outHeight}`
          )

          // Resize the image
          const canvas = document.createElement('canvas')
          canvas.width = outWidth
          canvas.height = outHeight
          const canvasCtx = canvas.getContext('2d')
          canvasCtx.drawImage(image, 0, 0, outWidth, outHeight)
          this.canvasToBlob(canvas, (blob) => {
            resolve(blob)
          }, 'image/jpeg', 1)
        } else {
          resolve(inputImage)
        }
      }
      image.onerror = () => {
        console.warn('Unreadable image file')
        URL.revokeObjectURL(url)
        reject()
      }
      image.src = url
    })
  }
}
