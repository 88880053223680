import { Component, Input, OnInit } from '@angular/core';
import { DisplayableDocument } from 'src/app/models/document.model';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss']
})

// For a next version of linker ? Keep it.
export class DocumentCardComponent implements OnInit {

  @Input() document: DisplayableDocument

  constructor() { }

  ngOnInit(): void {}

}
