<mat-nav-list>
  <!-- Configurations -->
  <a mat-list-item>
    <span mat-line>Configurations - {{ config?.customer }}</span>
    <span class="description" mat-line>Version d'application : {{ version }}</span>
  </a>

  <a *ngIf="!config.requireConsolidate" mat-list-item>
    <mat-icon mat-list-icon>send_time_extension</mat-icon>
		<span mat-line>Envoi partiel</span>
    <span mat-line class="description">Vous permet de finaliser l'envoi sans avoir fourni tous les documents demandés.</span>
  </a>

  <a *ngIf="config.allowManualTyping" mat-list-item>
    <mat-icon mat-list-icon>edit_document</mat-icon>
		<span mat-line>Typage manuel</span>
    <span mat-line class="description">Lorsqu'un document n'est pas reconnu, vous permet de lui définir un type en lien avec la demande.</span>
  </a>

  <a *ngIf="config.stepByStep" mat-list-item>
    <mat-icon mat-list-icon>edit_document</mat-icon>
		<span mat-line>Etape par étape</span>
    <span mat-line class="description">Les documents sont demandés étape par étape.</span>
  </a>
</mat-nav-list>