import { Injectable } from '@angular/core'
import { Observable, of, BehaviorSubject } from 'rxjs'
import * as KJUR from 'jsrsasign'
import { RadialService } from '../services/radial/radial.service'

export class User {
  // Very simple User Object, just for testing purpose
  constructor(public id: string, public jwt?: string, public expiresIn?: number) { }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  anonymousName = 'anon'
  user: User
  isLoggedIn = false
  loggedSubject = new BehaviorSubject<boolean>(false)
  onLogged$: Observable<boolean> = this.loggedSubject.asObservable()
  redirectUrl: string // store the URL so we can redirect after logging in
  timerHandle: any

  constructor(
    private radialService: RadialService,
  ) {
    this.user = { id: this.anonymousName }
  }

  public async login(realm: string, clientId: string, login: string, password: string): Promise<void> {
    console.log(`        authServ.ts login challenge log(${login})`)

    this.loggedSubject.next(true)
    this.isLoggedIn = true
  }
}
