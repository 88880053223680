<div class="circle-container">
    <!-- For a next version ? Dynamic loader here -->
    <div #circle [ngClass]="isError ? 'error' : 'normal'" class="circle">
        <mat-progress-spinner *ngIf="progressValue > 0"
          [color]="'accent'"
          [mode]="'determinate'"
          [value]="progressValue / 2"
          [strokeWidth]="10"
          [diameter]="spinnerDiameter">
        </mat-progress-spinner>
    </div>
    <!-- <p *ngIf="version" class="version">v{{version}}</p>  -->
  </div>
  

