import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { environment } from '../environments/environment'
import { Subscription } from 'rxjs'
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from './auth/auth.service'
import { VideoDevicesService } from './services/video-devices/video-devices.service'
import { RadService } from './services/rad/rad.service'
import { ConfigService } from './services/config/config.service'
import { StabilityService } from './services/stability/stability.service';
import { RoiService } from './services/roi/roi.service';
import { ConfigDetailsDialogComponent } from './config-details-bottom-sheet/config-details-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  onVideoReady: Subscription
  onRadEvent: Subscription
  onRoiEvent: Subscription
  onStabilityEvent: Subscription
  onSwUpdate: Subscription

  showTooltip: boolean = true
  configList: string = 'oui <br> non <br> yes';
    
  constructor(
    private videoDevicesService: VideoDevicesService,
    public configService: ConfigService,
    private radService: RadService,
    private roiService: RoiService,
    private storageMap: StorageMap,
    private authService: AuthService,
    private stabilityService: StabilityService,
    private bottomSheet: MatBottomSheet
  ) {}

  async ngOnInit() {    
    if (navigator.serviceWorker) {
      // remove any previous service worker
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister()
        }
      }).catch((err) => {
        console.log('         appComp.ts Service Worker registration failed: ', err)
      })
    }

    // standard mode, use version number of this app
    this.storageMap.get('version').subscribe(async (version: string) => {
      if (version === undefined || version !== environment.version) {
        // brand new version or modified version : force reload
        await this.storageMap.delete('modelsrad').toPromise()
        await this.storageMap.delete('modelsroi').toPromise()
        await this.storageMap.delete('validationSchema').toPromise()

        await this.storageMap.set('version', environment.version).toPromise()
      }
    })

    this.onVideoReady = this.videoDevicesService.onReady().subscribe(null, null, () => {
      console.log(`         appComp.ts videoDevicesService onReady`)
    })

    this.onStabilityEvent = this.stabilityService.onEvent$.subscribe((msg) => {
      if (msg.cmd === 'ready') {
        console.log(`         appComp.ts subscribe stability ready received`)
      }
    })

    this.onRadEvent = this.radService.onEvent$.subscribe((msg) => {
      if (msg.cmd === 'ready') {
        console.log(`         appComp.ts subscribe onRad ready received`)
        this.roiService.initModels()
      }
    })

    this.onRoiEvent = this.roiService.onEvent$.subscribe((msg) => {
      if (msg.cmd === 'ready') {
        console.log(`         appComp.ts subscribe onRoi ready received`)
      }
    })

    await this.authService.login(
      environment.auth.crucial.realm,
      environment.auth.crucial.clientId,
      environment.auth.crucial.log,
      environment.auth.crucial.pwd,
    )
    console.log(`         appComp.ts auth login user done`)
    // start radService as soon as possible to load model
    this.radService.initModels()
  }

  ngOnDestroy() {
    if (this.onVideoReady) { this.onVideoReady.unsubscribe() }
    if (this.onStabilityEvent) { this.onStabilityEvent.unsubscribe() }
    if (this.onRadEvent) { this.onRadEvent.unsubscribe() }
    if (this.onSwUpdate) { this.onSwUpdate.unsubscribe() }
  }

  getState(outlet: RouterOutlet) {
    return outlet.activatedRouteData.state
  }

  openConfigDetailsBottomSheet() {
    this.bottomSheet.open(ConfigDetailsDialogComponent, { data: this.configService });
  }
}
