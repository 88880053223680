import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { DetectService } from '../detect/detect.service'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class VideoDevicesService {
  haveDevice = false
  videoDevices: MediaDeviceInfo[] = []
  videoDeviceIdx = 1 // most often it's environment
  ready = new Subject<void>()

  constructor(private detectService: DetectService) {
    this.init()
  }

  private init() {
    navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
      this.videoDevices = deviceInfos.filter(device => device.kind === 'videoinput')
      this.haveDevice = environment.with.feature.camera === true && this.videoDevices.length > 0
      console.log(`videoDevicesServ.ts haveDevice(${this.haveDevice}):`, this.videoDevices)
      console.log(`videoDevicesServ.ts getSupportedConstraints:`, navigator.mediaDevices.getSupportedConstraints())

      // reset the index based on environment camera, but there is no way to know which camera is.
      // device label is localized, i.e: 'Back Camera' or 'Caméra arrière'
      // force idx to the last one
      this.videoDeviceIdx = Math.min(1, this.videoDevices.length - 1)

      if (!this.ready.isStopped) {
        this.ready.complete()
      }
    })
  }

  public onReady(): Observable<void> {
    return this.ready.asObservable()
  }

  public get(delta: number): Observable<MediaStream> {
    const subject = new Subject<MediaStream>()
    console.log(`videoDevicesServ.ts request camera idx(${this.videoDeviceIdx}) delta(${delta}), ` +
      `devices(${this.videoDevices.length})`, this.videoDevices)

    this.videoDeviceIdx = (this.videoDeviceIdx + delta) % this.videoDevices.length
    const videoDevice = this.videoDevices[this.videoDeviceIdx]
    console.log(`videoDevicesServ.ts request camera #${this.videoDeviceIdx}`)

    const onStream = (mediaStream: MediaStream) => {
      // update the devices list in case where we are in iOS for the first stream
      if (videoDevice.deviceId === '') {
        this.init()
      }
      subject.next(mediaStream)
    }

    console.log(`videoDevicesServ.ts get camera #${this.videoDeviceIdx}`)
    navigator.mediaDevices.getUserMedia(this.makeConstraints(videoDevice, false))
    .then(onStream)
    .catch(err => {
      console.log('videoDevicesServ.ts getUserMedia error', err)
      console.log('videoDevicesServ.ts retry getUserMedia with additional height constraint')
      navigator.mediaDevices.getUserMedia(this.makeConstraints(videoDevice, true))
      .then(onStream)
      .catch(secondErr => {
        console.log('videoDevicesServ.ts getUserMedia error', secondErr)
      })
    })

    return subject.asObservable()
  }

  private makeConstraints(videoDevice: MediaDeviceInfo, specifyHeight: boolean) {
    const widthConstraint = { ideal: 1920 }
    const heightConstraint = specifyHeight ? { ideal: 1080 } : undefined
    const deviceId = videoDevice.deviceId
    return deviceId === '' ?
      // iOS hasn't yet populated the devices, use facing mode instead
      {
        video: {
          facingMode: 'environment',
          width: widthConstraint,
          height: heightConstraint,
        },
        audio: false
      } :
      // everything has been populated
      {
        video: {
          deviceId: { exact: deviceId },
          width: widthConstraint,
          height: heightConstraint,
        },
        audio: false
      }
  }
}
