import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { DocumentComponent } from './documents/documents.component';
import { ConfigService } from './services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class DocGuard implements CanDeactivate<DocumentComponent> {

  constructor(private configService: ConfigService) {}

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      const id = this.configService.requestInfo.requestId
      return (id !== '' && id !== undefined) ? true : false
  } 
}
