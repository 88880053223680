
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core'

import * as tf from '@tensorflow/tfjs'
import '@tensorflow/tfjs-backend-cpu'
import '@tensorflow/tfjs-backend-webgl'

import * as faceDetection from '@tensorflow-models/face-detection';

declare var ImageCapture: any

export interface BiometryDetectionResult {
    imageData: Blob[]
    videoData: Blob[]
}

export class BiometryBox {
    public centerX: number;
    public centerY: number;
    public radiusX: number;
    public radiusY: number;

    constructor(centerX: number, centerY: number, radiusX: number, radiusY: number) {
        this.centerX = centerX;
        this.centerY = centerY;
        this.radiusX = radiusX;
        this.radiusY = radiusY;
    }
    
    public get left(): number {
        return this.centerX - this.radiusX;
    }

    public get right(): number {
        return this.centerX + this.radiusX;
    }

    public get top(): number {
        return this.centerY - this.radiusY;
    }

    public get bottom(): number {
        return this.centerY + this.radiusY;
    }

    public get width(): number {
        return this.right - this.left;
    }

    public get height(): number {
        return this.bottom - this.top;
    }

    public get area(): number {
        return this.width * this.height;
    }
}

@Component({
    selector: 'app-biometry-detection',
    templateUrl: './biometry-detection.component.html',
    styleUrls: ['./biometry-detection.component.scss']
})
export class BiometryDetectionComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() imageRef: Blob = null;

    @Output() submitted = new EventEmitter<BiometryDetectionResult>();
    
    @ViewChild('container') container;
    @ViewChild('output') output;
    @ViewChild('camera') camera;

    private state: string = 'initializing';

    private ctx: any = null;
    private tfBackend: string = '';
    private imageCapture: any = null;
    private recorder: any = null;
    private faceDetector: any = null;
    private bitmapRef: ImageBitmap = null;
    private handleAnimationFrame: number = -1;

    private zeBox: BiometryBox = null;
    private inZeBox: boolean = false;
    private decountBeginDt: number = -1;
    private decount: number = -1;
    
    private imageData: Blob[] = [];
    private videoData: Blob[] = [];
    private bitmapCrop: ImageBitmap = null;
    private recordingBeginDt: number = -1;
    
    error: string = '';
    debug: boolean = false;

    constructor() {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.setupTfBackend();
        this.setupCamera();
        this.setupDrawContext();

        this.render();
    }

    ngOnDestroy() {
        window.cancelAnimationFrame(this.handleAnimationFrame);

        if (this.bitmapRef) {
            this.bitmapRef.close();
            this.bitmapRef = null;
        }

        if (this.bitmapCrop) {
            this.bitmapCrop.close();
            this.bitmapCrop = null;
        }

        if (this.faceDetector != null) {
            this.faceDetector.dispose();
            this.faceDetector = null;
        }
    }

    public setImageRef(imageRef: Blob) {
        this.imageRef = imageRef;
        createImageBitmap(this.imageRef)
            .then((bitmap) => {
                this.bitmapRef = bitmap;
            });
    }

    public pending() {
        if (this.state !== 'intializing' &&
            this.state !== 'error') {

            this.setStatePending();
        }
    }

    private isiOS(): boolean {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }
      
    private isAndroid(): boolean {
        return /Android/i.test(navigator.userAgent);
    }
      
    private isMobile(): boolean {
        return this.isAndroid() || this.isiOS();
    }

    private async setupTfBackend() {
        const backendAvailable = {
            webgl: {
                //WEBGL_VERSION: [1, 2],
                //WEBGL_CPU_FORWARD: [true, false],
                //WEBGL_PACK: [true, false],
                //WEBGL_FORCE_F16_TEXTURES: [true, false],
                //WEBGL_RENDER_FLOAT32_CAPABLE: [true, false],
                //WEBGL_FLUSH_THRESHOLD: [-1, 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            },
            cpu: {}
        };

        let isBackendSet = false;
        for (const backendName of Object.keys(backendAvailable)) {
            //const backendFlags = backendAvailable[backendName];

            isBackendSet = await tf.setBackend(backendName);
            if (isBackendSet) {
                break;
            }
        }

        if (isBackendSet) {
            this.tfBackend = tf.getBackend();
        }
    }

    private setupCamera() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            throw new Error(
                'Browser API navigator.mediaDevices.getUserMedia not available');
        }

        const targetFPS = 60;
        const sizeOption = {
            width: this.isMobile() ? 360 : 640,
            height: this.isMobile() ? 270 : 480
        };

        const videoConfig = {
            'audio': false,
            'video': {
                facingMode: 'user',
                width: sizeOption.width,
                height: sizeOption.height,
                frameRate: {
                    ideal: targetFPS,
                },
            },
        };

        const stream = navigator.mediaDevices.getUserMedia(videoConfig)
            .then((stream) => {
                this.camera.nativeElement.addEventListener("loadeddata", () => {
                    if (this.camera.nativeElement.readyState >= 2) {
                        this.camera.nativeElement.play();
            
                        this.setupOutput();
                        this.setupFaceDetector();
                    }
                });

                this.camera.nativeElement.srcObject = stream;
            });
    }

    private setupDrawContext() {
        this.ctx = this.output.nativeElement.getContext('2d');
    }

    private setupOutput() {
        const w = this.camera.nativeElement.videoWidth;
        const h = this.camera.nativeElement.videoHeight;

        this.camera.nativeElement.width = w;
        this.camera.nativeElement.height = h;
        this.output.nativeElement.width = w;
        this.output.nativeElement.height = h;

        const zeBoxSize = {
            width: this.isMobile() ? 250 : 300,
            height: this.isMobile() ? 300 : 400
        }
        this.zeBox = new BiometryBox(
            w * 0.5,
            h * 0.5,
            zeBoxSize.width * 0.5,
            zeBoxSize.height * 0.5
        );

        const stream = this.camera.nativeElement.srcObject;

        const track = stream.getVideoTracks()[0];
        this.imageCapture = new ImageCapture(track);

        //this.recorder = new MediaRecorder(stream);
        //this.recorder.ondataavailable = (event) => this.videoData.push(event.data);
    }

    private setupFaceDetector() {
        if (this.faceDetector != null) {
            this.faceDetector.dispose();
            this.faceDetector = null;
        } 

        faceDetection.createDetector(
            faceDetection.SupportedModels.MediaPipeFaceDetector, 
            {
                runtime: 'mediapipe',
                solutionPath: 'assets/face_detection/'
            })
            .then((faceDetector) => {
                this.faceDetector = faceDetector;
                this.setStateInitialized();
            });
    }

    private async render(dt = 0) {
        // estimate faces
        let faces = null;

        if (this.state === 'pending' || this.state === 'decounting' || this.state === 'recording') {
            try {
                faces = await this.faceDetector.estimateFaces(this.camera.nativeElement, { flipHorizontal: false });
            } catch (error) {
                this.faceDetector.dispose();
                this.faceDetector = null;
            }

            this.inZeBox = this.checkIfInZeBox(faces);
            if (this.inZeBox) {
                if (this.state === 'pending') {
                    this.setStateDecounting(dt);
                }
            } else {
                this.setStatePending();
            }
        }
        
        if (this.state === 'decounting') {
            this.decount = 4 - Math.ceil((dt - this.decountBeginDt) / 1000);
            if (this.decount <= 0) {
                this.setStateRecording(dt);
            }
        } /*else if (this.state === 'recording') {
            const recordingDt = dt - this.recordingBeginDt;
            if (recordingDt > 500) {
                this.setStateValidating();
            }
        }*/

        // render
        if (this.ctx != null) {
            const w = this.output.nativeElement.width;
            const h = this.output.nativeElement.height;

            //this.drawMask();
            //this.ctx.globalCompositeOperation = 'multiply';
            this.ctx.drawImage(this.camera.nativeElement, 0, 0, w, h);
            //this.ctx.globalCompositeOperation = 'source-over';
            this.drawBitmapRefAndCrop();
            this.drawProgress(dt);
            this.drawDecount();
            this.drawRecording();

            if (this.debug) {
                this.drawFaces(faces, true, true);
                
                this.ctx.font = '12pt sans-serif';
                this.ctx.fillStyle = '#16ed7d';
                this.ctx.fillText("tfBackend: " + this.tfBackend, 10, h - 75);
                this.ctx.fillText("state: " + this.state, 10, h - 60);
                this.ctx.fillText("inZeBox: " + this.inZeBox, 10, h - 45);
                this.ctx.fillText("imageData: " + this.imageData.length, 10, h - 30);
                this.ctx.fillText("videoData: " + this.videoData.length, 10, h - 15);
            }
        }

        this.handleAnimationFrame = window.requestAnimationFrame(this.render.bind(this));
    }

    private setStateInitialized() {
        this.camera.nativeElement.play();

        this.state = 'initialized';
    }

    private setStatePending() {
        //this.recorder.stop();
        this.camera.nativeElement.play();

        this.imageData = [];
        this.videoData = [];
        //this.recorder.ondataavailable = (event) => this.videoData.push(event.data);

        this.state = 'pending';
    }

    private setStateDecounting(dt) {
        this.decountBeginDt = dt;
        this.decount = 4;

        this.state = 'decounting';
    }

    private setStateRecording(dt) {
        this.recordingBeginDt = dt;

        this.takePhoto();
        //this.recorder.start();

        this.state = 'recording';
    }

    private setStateValidating() {
        //this.recorder.stop();
        this.camera.nativeElement.pause();

        this.state = 'validating';

        this.submitted.emit({
            imageData: this.imageData,
            videoData: this.videoData
        });
    }

    private setStateError(error) {
        console.error(error);
        this.camera.nativeElement.pause();
        this.error = error;
        this.state = 'error';
    }

    private checkIfInZeBox(faces): boolean {
        if (!faces) {
            return false;
        }

        let result = false;
        faces.forEach((face) => {
            const faceBox = face.box;
            const faceBoxLeft = faceBox.xMin;
            const faceBoxRight = faceBox.xMax;
            const faceBoxTop = faceBox.yMin;
            const faceBoxBottom = faceBox.yMax;
            const faceBoxWidth = faceBoxRight - faceBoxLeft;
            const faceBoxHeight = faceBoxBottom - faceBoxTop;
            const faceBoxArea = faceBoxWidth * faceBoxHeight;

            result =
                (faceBoxLeft > this.zeBox.left ) &&
                (faceBoxRight < this.zeBox.right) &&
                (faceBoxTop > this.zeBox.top) &&
                (faceBoxBottom < this.zeBox.bottom) &&
                (faceBoxArea > this.zeBox.area / 4);
        });

        return result;
    }

    private takePhoto() {
        this.state = 'capturing';
        this.camera.nativeElement.pause();
        this.render(0);
        
        this.imageCapture
            .takePhoto()
            .then((image) => {
                this.cropImage(image)
                    .then((cropImage) => {
                        this.imageData.push(image);
                        this.setStateValidating();
                    })
                    .catch((error) => this.setStateError(error));
            })
            .catch((error) => this.setStateError(error));
    }

    private cropImage(image: Blob): Promise<Blob | undefined> {
        return new Promise<Blob | undefined>(async (resolve, reject) => {
            if (!this.ctx) {
                reject(undefined);
            }

            const bitmap = await createImageBitmap(
                image,
                this.zeBox.left,
                this.zeBox.right,
                this.zeBox.width,
                this.zeBox.height
            );
            if (!bitmap) {
                reject(undefined);
            }

            const w = this.output.nativeElement.width;
            const h = this.output.nativeElement.height;

            const canvas = document.createElement('canvas');
            canvas.width = this.zeBox.width;
            canvas.height = this.zeBox.height;

            const drawer = canvas.getContext('2d');
            drawer.drawImage(
                bitmap,
                0,
                0,
                this.zeBox.width,
                this.zeBox.height
            );

            if (this.debug) {
                this.bitmapCrop = bitmap;
            } else {
                bitmap.close();
            }

            canvas.toBlob(
                (result) => {
                    resolve(result);
                },
                'image/jpeg',
                1
            );
        });
    }

    private linearInterpolation(x, y, t) {
        return x * (1 - t) + y * t;
    }

    private rgba(r, g, b, a) {
        return "rgba(" + r + "," + g + "," + b + "," + a + ")";
    }

    private drawMask() {
        if (!this.ctx) {
            return;
        }

        if (!this.zeBox) {
            return;
        }

        const w = this.output.nativeElement.width;
        const h = this.output.nativeElement.height;

        this.ctx.fillStyle = '#ffffff40';
        this.ctx.fillRect(0, 0, w, h);

        this.ctx.fillStyle = '#fff';
        this.ctx.beginPath();
        this.ctx.ellipse(
            this.zeBox.centerX,
            this.zeBox.centerY,
            this.zeBox.radiusX,
            this.zeBox.radiusY,
            0.0,
            0.0,
            2 * Math.PI,
            true
        );
        this.ctx.fill();
    }

    private drawBitmapRefAndCrop() {
        if (!this.ctx) {
            return;
        }

        if (this.imageRef && this.bitmapRef) {
            this.ctx.drawImage(this.bitmapRef, 10, 10, 50, 70);
        }

        if (this.bitmapCrop && this.debug) {
            this.ctx.drawImage(this.bitmapCrop, 60, 10, 50, 70);
        }
    }

    private drawProgress(dt) {
        if (!this.ctx) {
            return;
        }

        if (!this.zeBox) {
            return;
        }

        this.ctx.strokeStyle = '#f0f0f0';
        this.ctx.lineWidth = 3;
        this.ctx.beginPath();
        this.ctx.ellipse(
            this.zeBox.centerX,
            this.zeBox.centerY,
            this.zeBox.radiusX,
            this.zeBox.radiusY,
            0.0,
            0.0,
            2 * Math.PI
        );
        this.ctx.stroke();
        
        if (this.state === 'decounting' || this.state === 'recording') {
            const t = dt / 2000;
            const step = this.linearInterpolation(0.0, 2 * Math.PI, t);

            let angleBegin = 0;
            let angleEnd = 2 * Math.PI;
            if (this.state === 'recording') {
                angleBegin = step;
                angleEnd = (Math.PI / 3) + step;
            }

            this.ctx.strokeStyle = '#16ed7d';
            this.ctx.lineWidth = 3;
            this.ctx.beginPath();
            this.ctx.ellipse(
                this.zeBox.centerX,
                this.zeBox.centerY,
                this.zeBox.radiusX,
                this.zeBox.radiusY,
                0.0,
                angleBegin,
                angleEnd
            );
            this.ctx.stroke();
        }
    }

    private drawDecount() {
        if (!this.ctx) {
            return;
        }

        if (this.state !== 'decounting') {
            return;
        }

        const w = this.output.nativeElement.width;
        const h = this.output.nativeElement.height;

        this.ctx.font = '92pt sans-serif';
        this.ctx.fillStyle = '#fff';
        const metrics = this.ctx.measureText(this.decount);
        this.ctx.fillText(this.decount, w * 0.5 - metrics.width * 0.5, h * 0.5 + metrics.width * 0.5);
    }

    private drawRecording() {
        if (!this.ctx) {
            return;
        }

        if (this.state !== 'recording') {
            return;
        }

        const w = this.output.nativeElement.width;
        const h = this.output.nativeElement.height;

        this.ctx.fillStyle = 'red';
        this.ctx.beginPath();
        this.ctx.arc(w - 50, 50, 10, 0, 2 * Math.PI);
        this.ctx.fill();
    }

    private drawPath(points, closePath) {
        if (!this.ctx) {
            return;
        }

        const region = new Path2D();
        region.moveTo(points[0][0], points[0][1]);
        for (let i = 1; i < points.length; i++) {
            const point = points[i];
            region.lineTo(point[0], point[1]);
        }
      
        if (closePath) {
            region.closePath();
        }

        this.ctx.stroke(region);
    }

    private drawFaces(faces, boundingBox, showKeypoints) {
        if (!this.ctx) {
            return;
        }

        if (!faces) {
            return;
        }

        faces.forEach((face) => {
            const keypoints = face.keypoints.map((keypoint) => [keypoint.x, keypoint.y]);
      
            if (boundingBox) {
                this.ctx.strokeStyle = 'red';
                this.ctx.lineWidth = 1;
        
                const box = face.box;
                this.drawPath(
                    [
                        [box.xMin, box.yMin],
                        [box.xMax, box.yMin],
                        [box.xMax, box.yMax],
                        [box.xMin, box.yMax]
                    ],
                    true
                );
            }
      
            if (showKeypoints) {
                this.ctx.fillStyle = '#16ed7d';
      
                for (let i = 0; i < 6; i++) {
                    const x = keypoints[i][0];
                    const y = keypoints[i][1];
            
                    this.ctx.beginPath();
                    this.ctx.arc(x, y, 3, 0, 2 * Math.PI);
                    this.ctx.fill();
                }
            }
        });
    }
}
