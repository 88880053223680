<div class="content">
  
  <div class="text">
    <!-- Progress -->
    <div *ngIf="sending && !sent" class="progress">
      <mat-spinner color="primary"></mat-spinner>
      <h2>Envoi en cours...</h2>
    </div>

    <div *ngIf="sent && !sending">
      <ng-container *ngIf="configService?.endMessages?.length > 0">
        <p *ngFor="let message of configService.endMessages">
          {{ message }}
        </p>
      </ng-container>
      
      <ng-container *ngIf="configService?.endMessages?.length === 0">
        <p>Un conseiller vous recontactera sous 48h.</p>
      </ng-container>
    </div>
    
    <span [innerHTML]="htmlText"></span>
  </div>

  <button *ngIf="this.sent"
    mat-fab (click)="onExit()" #defaultButton color="primary">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ configService.endLinkLabel ? configService.endLinkLabel : 'Sortir' }}</span>
  </button>
</div>