import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { IMug } from '@doclab/coaxial-core'

import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { Config, RadialConfig, RequestInfo } from 'src/app/models/config.model'
import *  as CryptoJS from 'crypto-js'
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public customer: string
  public mug: IMug
  public quotaUser: string
  public userCallbackUrl: string
  // Send to convivial
  public enforcedControls: boolean = false
  // Manual typing
  public allowManualTyping: boolean = true
  public gdprMessages: string[] = []
  public gdprOkLabel = 'OK'
  public messages: string[] = []
  public endLink?: string
  public endLinkLabel = 'OK'
  public endMessages: string[]
  public schema: string
  public requestInfo: RequestInfo
  public logo: SafeUrl
  public radial: RadialConfig
  private onReady = new Subject<void>()
  public onReady$ = this.onReady.asObservable()

  // Partial send
  public requireConsolidate: boolean = true;

  // step by step
  public stepByStep: boolean = true;

  private key: string = ''

  constructor(
    private domSanitizer: DomSanitizer,
  ) {
  }

  public load(requestId: string, config: Config): void {
    console.log(`>>> raw config`, config)
    this.quotaUser = config.userInfo?.quotaUser || 'mgen'
    this.mug = config.mug
    this.customer = config.customer
    this.userCallbackUrl = config.userInfo?.userCallbackURL || ''
    
    // Send to convivial
    if (config.userInfo?.enforcedControls) {
      this.enforcedControls = config.userInfo?.enforcedControls
    }

    // Manual typing
    if (config.userInfo?.allowManualTyping) {
      this.allowManualTyping = config.userInfo?.allowManualTyping
    }

    this.gdprMessages = config.userInfo?.gdprMessages &&
      config.userInfo.gdprMessages.map(
        (msg) => msg.message
      )
    if (config.userInfo?.gdprOkLabel) {
      this.gdprOkLabel = config.userInfo.gdprOkLabel
    }
    this.endLink = config.userInfo?.endLink || 'https://www.wizidee.com'
    if (config.userInfo?.endLinkLabel) {
      this.endLinkLabel = config.userInfo.endLinkLabel
    }
    config.requestInfo.requestId = requestId

    localStorage.setItem('user', this.encrypt(config.customer))
    localStorage.setItem('connected', 'yes')
    localStorage.setItem('logo', config.userInfo.logo)
    this.endMessages = config.userInfo?.endMessages &&
      config.userInfo.endMessages.map(
        (msg) => msg.message
      )
    this.messages = config.customerInfo.customerMessage.map(
      (msg) => msg.message
    )
    this.schema = config.requestInfo.schema
    this.requestInfo = config.requestInfo
    if (config.userInfo?.logo) {
      this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + config.userInfo.logo)
    } else {
      this.logo = this.domSanitizer.bypassSecurityTrustUrl('/assets/wzd-logo.png')
    }
    this.radial = config.radial

    // Partial send
    if (config.userInfo?.requireConsolidate !== undefined) {
      this.requireConsolidate = config.userInfo?.requireConsolidate
    }

    // step by step
    if (config.userInfo?.stepByStep !== undefined) {
      this.stepByStep = config.userInfo?.stepByStep;
    }

    this.onReady.next()
  }
  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString()
  }
}
