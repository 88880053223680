import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, Subject, throwError, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Location } from '@angular/common'

import { environment } from 'src/environments/environment'

export class SentinelResult {
    status: string;
    isFound: boolean;
    fields?: number;
}

export class SelfieResult {
    match: boolean;
}

export class SpoofResult {
    p1: number;
    p2: number;
    valid: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BiometryService {
    private partialHost: string = '';
    private sentinelHost: string = '';
    //private selfieHost: string = '';
    //private spoofHost: string = '';

    constructor(
        private httpClient: HttpClient
    ) {
        const parts = location.origin.split('.');
        const subDomain = parts.length == 4 ? '' : '.prod';

        this.partialHost = environment.partialHost;
        if (this.partialHost === '') {
            this.partialHost = location.origin.replace('linker', `bff-linker`);
        }

        this.sentinelHost = environment.sentinelHost;
        if (this.sentinelHost === '') {
            this.sentinelHost = location.origin.replace('linker', `sentinel${subDomain}`);
        }

        //this.selfieHost = environment.selfieHost;
        //if (this.selfieHost === '') {
        //    this.selfieHost = location.origin.replace('linker', `selfie${subDomain}`);
        //}

        //this.spoofHost = environment.spoofHost;
        //if (this.spoofHost === '') {
        //    this.spoofHost = location.origin.replace('linker', `spoof${subDomain}`);
        //}
    }

    public sentinel(imageReference: Blob, radId: string): Observable<number> {
        // const sentinelUrl = Location.joinWithSlash(this.sentinelHost, '/analyze');
        const sentinelUrl = Location.joinWithSlash(this.partialHost, '/tool/sentinel');

        let sentinelFormData = new FormData();
        sentinelFormData.append("image", imageReference);
        sentinelFormData.append("radId", radId);

        return this.httpClient.post<SentinelResult>(sentinelUrl, sentinelFormData).pipe(
            map((result) => {
                if (result.status == "ok") {
                    if (result.fields != undefined) {
                        return 0;
                    }

                    if (result.isFound) {
                        return 1;
                    } else {
                        return -1;
                    }
                }

                return -1;
            }),
            catchError(err => of(-1))
        );
    }

    public selfie(imageReference: Blob, imageSelfie: Blob): Observable<SelfieResult> {
        //const selfieUrl = Location.joinWithSlash(this.selfieHost, '/selfie');
        const selfieUrl = Location.joinWithSlash(this.partialHost, '/tool/selfie');

        let selfieFormData = new FormData();
        selfieFormData.append("file1", imageReference);
        selfieFormData.append("file2", imageSelfie);
        selfieFormData.append("cropped", "false");

        return this.httpClient.post<SelfieResult>(selfieUrl, selfieFormData).pipe(
            map((result) => {
                result.match = true
                if (typeof(result) == 'string') {
                    return {
                        match: (result as string).includes("true")
                    }
                }

                return result;
            }),
            catchError(err => of({
                match: false
            }))
        );
    }

    public spoof(imageSelfie: Blob): Observable<SpoofResult> {
        //const spoofUrl = Location.joinWithSlash(this.spoofHost, '/spoof');
        const spoofUrl = Location.joinWithSlash(this.partialHost, '/tool/spoof');

        let spoofFormData = new FormData();
        spoofFormData.append("file", imageSelfie);

        return this.httpClient.post<SpoofResult>(spoofUrl, spoofFormData);
    }
}
