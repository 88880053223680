<!-- Style and progress loader circle / For a next version ? Dynamic loader here. -->
<!-- <app-circle-panel [progressValue]="documentService.getProgressPercent()"></app-circle-panel> -->

<div class="content" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
  <h2>
    <span [innerHTML]="infoMessage"></span>
    ({{ documentService.displayableDocuments.length }})
  </h2>
  
  <mat-hint class="document-error" *ngFor="let errorMsg of documentService.crossDocumentErrors">
    <span>{{errorMsg}}</span>
  </mat-hint>

      <!-- #doc is for style when drag -->
      <div #docs class="docs-container" (drop)="onDrop($event, true)">
        <mat-accordion>
          <ng-container *ngFor="let doc of documentService.displayableDocuments; index as docIdx">
            <mat-expansion-panel *ngFor="let page of doc.pages; index as pageIdx"
                                [expanded]="lastDocIdx === docIdx && lastPageIdx === pageIdx"
                                [hideToggle]="doc.valid && page.valid ? false : true"
                                [class.diemphased]="diemphased(doc)">

              <mat-expansion-panel-header [class.disabled]="!doc.valid || !page.valid">
                <mat-panel-title>
                  <mat-icon [style.color]="getPanelTitleColor(doc, page)">
                    {{ getPanelTitleIcon(page) }}
                  </mat-icon>
                  <span>{{ documentService.getDocumentPageLabel(doc.name, page.name) || doc.label }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-container *ngIf="pageIdx === 0 && doc.result && doc.typing !== 'Manual'">
                <mat-hint *ngFor="let error of doc.result.result.errors">
                  <span *ngIf="error.severity === 0">{{ error.longDescription || error.shortDescription }}</span>
                </mat-hint>
              </ng-container>

              <ng-container *ngIf="page.result && !page.missing && page.typing === 'Auto'">
                <mat-hint *ngFor="let error of page.result.result.errors">
                  <span>{{ error.longDescription || error.shortDescription }}</span>
                </mat-hint>
                <div *ngFor="let field of page.fields">
                  <mat-hint *ngFor="let error of field.result.errors">
                    <span *ngIf="error.severity === 0">
                      {{ field.description || field.label || field.name }} :
                      {{ error.longDescription || error.shortDescription }}
                    </span>
                  </mat-hint>
                </div>
              </ng-container>

              <div class="body">
                <div *ngIf="!page.missing">
                  <mat-spinner *ngIf="!page.image" matPrefix color="primary"></mat-spinner>
                    <img *ngIf="!!page.image" 
                    (click)="openZoomDialog(doc, page)"
                    class="photo" [src]="page.image | safe" 
                    aria-label="Fullscreen">
                </div>
                <ng-container *ngIf="showPageFields && page.typing === 'Auto'">
                  <div *ngFor="let control of getPageFieldControls(page) | keyvalue">
                    <mat-form-field [ngSwitch]="control.key" appearance="outline">
                      <!-- <mat-label>{{ documentService.getField2name(control.key) }}</mat-label> -->
                      <mat-spinner *ngIf="control.value.untouched" matPrefix diameter="16" color="primary"></mat-spinner>
                      <textarea *ngSwitchCase="'mrz'" matInput [formControl]="control.value" readonly
                                [rows]="getNbrRows(control.value.value)"></textarea>
                      <textarea *ngSwitchCase="'adresse'" matInput [formControl]="control.value" readonly
                                [rows]="getNbrRows(control.value.value)"></textarea>
                      <input *ngSwitchDefault matInput type="text" [formControl]="control.value" readonly>
                      <mat-icon *ngIf="hasCorrectedValue(page, control.key)" matSuffix
                                (click)="onToggleCode(control.value, page, control.key)">
                        {{ getCodeLabel(page, control.key) }}
                      </mat-icon>
                      <mat-error>
                        <div *ngFor="let error of getErrors(page, control.key)"
                            [ngClass]="{warning: error.severity === 1}">
                          <span>{{ error.shortDescription}}</span>
                          <span *ngIf="error.longDescription"> {{' : ' + error.longDescription}}</span>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>

    <div class="options">
      <!-- Beginning -->
      <p *ngIf="!documentService.isReadyToSend && !configService.stepByStep">
          Veuillez capturer le document de votre choix.</p>

      <p *ngIf="!documentService.isReadyToSend && configService.stepByStep && !documentService.hasCrossDocumentErrors">
          Veuillez capturer votre {{documentService.nextDoc.label || ""}}.</p>

      <p *ngIf="!documentService.isReadyToSend && configService.stepByStep && documentService.hasCrossDocumentErrors">
          Veuillez capturer le document de votre choix.</p>
      
      <!-- Complete send -->
      <p *ngIf="documentService.isReadyToSend && configService.requireConsolidate">
          <span>Félicitation !</span>
          Votre dossier est complet.
      </p>

      <!-- Partial send -->
      <p *ngIf="documentService.isReadyToSend && !configService.requireConsolidate">
        Vous pouvez envoyer vos documents ou poursuivre l'ajout.</p>
    </div>

    <div class="buttons" *ngIf="!isInProgress">
          <!-- Capture -->
          <button *ngIf="environment?.with.feature.camera && !documentService.isDone" 
            mat-fab (click)="onCamera()" #defaultButton color="primary">
            <mat-icon>photo_camera</mat-icon>
            <span>Capturer</span>
          </button>
          <!-- Upload -->
          <button *ngIf="!documentService.isValid && !documentService.nextIsSelfie || documentService.hasCrossDocumentErrors" mat-fab (click)="onFile()"
                  #defaultButton color="primary" [disabled]="documentService.isValid">
              <mat-icon>image</mat-icon>
              <span>Upload</span>
          </button>
          <!-- Biometry -->
          <button *ngIf="!documentService.isValid && documentService.hasSelfie && documentService.nextIsSelfie && !documentService.hasCrossDocumentErrors"
                  mat-fab
                  (click)="onBiometry()"
                  #defaultButton color="primary">
              <mat-icon>photo_camera_front</mat-icon>
              <span>Selfie</span>
          </button>
          <!-- Continue -->
          <button *ngIf="documentService.isReadyToSend"
               mat-fab (click)="onNext()"
                  color="primary">
              <mat-icon>input</mat-icon>
              <span>Envoyer</span>
          </button>
      </div>

    <!-- Progress -->
    <div class="progress" *ngIf="isInProgress">
      <mat-spinner color="primary"></mat-spinner>
      <p>{{ progressMsg }}</p>
    </div>
</div>

 <!-- Necessary to insert files from upload button -->
 <input #fileInput type="file" multiple name="file" accept="image/*,application/pdf" (change)="onSelectedFile($event.target.files)"
 style="display:none;" />